import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import {
  Form,
  Modal,
  ButtonGroup,
  ProgressBar,
  Row,
  Col,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { MdWarning } from '@react-icons/all-files/md/MdWarning';

import {
  Field,
  Button,
  Heading,
  Paragraph,
  ButtonIcon,
  PeriodFilterButtons,
  Spinner,
  Link,
  WarningBanner,
} from 'components';

import { toAdminCompanySettings } from 'services/routehelpers';
import SystemInfoPanel from 'containers/SystemInfoPanel';

import './style.css';

const p = ({ children }) => <span style={{ color: 'black' }}>{children}</span>;

function BalanceDataModal(props) {
  const {
    roleType,
    periods,
    showAll,
    company = {},
    toPeriod,
    showModal,
    toggleHide,
    fromPeriod,
    handleChange,
    singlePeriod,
    handleSubmitUpdate,
    accountingSystem,
    downloadProgress,
    isAlreadyUpdating,
    hasUpdatedBalance,
    toggleAutoReload,
    autoReload,
    hasValidIntegration,
  } = props;

  const { accountingYear } = useParams();

  const currentAccountingSystem = accountingSystem.data || {};

  const systemsSupportingPeriods = [
    'XLEDGER',
    'Visma Business',
    'POWER OFFICE',
    '24SEVEN OFFICE',
    'TRIPLETEX',
    'On Property - SQL',
  ];

  const isUpdating =
    isAlreadyUpdating ||
    (downloadProgress?.status && downloadProgress?.status !== 'Processed') ||
    downloadProgress?.period_year === +accountingYear ||
    downloadProgress?.accountingYear === +accountingYear;

  useEffect(() => {
    let timeout;
    if (downloadProgress?.status === 'Processed') {
      timeout = setTimeout(() => {
        toggleHide();
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [downloadProgress?.status]);

  return (
    <Modal
      show={showModal}
      className="update-balance-data-modal"
      onEscapeKeyDown={toggleHide}
    >
      <Modal.Header>
        <Heading level={3}>
          <Trans>Update Balance Data</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        {!hasValidIntegration && (
          <div className="alert alert-warning" role="alert">
            <Trans component={p}>
              The integration with the accounting system of{' '}
              {company.currentCompanyName} is not valid.{' '}
              <Link
                to={() =>
                  toAdminCompanySettings({
                    companyIdToEdit: company.currentCompanySID,
                  })
                }
                onClick={toggleHide}
                style={{ color: 'black', textDecoration: 'underline' }}
              >
                Check your company&apos;s settings.
              </Link>
            </Trans>
          </div>
        )}
        {accountingSystem.isError && (
          <Heading level={4}>
            <Trans>
              Error while fetching accounting system. Please try again later.
            </Trans>
          </Heading>
        )}
        {accountingSystem.isLoading ? (
          <SpinnerHolder>
            <Spinner />
          </SpinnerHolder>
        ) : (
          currentAccountingSystem &&
          !accountingSystem.isError &&
          hasValidIntegration && (
            <>
              <span>
                <SystemInfoPanel />
                {currentAccountingSystem?.erp_system_id ===
                  '5ab301b6-8388-48aa-ad26-214240d8069e' && (
                  <div className="alert alert-warning" role="alert">
                    <Trans component={p}>
                      SAF-T files cannot be imported here. Please visit{' '}
                      <a
                        href="https://saft.accountflow.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ color: 'black', textDecoration: 'underline' }}
                      >
                        saft.accountflow.com
                      </a>{' '}
                      to import your SAF-T files.
                    </Trans>
                  </div>
                )}
                {systemsSupportingPeriods.includes(
                  currentAccountingSystem.erp_name,
                ) &&
                  currentAccountingSystem.erp_system_id !==
                    '5ab301b6-8388-48aa-ad26-214240d8069e' &&
                  company.currentPeriodType !== 'yearly' && (
                    <>
                      <Paragraph>
                        <Trans>
                          Select periods range you want to update. Current range
                          ({fromPeriod}-{toPeriod})
                        </Trans>
                      </Paragraph>
                      <br />
                      <Form>
                        <PeriodFilterButtons
                          activePeriods={periods}
                          handleFilterChange={handleChange}
                          periodType={
                            showAll ? 'monthly' : company.currentPeriodType
                          }
                          singlePeriod={singlePeriod}
                        />
                        <CheckHolder>
                          <Field
                            inline
                            id="showAllMonths-modal"
                            type="checkbox"
                            name="showAll"
                            label={t`Show 12 periods`}
                            onChange={(e) =>
                              e.target.checked
                                ? handleChange(e, 'monthly')
                                : handleChange(e, company.currentPeriodType)
                            }
                            defaultChecked={showAll}
                            disabled={company.currentPeriodType === 'monthly'}
                          />
                        </CheckHolder>
                        <CheckHolder>
                          <Field
                            inline
                            id="singlePeriod-modal"
                            type="checkbox"
                            name="singlePeriod"
                            label={t`Update single period`}
                            onChange={(e) =>
                              handleChange(e, company.currentPeriodType)
                            }
                            defaultChecked={singlePeriod}
                          />
                        </CheckHolder>
                      </Form>
                    </>
                  )}
              </span>
              {currentAccountingSystem.erp_system_id !==
                '5ab301b6-8388-48aa-ad26-214240d8069e' && (
                <>
                  <Form>
                    <CheckHolder>
                      <Field
                        inline
                        id="autoReload-modal"
                        type="checkbox"
                        name="autoReload"
                        label={t`Refresh automatically after a balance update`}
                        onChange={toggleAutoReload}
                        defaultChecked={autoReload}
                      />
                    </CheckHolder>
                  </Form>
                  <Row>
                    <Col>
                      <WarningHolder>
                        <Trans>
                          <MdWarning size="4rem" />
                          During the update, the company data will be read only.
                          You can still navigate in Accountflow, but you will
                          not be able to save any changes.
                        </Trans>
                      </WarningHolder>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )
        )}
        {!company.accountSystem && (
          <h4 style={{ textAlign: 'center' }}>
            <Trans>
              Please setup an Accounting System through Integration Settings
            </Trans>
          </h4>
        )}
        {isUpdating && (
          <>
            <h4 style={{ textAlign: 'center' }}>
              {downloadProgress?.status || t`Updating...`}
            </h4>
            <ProgressBar
              active
              now={
                downloadProgress?.status === 'Failed'
                  ? 0
                  : downloadProgress?.event_progress || 100
              }
            />
          </>
        )}
        {downloadProgress?.status === 'Failed' && (
          <WarningBanner
            // eslint-disable-next-line max-len
            message={t`The balance data failed to update. Please try revalidating the integration by`}
            linkTitle={t`clicking here`}
            link={toAdminCompanySettings({
              companyIdToEdit: company.currentCompanySID,
            })}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            kind="info"
            type="submit"
            disabled={
              !hasValidIntegration ||
              accountingSystem.isLoading ||
              accountingSystem.isError ||
              hasUpdatedBalance ||
              roleType.isReadOnly ||
              isAlreadyUpdating ||
              !company.accountSystem ||
              currentAccountingSystem.erp_system_id ===
                '5ab301b6-8388-48aa-ad26-214240d8069e' ||
              (downloadProgress?.status &&
                downloadProgress.status !== 'Processed' &&
                downloadProgress.status !== 'Failed' &&
                (downloadProgress.period_year === +accountingYear ||
                  downloadProgress.accountingYear === +accountingYear))
            }
            onClick={handleSubmitUpdate}
          >
            <Trans>Update Balance for {accountingYear}</Trans>
          </Button>
          <Button fill kind="danger" magnify onClick={toggleHide}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

BalanceDataModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleHide: PropTypes.func.isRequired,
  company: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmitUpdate: PropTypes.func.isRequired,
  periods: PropTypes.instanceOf(Array).isRequired,
  fromPeriod: PropTypes.number.isRequired,
  toPeriod: PropTypes.number.isRequired,
  singlePeriod: PropTypes.bool.isRequired,
};

export default BalanceDataModal;

const CheckHolder = styled.div`
  margin-top: 30px;

  & + & {
    margin-top: 10px;
  }
`;

const WarningHolder = styled.div`
  margin: 30px 16px 0 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  background: #ffdf00;
  border-radius: 5px;
  font-weight: 500;
`;

const SpinnerHolder = styled.div`
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
