import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fromCompany } from 'store/selectors';
import { resourceCreateRequest } from 'store/actions';

import { toggleManuallyToggledWTPApi } from 'services/apihelpers';
import { PayrollWTDrilldownModal } from 'components';
import { useParams } from 'react-router-dom';

export function PayrollWithheldTaxDrilldownModal(props) {
  const { row, toggleModal, loading, refetch } = props;
  const dispatch = useDispatch();
  const company = useSelector(fromCompany.getCompany);
  const { accountingYear } = useParams();

  const handleAction = async (action, payload) => {
    switch (action) {
      case 'excludeWTTransaction':
      case 'includeWTTransaction': {
        const query = {
          companySid: company?.currentCompanySID,
          year: Number(accountingYear),
          transactionLineId: payload.transactionLineId,
        };
        await dispatch(
          resourceCreateRequest(toggleManuallyToggledWTPApi, query),
        );
        refetch();
        break;
      }
      default:
        break;
    }
  };

  return (
    <PayrollWTDrilldownModal
      {...{
        row,
        toggleModal,
        loading,
        refetch,
        handleAction,
      }}
    />
  );
}

export default PayrollWithheldTaxDrilldownModal;
