import React from 'react';
import { t } from '@lingui/macro';
import { BiTrash } from '@react-icons/all-files/bi/BiTrash';

import { getTranslatedPayrollReportElements } from 'services/helpers';

export const columns = (names, isReadOnly) => [
  {
    width: 190,
    Header: t`Name`,
    accessor: 'elementName',
    className: '',
    cell: {
      type: 'select',
      editable: !isReadOnly,
      disableClick: true,
      fieldOptions: {
        id: 'elementName',
        single: true,
        modal: true,
        small: true,
        placeholder: t`Please select Name`,
        disableSort: true,
        autoFocus: true,
        autoHeight: true,
        options: names.map((item, i) => ({
          id: i,
          // eslint-disable-next-line max-len
          label: getTranslatedPayrollReportElements(item).name,
        })),
      },
    },
  },
  {
    width: 65,
    Header: t`Period Number`,
    accessor: 'period',
    className: '',
    cell: {
      type: 'select',
      editable: !isReadOnly,
      disableClick: true,
      fieldOptions: {
        id: 'period',
        single: true,
        autoHeight: true,
        small: true,
        placeholder: t`Please select Period`,
        disableSort: true,
        autoFocus: true,
        options: [...Array(12).keys()].map((item) => ({
          id: item + 1,
          label: (item + 1).toString(),
        })),
      },
    },
  },
  {
    width: 70,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    cell: {
      type: 'input',
      inputType: 'number',
      editable: !isReadOnly,
      disableClick: true,
    },
  },
  {
    width: 75,
    Header: t`With Social Security`,
    accessor: 'withSocialSecurity',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
  ...(!isReadOnly
    ? [
        {
          width: 40,
          Header: t`Actions`,
          accessor: 'actions',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'actions',
            disableClick: true,
            actions: [
              {
                id: 'delete',
                icon: () => <BiTrash />,
                tooltip: t`Delete`,
              },
            ],
          },
        },
      ]
    : []),
];

export default { columns };
