/* eslint-disable no-console */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { SudokuCreator } from '@algorithm.ts/sudoku';
import { Trans } from '@lingui/macro';

// Map difficulty names to numerical difficulty values for the library
const difficultyValueMap = {
  easy: 0.2,
  medium: 0.4,
  hard: 0.6,
  expert: 1,
};

// --- Helper Function ---

// Converts the flat 81-element board array (with -1 for empty, 0-8 for numbers)
// to a 9x9 2D array (with 0 for empty, 1-9 for numbers)
const flatBoardTo2D = (flatBoard) => {
  const board = [];
  if (!flatBoard || flatBoard.length !== 81) {
    // eslint-disable-next-line no-console
    console.error('Invalid flat board data received for conversion');
    return Array.from({ length: 9 }, () => Array(9).fill(0));
  }
  for (let i = 0; i < 9; i += 1) {
    const row = [];
    for (let j = 0; j < 9; j += 1) {
      const value = flatBoard[i * 9 + j];
      // Library: -1 == empty --> Our state: 0
      // Library: 0..8 == number --> Our state: 1..9
      row.push(value === -1 ? 0 : value + 1);
    }
    board.push(row);
  }
  return board;
};

// --- Custom Hook for Generation ---

const useSudokuGenerator = (difficulty, gameId) => {
  const [initialPuzzle, setInitialPuzzle] = useState(() =>
    Array.from({ length: 9 }, () => Array(9).fill(0)),
  );
  const [solution, setSolution] = useState(() =>
    Array.from({ length: 9 }, () => Array(9).fill(0)),
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    try {
      const numericDifficulty = difficultyValueMap[difficulty] || 0.4;
      const creator = new SudokuCreator({
        childMatrixWidth: 3,
      });
      const puzzleData = creator.createSudoku(numericDifficulty);

      if (puzzleData?.puzzle && puzzleData?.solution) {
        const board2D = flatBoardTo2D(puzzleData.puzzle);
        const solution2D = flatBoardTo2D(puzzleData.solution);

        setInitialPuzzle(board2D);
        setSolution(solution2D);
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'Hook: Sudoku generation failed or returned invalid data',
        );
        setError('Failed to generate puzzle data.');
        // Set empty boards on failure
        const emptyBoard = Array.from({ length: 9 }, () => Array(9).fill(0));
        setInitialPuzzle(emptyBoard);
        setSolution(emptyBoard);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Hook: Error during Sudoku generation:', err);
      setError('An error occurred during generation.');
      // Set empty boards on error
      const emptyBoard = Array.from({ length: 9 }, () => Array(9).fill(0));
      setInitialPuzzle(emptyBoard);
      setSolution(emptyBoard);
    } finally {
      setLoading(false);
    }
  }, [difficulty, gameId]); // Re-run effect when these change

  return { initialPuzzle, solution, loading, error };
};

// --- Sudoku Game Component ---

function SudokuGame({ difficulty, gameId, onCheckTrigger }) {
  // Use the custom hook to get puzzle data
  const { initialPuzzle, solution, loading, error } = useSudokuGenerator(
    difficulty,
    gameId,
  );

  // State for the user's current board entries
  const [board, setBoard] = useState(() =>
    Array.from({ length: 9 }, () => Array(9).fill(0)),
  );
  // State for validation results
  const [checkResults, setCheckResults] = useState(() =>
    Array.from({ length: 9 }, () => Array(9).fill(null)),
  );
  // Timer state
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);

  // Timer effect
  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isRunning]);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  // Effect to reset the board and timer when a new puzzle is loaded
  useEffect(() => {
    if (initialPuzzle) {
      setBoard(initialPuzzle);
      setCheckResults(Array.from({ length: 9 }, () => Array(9).fill(null)));
      setTime(0);
      setIsRunning(true);
    }
  }, [initialPuzzle]);

  const handleInputChange = useCallback(
    (rowIndex, colIndex, event) => {
      // Clear check results when user types
      setCheckResults(Array.from({ length: 9 }, () => Array(9).fill(null)));

      const { value } = event.target;
      if (/^[1-9]$/.test(value) || value === '') {
        const newBoard = board.map((row) => [...row]);
        newBoard[rowIndex][colIndex] =
          value === '' ? 0 : Number.parseInt(value, 10);
        setBoard(newBoard);
      } else if (value.length > 1) {
        const lastChar = value.slice(-1);
        if (/^[1-9]$/.test(lastChar)) {
          const newBoard = board.map((row) => [...row]);
          newBoard[rowIndex][colIndex] = Number.parseInt(lastChar, 10);
          setBoard(newBoard);
        }
      }
    },
    [board],
  );

  // Function passed down from Modal to trigger the check
  const checkBoard = useCallback(() => {
    if (!solution || !board) {
      return; // No solution or board available
    }

    const results = board.map((row, rIdx) =>
      row.map((cellValue, cIdx) => {
        if (
          !initialPuzzle?.[rIdx] ||
          typeof initialPuzzle[rIdx][cIdx] === 'undefined' ||
          !solution?.[rIdx] ||
          typeof solution[rIdx][cIdx] === 'undefined'
        ) {
          return null;
        }

        if (initialPuzzle[rIdx][cIdx] !== 0) {
          return 'initial';
        }
        if (cellValue === 0) {
          return null;
        }
        return cellValue === solution[rIdx][cIdx] ? 'correct' : 'incorrect';
      }),
    );

    // Check if the puzzle is completely solved correctly
    const isComplete = results.every((row) =>
      row.every((cell) => cell === 'correct' || cell === 'initial'),
    );

    if (isComplete) {
      setIsRunning(false);
    }

    setCheckResults(results);
  }, [board, solution, initialPuzzle]);

  // Effect to register the checkBoard function with the parent Modal
  useEffect(() => {
    if (onCheckTrigger) {
      onCheckTrigger(checkBoard);
    }
    // Cleanup function (optional but good practice)
    return () => {
      if (onCheckTrigger) {
        onCheckTrigger(null); // Unregister on unmount/dependency change
      }
    };
  }, [onCheckTrigger, checkBoard]); // Rerun if callback prop or checkBoard function changes

  if (loading) {
    return <LoadingPlaceholder>Generating puzzle...</LoadingPlaceholder>;
  }
  if (error) {
    return <ErrorPlaceholder>Error: {error}</ErrorPlaceholder>;
  }

  return (
    <>
      <TimerDisplay>{formatTime(time)}</TimerDisplay>
      <StyledTable>
        <tbody>
          {board.map((row, rowIndex) => (
            <StyledTr key={`row-${rowIndex}`}>
              {' '}
              {/* Use more unique key */}
              {row.map((cell, colIndex) => {
                const isInitialCell =
                  initialPuzzle?.[rowIndex]?.[colIndex] !== 0;
                const key = `cell-${rowIndex}-${colIndex}`;
                const checkStatus = checkResults?.[rowIndex]?.[colIndex];

                return (
                  <StyledTd
                    key={key}
                    isInitial={isInitialCell}
                    checkStatus={checkStatus}
                  >
                    {isInitialCell ? (
                      <span>{cell !== 0 ? cell : ''}</span>
                    ) : (
                      <InputCell
                        type="text"
                        inputMode="numeric"
                        pattern="[1-9]"
                        maxLength={1}
                        value={cell !== 0 ? cell : ''}
                        onChange={(e) =>
                          handleInputChange(rowIndex, colIndex, e)
                        }
                        isInitial={isInitialCell}
                        checkStatus={checkStatus}
                        readOnly={checkStatus && checkStatus !== 'initial'} // Consider if you want this
                      />
                    )}
                  </StyledTd>
                );
              })}
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
}

SudokuGame.propTypes = {
  difficulty: PropTypes.oneOf(['easy', 'medium', 'hard']).isRequired,
  gameId: PropTypes.number.isRequired,
  onCheckTrigger: PropTypes.func.isRequired,
};

// --- Sudoku Modal Component ---

export function SudokuModal({ show, onHide }) {
  const [difficulty, setDifficulty] = useState('easy');
  const [gameId, setGameId] = useState(Date.now()); // Use timestamp for unique initial ID
  const checkBoardRef = useRef(null); // Keep using ref to store the check function

  const handleNewGame = () => {
    setGameId(Date.now()); // Trigger new game generation
    // No need to manually clear results, the SudokuGame effect will handle it
  };

  const handleCheck = () => {
    if (checkBoardRef.current) {
      checkBoardRef.current(); // Call the check function from SudokuGame
    }
  };

  // Callback for SudokuGame to register its check function
  // Use useCallback to prevent unnecessary re-renders if SudokuModal re-renders
  const registerCheckTrigger = useCallback((checkerFunc) => {
    checkBoardRef.current = checkerFunc;
  }, []); // Empty dependency array: this function itself never changes

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans>Sudoku Challenge!</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ControlsContainer>
          <DifficultySelector>
            <span>
              <Trans>Difficulty:</Trans>
            </span>
            <ButtonGroup>
              <Button
                bsStyle={difficulty === 'easy' ? 'primary' : 'default'}
                onClick={() => setDifficulty('easy')}
              >
                <Trans>Easy</Trans>
              </Button>
              <Button
                bsStyle={difficulty === 'medium' ? 'primary' : 'default'}
                onClick={() => setDifficulty('medium')}
              >
                <Trans>Medium</Trans>
              </Button>
              <Button
                bsStyle={difficulty === 'hard' ? 'primary' : 'default'}
                onClick={() => setDifficulty('hard')}
              >
                <Trans>Hard</Trans>
              </Button>
              <Button
                bsStyle={difficulty === 'expert' ? 'primary' : 'default'}
                onClick={() => setDifficulty('expert')}
              >
                <Trans>Expert</Trans>
              </Button>
            </ButtonGroup>
          </DifficultySelector>
          <Button bsStyle="success" onClick={handleNewGame}>
            <Trans>New Game</Trans>
          </Button>
        </ControlsContainer>

        <SudokuGame
          difficulty={difficulty}
          gameId={gameId}
          onCheckTrigger={registerCheckTrigger} // Pass the registration function
        />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="info" onClick={handleCheck}>
          <Trans>Check Solution</Trans>
        </Button>
        <Button onClick={onHide}>
          <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SudokuModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default SudokuModal;

// --- Styled Components ---

const LoadingPlaceholder = styled.div`
  min-height: 300px; /* Ensure placeholder takes space */
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  color: #666;
`;

const ErrorPlaceholder = styled(LoadingPlaceholder)`
  color: #c00;
  font-weight: bold;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 15px; // Add some padding
  flex-wrap: wrap; // Allow wrapping on smaller screens
  gap: 15px; // Add gap between items
`;

const DifficultySelector = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > span {
    font-weight: bold;
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  border: 2px solid black;
  margin: 20px auto;
  box-sizing: border-box;

  tbody {
    /* Re-add grid layout with fixed cell sizes */
    display: grid;
    grid-template-columns: repeat(9, 48px);
    grid-template-rows: repeat(9, 48px);
    /* Remove width/height auto, let grid define size */
  }
`;

const StyledTr = styled.tr`
  /* Re-add display: contents */
  display: contents;
`;

const StyledTd = styled.td`
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  /* --- Apply thick borders based on position --- */

  /* Right border for columns 3 and 6 */
  &:nth-child(3n) {
    border-right: 2px solid black;
  }

  /* Bottom border for rows 3 and 6 */
  /* Apply directly to TD based on parent TR */
  tr:nth-child(3n) > & {
    border-bottom: 2px solid black;
  }

  /* Style initial numbers differently */
  font-weight: ${(props) => (props.isInitial ? 'bold' : 'normal')};
  color: ${(props) => (props.isInitial ? '#333' : '#1a73e8')};
  background-color: ${(props) => {
    if (props.isInitial) return '#eee';
    if (props.checkStatus === 'correct') return '#d1f7c4'; // Light green for correct
    if (props.checkStatus === 'incorrect') return '#f7c4c4'; // Light red for incorrect
    return 'white'; // Default
  }};

  /* Ensure initial number span fills the cell */
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* Removed position: absolute */
  }
`;

const InputCell = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  font-size: inherit;
  font-weight: normal;
  color: ${(props) => {
    if (props.checkStatus === 'incorrect') return '#c00';
    return '#1a73e8';
  }};
  background-color: transparent;
  padding: 0;
  box-sizing: border-box;
  /* Removed position: absolute */

  /* Remove spinners */
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    /* Optional: Don't change background on focus if already checked */
    background-color: ${(props) =>
      props.checkStatus ? 'transparent' : '#e8f0fe'};
    outline: 2px solid #1a73e8;
    outline-offset: -2px;
    z-index: 1;
  }
`;

// Add new styled component for timer
const TimerDisplay = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  font-family: monospace;
`;
