import React, { Component } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';

import { UserDetailsTable } from 'components';

import UserPreviewModal from 'containers/UserPreviewModal';
import CompanyPreviewModal from 'containers/CompanyPreviewModal';
import AdminUserAddEditModal from 'containers/AdminUserAddEditModal';
import AddEditGroupUserModal from 'containers/AddEditGroupUserModal';
import AddEditGroupCompanyModal from 'containers/AddEditGroupCompanyModal';

import { resourceListReadSuccess } from 'store/resource/actions';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import {
  getUsersApi,
  adminUpdateUserStatus,
  isOrganizationExternallyManagedApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

class CompanyDetailsTableContainer extends Component {
  state = {
    modal: '',
    modalItem: {},
    actionSubmitting: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.match.params) !==
      JSON.stringify(nextProps.match.params)
    ) {
      this.fetchData(nextProps);
    }

    return true;
  }

  getSearch = (props = this.props) => {
    const search = queryString.parse(props.location.search, {
      parseBooleans: true,
    });

    return {
      reportMode: search.reportMode || false,
    };
  };

  fetchData = (props = this.props) => {
    const { getUserList, company, isOrganizationExternallyManaged } = props;

    getUserList({
      organizationId: company.organizationId,
    });
    isOrganizationExternallyManaged({
      organizationSid: company.organizationId,
    });
  };

  handleActivationChange = async (type, selectedRows) => {
    const activate = type === 'activate';
    const { data, updateUsersStatus, updateReduxResourceResponse } = this.props;

    // Handle selectedRows being a single row
    if (!Array.isArray(selectedRows)) {
      // eslint-disable-next-line no-param-reassign
      selectedRows = [selectedRows];
    }

    await updateUsersStatus({
      activate,
      userUuids: selectedRows.map((u) => u.uuid),
    });

    const collection = data?.map((_item) => {
      const el = { ..._item };
      const row = selectedRows.find((u) => u.uuid === _item.uuid);

      if (row) {
        // eslint-disable-next-line no-param-reassign
        el.recordStatus = activate;
      }

      return el;
    });

    updateReduxResourceResponse(getUsersApi, collection);
  };

  toggleModal = (name, modalItem = {}) => {
    this.setState((prevState) => ({
      modal: prevState.modal === name ? '' : name,
      modalItem: prevState.modal === name ? {} : modalItem,
    }));
  };

  setActionSubmitting = (action, submitting) => {
    this.setState((state) => ({
      actionSubmitting: { ...state.actionSubmitting, [action]: submitting },
    }));
  };

  handleAction = async (action, props, table) => {
    try {
      this.setActionSubmitting(action, true);

      switch (action) {
        case 'activate':
        case 'deactivate': {
          await this.handleActivationChange(action, props);
          break;
        }

        case 'userPreview': {
          this.toggleModal('userPreview', props);
          break;
        }

        case 'addUser': {
          this.toggleModal('addUser', null);
          break;
        }

        case 'edit': {
          this.toggleModal('addUser', props);
          break;
        }

        case 'addUserGroup': {
          this.toggleModal('addUserGroup', props);
          break;
        }

        case 'rowClick': {
          switch (table) {
            case 'companyPreview': {
              this.toggleModal('companyPreview', props);
              break;
            }
            case 'userPreview': {
              this.toggleModal('userPreview', props);
              break;
            }
            case 'editUserGroup': {
              this.toggleModal('editUserGroup', props);
              break;
            }
            case 'editCompanyGroup': {
              this.toggleModal('editCompanyGroup', props);
              break;
            }
            default: {
              break;
            }
          }
          break;
        }

        default: {
          break;
        }
      }

      this.setActionSubmitting(action, false);
    } catch (e) {
      this.setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  closeModal = () => {
    this.setState({
      modal: '',
      modalItem: {},
    });
  };

  closeAddEditModal = (_, refetch) => {
    this.closeModal();

    if (refetch) {
      this.fetchData();
    }
  };

  renderModal = () => {
    let modalToRender = '';

    switch (this.state.modal) {
      case 'addUser': {
        modalToRender = (
          <AdminUserAddEditModal
            payload={this.state.modalItem}
            closeModal={this.closeAddEditModal}
            modalAction={!!this.state.modalItem}
            organizationExternallyManaged={
              this.props.organizationExternallyManaged
            }
          />
        );
        break;
      }
      case 'userPreview': {
        modalToRender = (
          <UserPreviewModal
            id={this.state.modalItem.uuid}
            closeModal={this.closeModal}
            handleAction={this.handleAction}
          />
        );
        break;
      }
      case 'editUserGroup': {
        modalToRender = (
          <AddEditGroupUserModal
            item={this.state.modalItem}
            closeModal={this.closeModal}
          />
        );
        break;
      }
      case 'addUserGroup': {
        modalToRender = (
          <AddEditGroupUserModal
            create={this.state.modalItem}
            closeModal={this.closeModal}
          />
        );
        break;
      }
      case 'editCompanyGroup': {
        modalToRender = (
          <AddEditGroupCompanyModal
            item={this.state.modalItem}
            closeModal={this.closeModal}
          />
        );
        break;
      }
      case 'companyPreview': {
        modalToRender = (
          <CompanyPreviewModal
            id={this.state.modalItem.companyId}
            closeModal={this.closeModal}
            handleAction={this.handleAction}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return modalToRender;
  };

  render() {
    const { actionSubmitting } = this.state;
    const { data, loading, organizationExternallyManaged } = this.props;
    const search = this.getSearch(this.props);

    return (
      <>
        {this.renderModal()}
        <UserDetailsTable
          {...{
            data,
            search,
            loading,
            actionSubmitting,
            title: t`Admin Users`,
            handleAction: this.handleAction,
            organizationExternallyManaged,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  data: fromResource.getList(state, getUsersApi),
  company: fromCompany.getCompany(state),
  loading: pending(state, `${getUsersApi}ListRead`),
  organizationExternallyManaged: fromResource.getList(
    state,
    isOrganizationExternallyManagedApi,
  ).status,
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: (query) => dispatch(resourceListReadRequest(getUsersApi, query)),
  updateUsersStatus: (query) =>
    dispatch(resourceCreateRequest(adminUpdateUserStatus, query)),
  updateReduxResourceResponse: (resource, payload) =>
    dispatch(resourceListReadSuccess(resource, payload)),
  isOrganizationExternallyManaged: (query) =>
    dispatch(
      resourceListReadRequest(isOrganizationExternallyManagedApi, query),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyDetailsTableContainer);
