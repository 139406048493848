import { t } from '@lingui/macro';

export function getVATReportType(reportType) {
  switch (reportType) {
    case 'alminnelig':
      return t`Ordinary`;
    case 'primaernaering':
      return t`Primary industry`;
    case 'kompensasjon':
      return t`Compensation`;
    case 'merverdiavgift kompensasjon':
      return t`VAT Compensation`;
    case 'omvendtAvgiftsplikt':
      return t`Reverse Charge Obligation`;
    default:
      return t`${reportType}`;
  }
}

export function getVATReportTypeTitle(reportType) {
  switch (reportType) {
    case 'alminnelig':
      return t`VAT Documents for Ordinary`;
    case 'primaernaering':
      return t`VAT Documents for Primary industry`;
    case 'kompensasjon':
      return t`VAT Documents for Compensation`;
    case 'merverdiavgift kompensasjon':
      return t`VAT Documents for VAT Compensation`;
    case 'omvendtAvgiftsplikt':
      return t`VAT Documents for Reverse Charge Obligation`;
    default:
      return t`VAT Documents for ${reportType}`;
  }
}

export default { getVATReportType, getVATReportTypeTitle };
