import React from 'react';
import { t } from '@lingui/macro';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';

/**
 * Organization Settings Table Grid
 */

export const columns = ({ reportMode, canEditOrganization }) => [
  {
    width: reportMode ? 300 : 500,
    Header: t`Name`,
    accessor: 'organizationName',
    className: '',
    withTooltip: !reportMode,
  },
  {
    width: reportMode ? 190 : 210,
    Header: t`Number`,
    accessor: 'organizationCode',
    className: '',
  },
  {
    width: reportMode ? 190 : 200,
    Header: t`Active Companies`,
    accessor: 'activeCompanies',
    className: 'flex-right',
  },
  {
    width: reportMode ? 190 : 200,
    Header: t`Active Users`,
    accessor: 'activesUsers',
    className: 'flex-right',
  },
  {
    width: reportMode ? 180 : 250,
    Header: t`Altinn Setup`,
    accessor: 'organizationAltinSetupYes',
    className: 'flex-right',
    cell: {
      converter: (cell, row) =>
        // eslint-disable-next-line max-len
        `${row.organizationAltinSetupYes} / ${row.organizationAltinSetupNo}`,
    },
  },
  {
    width: 80,
    hidden: reportMode,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        ...(canEditOrganization
          ? [
              {
                id: 'edit',
                icon: () => <GoPencil />,
                tooltip: t`Edit`,
              },
            ]
          : []),
      ],
    },
  },
];

export default { columns };
