import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AltinnConfigModal } from 'components';

import { formatDateByMonth } from 'services/helpers';
import { fromAuth, fromCompany } from 'store/selectors';
import { resourceCreateRequest } from 'store/actions';
import {
  altinnFetchApi,
  altinnAuthenticateApi,
  retrieveAltinnSessionApi,
  validateAltinnSessionApi,
  altinnAuthenticatePinApi,
  updateCompanyListAltinnApi,
  fetchVatReportsFromAltinnApi,
  updateCompanyListAltinnVATApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function AltinnConfigModalContainer(props) {
  const {
    mode,
    periods,
    toPeriod,
    bimonthly,
    subTabEntry,
    singlePeriod,
    altinnSignIn,
    toggleWindow,
    selectedCompanyIdRows,
  } = props;
  const { accountingPeriod } = useParams();

  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(1)).toISOString(),
  );
  const [initialState, setInitialState] = useState(true);
  const [sessionValidate, setSessionValidate] = useState(false);
  const [periodSelectPerRow, setPeriodSelectPerRow] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [altinnFetchDetails, setAltinnFetchDetails] = useState(false);
  const [altinnUserCredentials, setAltinnUserCredentials] = useState(false);
  const [initialResponse, setInitialResponse] = useState(null);
  const [authenticateResponse, setAuthenticateResponse] = useState(null);
  const [reconcilingFromDate, setReconcilingFromDate] = useState(null);

  const [error, setError] = useState('');
  const [activePeriods, setActivePeriods] = useState(props.activePeriods || 12);

  const [selectedRows, setSelectedRows] = useState([]);
  const [altinnUpdatedDataList, setAltinnUpdatedDataList] = useState([]);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const loading = useSelector((state) =>
    pending(state, `${validateAltinnSessionApi}ListRead`),
  );

  const dispatch = useDispatch();

  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);

  const isValidPeriod = (period) => {
    if (!reconcilingFromDate) return true;

    const reconcilingDate = new Date(reconcilingFromDate);
    const reconcilingYear = reconcilingDate.getFullYear();
    const reconcilingMonth = reconcilingDate.getMonth() + 1;

    if (company.currentAccountingYear < reconcilingYear) return false;
    if (company.currentAccountingYear > reconcilingYear) return true;

    // For the current year, check against reconciling month
    if (bimonthly) {
      // Convert period to month range
      const startMonth = (period - 1) * 2 + 1;
      return startMonth >= reconcilingMonth;
    }
    // For monthly periods, directly compare with reconciling month
    return period >= reconcilingMonth;
  };

  const filterDataByReconciliationDate = (sessionData) => {
    // Create a base array of 12 periods with default values
    const baseData = Array.from({ length: 12 }, (_, i) => ({
      period: i + 1,
      status: 'Ikke hentet',
      modifiedDate: null,
      isUpdated: false,
      templateType:
        mode === 'company' || mode === 'organization' ? 'PAYROLL' : 'VAT',
    }));

    // If we have API response data, merge it with our base data
    if (sessionData?.altinnUpdatedDataList?.length > 0) {
      sessionData.altinnUpdatedDataList.forEach((item) => {
        if (item.period >= 1 && item.period <= 12) {
          baseData[item.period - 1] = {
            ...baseData[item.period - 1],
            ...item,
          };
        }
      });
    }

    // If no reconciling date, return all periods with their data
    if (!sessionData?.reconcilingFromDate) {
      return baseData;
    }

    const reconcilingDate = new Date(sessionData.reconcilingFromDate);
    const reconcilingYear = reconcilingDate.getFullYear();
    const reconcilingMonth = reconcilingDate.getMonth() + 1;

    // If we're in a past year, return empty array
    if (company.currentAccountingYear < reconcilingYear) {
      return [];
    }

    // If we're in a future year, return all periods with their data
    if (company.currentAccountingYear > reconcilingYear) {
      return baseData;
    }

    // For current year, only return periods from reconciling month onwards
    return baseData.filter((item) => item.period >= reconcilingMonth);
  };

  const setData = async () => {
    const { currentCompanySID, currentAccountingYear } = company;

    const query = {
      year: currentAccountingYear,
      companyId: currentCompanySID,
      templateType:
        mode === 'company' || mode === 'organization' ? 'PAYROLL' : 'VAT',
    };

    const _sessionValidate = await dispatch(
      resourceCreateRequest(validateAltinnSessionApi, query),
    );

    setFromDate(formatDateByMonth(currentAccountingYear).toISOString());
    setInitialState(_sessionValidate.sessionActive === false);
    setSessionValidate(_sessionValidate.sessionActive);
    setReconcilingFromDate(_sessionValidate.reconcilingFromDate);

    // Create the filtered data structure with the API response data
    const filteredData = filterDataByReconciliationDate(_sessionValidate);
    setAltinnUpdatedDataList(filteredData);
  };

  const handleSelectAll = (items) => {
    const selectedRowsItems = new Map();

    items.forEach((row) => {
      const { period } = row;
      if (row.selectable) {
        selectedRowsItems.set(period, {
          period,
        });
      }
    });

    setSelectedRows(Array.from(selectedRowsItems));
  };

  const handleChange = (item) => {
    const { period } = item;

    // For monthly periods, we should use the actual period number
    const selectedPeriod = period;

    const selectedRowsItems = new Map(selectedRows);
    if (selectedRowsItems.has(selectedPeriod)) {
      selectedRowsItems.delete(selectedPeriod);
    } else {
      selectedRowsItems.set(selectedPeriod, {
        period: selectedPeriod,
      });
    }
    setSelectedRows(Array.from(selectedRowsItems));
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'rowSelect': {
          if (Array.isArray(item.item)) {
            handleSelectAll(item.item);
          } else if (item.item === 'all') {
            setSelectedRows([]);
          } else {
            handleChange(item.item);
          }
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const updateCompanyListAltinnVAT = (query) =>
    dispatch(resourceCreateRequest(updateCompanyListAltinnVATApi, query));

  const updateCompanyListAltinn = (query) =>
    dispatch(resourceCreateRequest(updateCompanyListAltinnApi, query));

  const onSubmit = async (values) => {
    const { ssn, otp, altinnUserPassword } = values;

    const selectedPeriod =
      mode === 'company' ? activePeriods : accountingPeriod;

    let _authenticateResponse;
    if (initialState) {
      try {
        setAltinnUserCredentials(true);

        const _initialResponse = await dispatch(
          resourceCreateRequest(altinnAuthenticateApi, {
            userSSN: ssn,
            password: altinnUserPassword,
            companyId: company.currentCompanySID,
          }),
        );

        if (_initialResponse.status === 'InvalidCredentials') {
          setInitialResponse(_initialResponse);
          setAltinnUserCredentials(false);
        } else {
          setAltinnUserCredentials(false);
          setInitialState(false);
          setInitialResponse(_initialResponse);
        }
      } catch (e) {
        setAltinnUserCredentials(false);
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    } else {
      const reconcilingDate = new Date(reconcilingFromDate);
      const reconcilingYear = reconcilingDate.getFullYear();
      const reconcilingMonth = reconcilingDate.getMonth() + 1;

      // Convert bi-monthly period to monthly periods
      const convertBiMonthlyToMonthly = (biMonthlyPeriod) => {
        const startMonth = (biMonthlyPeriod - 1) * 2 + 1;
        return [startMonth, startMonth + 1];
      };

      let periodList = [];
      if (periodSelectPerRow) {
        // For row-by-row selection, use the directly selected periods
        periodList = Array.from(new Map(selectedRows).values())
          .map((item) => parseInt(item.period, 10))
          .sort((a, b) => a - b); // Sort periods in ascending order
      } else if (company.currentAccountingYear > reconcilingYear) {
        // If current year is after reconciling year, allow all periods
        if (bimonthly) {
          periodList = Array.from({ length: selectedPeriod }, (_, i) =>
            convertBiMonthlyToMonthly(i + 1),
          ).flat();
        } else {
          periodList = Array.from({ length: selectedPeriod }, (_, i) => i + 1);
        }
      } else {
        // For current year, respect reconciling month
        const startBiMonth = Math.ceil(reconcilingMonth / 2);

        if (bimonthly) {
          // Convert selected bi-monthly period to monthly periods
          periodList = Array.from(
            { length: selectedPeriod - startBiMonth + 1 },
            (_, i) => convertBiMonthlyToMonthly(startBiMonth + i),
          ).flat();
        } else {
          // For monthly, use direct month numbers
          periodList = Array.from(
            { length: selectedPeriod - reconcilingMonth + 1 },
            (_, i) => reconcilingMonth + i,
          );
        }
      }

      // Only filter by reconciling month if we're in the reconciling year
      if (company.currentAccountingYear === reconcilingYear) {
        periodList = periodList.filter(
          (period) => period <= 12 && period >= reconcilingMonth,
        );
      } else {
        // Just ensure periods are valid (1-12)
        periodList = periodList.filter((period) => period >= 1 && period <= 12);
      }

      try {
        setAltinnFetchDetails(true);

        const generalQuery = {
          fromDate,
          periodList,
          toDate: new Date().toISOString(),
          year: company.currentAccountingYear,
          userSSN: ssn || '',
          password: altinnUserPassword,
          companyId: company.currentCompanySID,
          isForceUpdate: true,
          isActiveSession: !!sessionValidate,
          altinnAuthenticatCode: otp || '',
        };

        if (mode === 'company') {
          await dispatch(
            resourceCreateRequest(altinnAuthenticatePinApi, {
              pin: otp || '',
              userSSN: ssn,
              password: altinnUserPassword,
              companyId: company.currentCompanySID,
              forceUpdate: true,
            }),
          );
          _authenticateResponse = await dispatch(
            resourceCreateRequest(altinnFetchApi, generalQuery),
          );
        } else if (mode === 'organization') {
          const companyIdList = [];
          const periodValues = periods.map((period) => period + 1);
          const selectedCompanyIdRowItems = new Map(selectedCompanyIdRows);
          selectedCompanyIdRowItems.forEach((item) =>
            companyIdList.push(parseInt(item.companyId, 10)),
          );

          const organizationSid = user.organizationSID;
          const query = {
            altinnAuthenticateCode: {
              ...generalQuery,
              periodList: singlePeriod ? [toPeriod] : periodValues,
              organizationSid,
            },
            companyIdList,
          };
          const fetchFunc =
            subTabEntry === 'vat'
              ? updateCompanyListAltinnVAT
              : updateCompanyListAltinn;

          _authenticateResponse = await fetchFunc(query);
        } else if (mode === 'VAT') {
          _authenticateResponse = await dispatch(
            resourceCreateRequest(fetchVatReportsFromAltinnApi, generalQuery),
          );
        }

        if (_authenticateResponse) {
          setAltinnFetchDetails(false);
          setFetchSuccess(true);
          setAuthenticateResponse(_authenticateResponse);
        }
      } catch (e) {
        setFetchSuccess(false);
        setAltinnFetchDetails(false);
        setAuthenticateResponse(_authenticateResponse);
        setError(
          e.response &&
            e.response.headers &&
            e.response.headers.get('Response-Message'),
        );
      }
    }
  };

  const handlePeriodChange = (e) => {
    const selectedPeriod = parseInt(e.target.getAttribute('id'), 10);

    if (!isValidPeriod(selectedPeriod)) {
      return; // Don't allow selection of invalid periods
    }

    // For bimonthly, ensure we select up to the chosen period
    if (bimonthly) {
      setActivePeriods(selectedPeriod);
    } else {
      setActivePeriods(selectedPeriod);
    }
  };

  const handlePeriodSelectPerRow = () => {
    setPeriodSelectPerRow(!periodSelectPerRow);
    setSelectedRows([]);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    if (authenticateResponse?.status) {
      dispatch(
        resourceCreateRequest(retrieveAltinnSessionApi, {
          companySID: company.currentCompanySID,
        }),
      );
    }
  }, [authenticateResponse?.status]);

  return (
    <AltinnConfigModal
      {...{
        mode,
        error,
        company,
        loading,
        bimonthly,
        onSubmit,
        handleAction,
        toggleWindow,
        altinnSignIn,
        initialState,
        fetchSuccess,
        activePeriods,
        initialResponse,
        sessionValidate,
        actionSubmitting,
        altinnFetchDetails,
        periodSelectPerRow,
        handlePeriodChange,
        authenticateResponse,
        altinnUserCredentials,
        altinnUpdatedDataList,
        handlePeriodSelectPerRow,
        reconcilingFromDate,
      }}
    />
  );
}

export default AltinnConfigModalContainer;

AltinnConfigModalContainer.propTypes = {
  mode: PropTypes.string,
  altinnSignIn: PropTypes.func.isRequired,
  periods: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedCompanyIdRows: PropTypes.shape({}).isRequired,
  toPeriod: PropTypes.number,
  singlePeriod: PropTypes.bool,
  toggleWindow: PropTypes.bool.isRequired,
  activePeriods: PropTypes.number,
  bimonthly: PropTypes.bool,
  subTabEntry: PropTypes.string,
};

AltinnConfigModalContainer.defaultProps = {
  mode: 'company',
  toPeriod: 1,
  activePeriods: 12,
  bimonthly: false,
  singlePeriod: false,
  subTabEntry: undefined,
};
