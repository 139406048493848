import { WebStorageStateStore /* ,IndexedDbDPoPStore */ } from 'oidc-client-ts';

/* eslint-disable max-len */
const apiEnvList = [
  { name: 'localhost', url: 'http://localhost:3000' },
  { name: 'sandbox', url: 'https://sandbox.accountflow.com' },
  { name: 'balances-production', url: 'https://accountflow.net' },
  { name: 'production', url: 'https://app.accountflow.com' },
  { name: 'production-internal', url: 'https://app.accountflow.internal' },
  { name: 'sandbox-ms', url: 'https://app.sandbox-ms.accountflow.com' },
];

/**
 * Detect the API environment. If BALANCES_ENV is defined, set the environment to that.
 * Otherwise, if the URL begins with one of the API URLs in apiEnvList,
 * then we know which environment we're in. Otherwise, we're in localhost.
 * */

const apiEnv =
  process.env.BALANCES_ENV ||
  apiEnvList.find(({ url }) => window.location.href.startsWith(url))?.name ||
  'localhost';

let oidcAuthority;

if (apiEnv === 'production-internal') {
  oidcAuthority = 'https://auth1.accountflow.internal/realms/Production';
} else if (apiEnv === 'sandbox-ms') {
  oidcAuthority = 'https://auth.sandbox-ms.accountflow.com/realms/Production';
} else {
  oidcAuthority = 'https://auth1.accountflow.com/realms/Production';
}

const oidcAuthorityFinal = process.env.OIDC_AUTHORITY || oidcAuthority;

export const config = {
  apiEnv,
  gaTrackingID: process.env.GA_TRACKING_ID,
  isDev: process.env.NODE_ENV !== 'production',
  isProdEnv: process.env.BALANCES_ENV === 'production',
  basename: '',
  isBrowser: typeof window !== 'undefined',
  apiUrl: '',
  /** @type {import('react-oidc-context').AuthProviderProps} */
  oidc: {
    authority: oidcAuthorityFinal,
    client_id: process.env.OIDC_CLIENT || 'frontend.accountflow.production',
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
    scope: 'openid offline_access profile',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
      const activeUrl = localStorage.getItem('activeUrl');
      if (activeUrl) {
        localStorage.removeItem('activeUrl');
        window.location.href = activeUrl;
      }
    },
  },
};

export default config;
