import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Plurals from 'make-plural';
import { fromAuth } from 'store/selectors';
import { I18nProvider } from '@lingui/react';
import { i18n as i18nItem } from '@lingui/core';
import { detect, fromNavigator } from '@lingui/detect-locale';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from 'constants/general';

export const i18n = i18nItem;

SUPPORTED_LANGUAGES.forEach(async (lang) => {
  const catalog = await import(
    `@lingui/loader!../../../../locale/${lang}/messages.po`
  );
  i18n.loadLocaleData(lang, { plurals: Plurals[lang] });
  i18n.load({
    [lang]: catalog?.messages,
  });
});

const detectLanguage = (detected) =>
  SUPPORTED_LANGUAGES.includes(detected?.substring(0, 2)) &&
  detected?.substring(0, 2);

function I18nLoader({ user, children }) {
  const locale = detect(detectLanguage(fromNavigator()), DEFAULT_LANGUAGE);

  useEffect(() => {
    i18n.activate(user?.languageCode || locale);
  }, [user, locale]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
});

I18nLoader.propTypes = {
  user: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

I18nLoader.defaultProps = {
  user: null,
};

export default connect(mapStateToProps)(I18nLoader);
