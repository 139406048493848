import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import _compact from 'lodash-es/compact';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { BsCheckLg } from '@react-icons/all-files/bs/BsCheckLg';
import { BsXLg } from '@react-icons/all-files/bs/BsXLg';

import CardPDFTitle from 'containers/CardPDFTitle';
import { ButtonIcon, Card, CustomTableWithAutoSettings } from 'components';

import { filterByActivatedStatuses } from 'services/shapes';
import { adminCompanySettingsColumns } from 'services/tableshapes';
import { adminCompanySettingsFilters } from 'services/tableFilterShapes';

const legends = [
  {
    color: 'default-bg',
    title: t`Grayed out companies are companies you don't have access to.`,
  },
];

function AdminCompanySettingsTable(props) {
  const {
    data,
    title,
    search,
    loading,
    roleType,
    getRowColor,
    handleAction,
    cmaUserStatus,
    actionSubmitting,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'name',
        desc: false,
      },
    ],
    [],
  );
  const extraButtons = [
    {
      id: 'addMulti',
      icon: <ButtonIcon as={FaPlus} type="extraButton" />,
      title: t`Add Company`,
      superSpecialAddCompanyButton: true,
      buttonProps: {
        size: 'sm',
        kind: 'default',
        fill: false,
        magnify: true,
      },
      options: [
        {
          id: 'addVismaBusinessNXTCompany',
          title: t`Add Visma Business NXT`,
        },
        {
          id: 'addEAccountingCompany',
          title: t`Add EAccounting`,
        },
        {
          id: 'addXeroCompany',
          title: t`Add Xero`,
        },
        {
          id: 'addCompany',
          title: t`Add other systems...`,
        },
      ],
    },
  ];

  if (!cmaUserStatus)
    extraButtons[0].options.push({
      id: 'addConnectMyAppsUser',
      title: t`Add ConnectMyApps user`,
    });

  const globalFilter = useMemo(
    () => ({
      recordStatus: filterByActivatedStatuses().filter(
        (item) => item.id === 'activated',
      ),
    }),
    [],
  );

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-adminCompanySettings">
      <CardPDFTitle title={title} />
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          legends,
          getRowColor,
          handleAction,
          actionSubmitting,
          columns: adminCompanySettingsColumns(
            search,
            roleType?.isSystemAdmin || roleType?.isSuperAdmin,
          ),
          tableId: 'adminCompanySettings',
          loadingId: 'companyId',
          reportMode: search.reportMode,
          withCellClick: true,
          filterConfigs: adminCompanySettingsFilters(data),
          headerConfigs: {
            extraButtons: _compact(extraButtons),
            exportFileName: title,
          },
          selectConfigs: {
            buttons: [
              {
                id: 'addCompanyGroup',
                icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                title: t`Create New Company Group`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: true,
                  magnify: true,
                },
              },
              {
                id: 'activate',
                icon: <ButtonIcon as={BsCheckLg} type="extraButton" />,
                title: t`Activate`,
                buttonProps: {
                  size: 'sm',
                  kind: 'success',
                  fill: true,
                  magnify: true,
                },
              },
              {
                id: 'deactivate',
                icon: <ButtonIcon as={BsXLg} type="extraButton" />,
                title: t`Deactivate`,
                buttonProps: {
                  size: 'sm',
                  kind: 'danger',
                  fill: true,
                  magnify: true,
                },
              },
            ],
          },
          initialState: {
            sortBy,
            globalFilter,
          },
        }}
      />
    </Card>
  );
}

AdminCompanySettingsTable.propTypes = {};
AdminCompanySettingsTable.defaultProps = {};

export default AdminCompanySettingsTable;
