import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash-es/isEmpty';
import styled from 'styled-components/macro';
import _uniqBy from 'lodash-es/uniqBy';
import toast from 'react-hot-toast';
import { plural, t, Trans } from '@lingui/macro';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';

import {
  Toggle,
  Spinner,
  GeneralTemplate,
  GenerateReportButton,
  AccountDrilldownInfo,
  AnimateAlert,
} from 'components';

import TableModals from 'containers/TableModals';
import CommentsModal from 'containers/CommentsModal';
import FileUploadModal from 'containers/FileUploadModal';
import AccrualsInfoModal from 'containers/AccrualsInfoModal';
import AccrualsOriginModal from 'containers/AccrualsOriginModal';
import CrossYearUnlinkModal from 'containers/CrossYearUnlinkModal';
import CreateReconciliationModal from 'containers/CreateReconciliationModal';

import { resourceListReadSuccess } from 'store/resource/actions';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import {
  formatNumber,
  commentsRouteInitializer,
  downloadFile,
} from 'services/helpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceListReadRequest,
} from 'store/actions';
import {
  reconcileApi,
  getFileListApi,
  deleteCommentApi,
  getIsCrossYearApi,
  updateCrossYearApi,
  getGLAccountDetails,
  setAccrualsCommentApi,
  editCurrentCommentApi,
  deactivateCrossYearApi,
  unLinkReconciliationApi,
  updateReconcileStatusApi,
  getTransactionDialogueApi,
  updateTransactionCommentApi,
  unLinkPartialReconciliationApi,
  saveReconciliationUserCommentApi,
  updateTransactionCommentCreateApi,
  getFilesFromTransactionLevel,
  getReconciliationGLAccountsFastApi,
  getLatestReportApi,
  downloadReportApi,
  cleanReconsApi,
} from 'services/apihelpers';
import {
  getGLAccrualsRowColor,
  getGLRowColor as getRowColor,
} from 'services/helpers/tables';
import { useGeneralLedgerReportGenerator } from 'services/hooks/reports';
import {
  accrualsDefaultValues,
  reconcileDefaultValues,
} from 'constants/reportSettings';

const reconcileSelectConverter = (selectedRows) => {
  let str = '';
  const selectedSum = selectedRows.reduce(
    (sum, row) => sum + row.original.amount,
    0,
  );

  str += plural(selectedRows.length, {
    zero: 'No rows selected',
    one: `${selectedRows.length} row selected`,
    other: `${selectedRows.length} rows selected`,
  });

  str += ' → ';

  str += t`Total: ${formatNumber(selectedSum).formatted}`;

  return str;
};
const accrualsSelectConverter = (selectedRows) => {
  let str = '';
  const selectedSum = selectedRows.reduce(
    (sum, row) => sum + row.original.sumRemaining,
    0,
  );

  str += plural(selectedRows.length, {
    zero: 'No rows selected',
    one: `${selectedRows.length} row selected`,
    other: `${selectedRows.length} rows selected`,
  });

  str += ' → ';

  str += t`Total: ${formatNumber(selectedSum).formatted}`;

  return str;
};

function GeneralTemplateContainer(props) {
  const auth = useAuth();
  const { account, accountName, roleType } = props;

  const [alertProps, setAlertProps] = useState({
    show: false,
    type: 'warning',
    title: t`Warning`,
    onCancel: () => {},
    onConfirm: () => {},
    children: <div />,
    showCancel: true,
    cancelBtnText: t`Close`,
    confirmBtnText: t`Delete`,
    closeOnClickOutside: true,
  });

  const [filters, setFilters] = useState({
    generalAccruals: accrualsDefaultValues,
    generalReconcile: reconcileDefaultValues,
  });

  const dispatch = useDispatch();

  const [modalProps, setModalProps] = useState({});
  const [{ loading, accrualsLoading, loadingCrossYear }, setLoading] = useState(
    {
      loading: false,
      accrualsLoading: false,
      loadingCrossYear: false,
    },
  );
  const [hideAccruals, setHideAccruals] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});
  const [selectedRows, setSelectedRows] = useState({
    Accruals: [],
    Reconcile: [],
  });
  const [manualSelectFunc, setManualSelectFunc] = useState({
    reconcile: null,
    accruals: null,
  });

  const { accountingYear, accountingPeriod, companyId } = useParams();

  const user = useSelector((state) => fromAuth.getUser(state));
  const company = useSelector((state) => fromCompany.getCompany(state));
  const fetchedData = useSelector((state) =>
    fromResource.getList(state, `${getGLAccountDetails}/${account}`),
  );
  const files = useSelector((state) =>
    fromResource.getList(state, getFileListApi),
  );
  const isCrossYear = useSelector(
    (state) => fromResource.getList(state, getIsCrossYearApi).status,
  );
  const accountList = useSelector((state) =>
    fromResource.getList(state, getReconciliationGLAccountsFastApi),
  );

  const memoData = useMemo(() => {
    let data = [];
    let accrualsData = [];
    let accountInfo = {};

    if (!isEmpty(fetchedData)) {
      data = fetchedData.transactionLineDtos || [];
      accrualsData = fetchedData.accruals || [];
      accountInfo = fetchedData.account;
    }

    return { data, accrualsData, accountInfo };
  }, [fetchedData]);
  const { data, accrualsData, accountInfo } = memoData;

  const { currentCompanySID, currentPeriodType } = company || {};

  const filesShortPreview = useMemo(() => {
    if (files?.transactionFileList) {
      return files.transactionFileList.map((item) => item.fileName);
    }

    return null;
  }, [files]);

  /* eslint-disable max-len */
  const crossYearTooltip = t`Cross year reconciliation
    Function:
    A button on the top of an account overview that can turn off or on the functionality having your current year from the application as "starting year" for the cross year reconciliation. In other words, if you are in 2020, turn it on, then 2019 will not be part of the cross year reconciliation.
    If this is enabled:
    The year after starting year would have access to earlier years' open posts from the starting year.
    If this is disabled:
    Displays a list of transactions/lines that are reconciled cross years, and that these will be unlinked/reset upon deactivation. This requires an approval (that the user clicks "Ok/Submit") so that it can't be a miss click where all cross year reconciliation would be unlinked/reset.`;
  /* eslint-disable max-len */

  const getRowAccrualsColor = (row) =>
    getGLAccrualsRowColor(row, accountingPeriod, accountingYear);

  const getFiles = async () => {
    const glFiles = [];

    const transactionFiles = await dispatch(
      resourceCreateRequest(
        // eslint-disable-next-line max-len
        `${getFilesFromTransactionLevel}?companyId=${companyId}&year=${accountingYear}&period=${accountingPeriod}`,
        {
          accountIds: [
            {
              accountId: account,
            },
          ],
        },
      ),
    );

    if (files?.transactionFileList) {
      glFiles.push(
        ...files.transactionFileList.map((item) => ({
          key: item.fileKey,
          name: item.fileName,
        })),
      );
    }

    if (transactionFiles?.length) {
      glFiles.push(
        ...transactionFiles[0].fileNamesAndKeys.map((file) => ({
          name: file.fileName,
          key: file.fileKey,
          // eslint-disable-next-line max-len
          folderName: `${transactionFiles[0].lineId}`,
        })),
      );
    }

    return glFiles;
  };

  const getManualSelectFuncReconcile = (func) => {
    setManualSelectFunc((state) => ({ ...state, reconcile: func }));
  };
  const getManualSelectFuncAccruals = (func) => {
    setManualSelectFunc((state) => ({ ...state, accruals: func }));
  };

  const clearSelectedIds = () => {
    manualSelectFunc.reconcile([]);
    manualSelectFunc.accruals([]);
  };

  const fetchFiles = async () => {
    try {
      await dispatch(
        resourceListReadRequest(getFileListApi, {
          account,
          toPeriod: accountingPeriod,
          companyId: currentCompanySID,
          fromPeriod: 1,
          showAll: false,
          periodType: currentPeriodType,
          periodYear: accountingYear,
          fileCategory: 'ACCOUNT',
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const { disabled, generatePDF, pdfGenerating, approveAndGeneratePDF } =
    useGeneralLedgerReportGenerator({
      data,
      getFiles,
      accountInfo,
      accrualsData,
      accrualsFilters: filters.generalAccruals,
      reconcileFilters: filters.generalReconcile,
      approveStatus: accountInfo?.approved,
      accountingYear,
      companyUUID: company?.uuid,
      currentAccount: {
        accountId: account,
        name: accountName,
      },
      accountingPeriod,
      currentPeriodType,
      loading: loading || accrualsLoading,
    });

  const checkIsCrossYear = async () => {
    setLoading((state) => ({ ...state, loadingCrossYear: true }));

    try {
      await dispatch(
        resourceListReadRequest(getIsCrossYearApi, {
          account,
          year: accountingYear,
          companyId: currentCompanySID,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading((state) => ({ ...state, loadingCrossYear: false }));
    }
  };

  const loadTransactions = async () => {
    setLoading((state) => ({ ...state, loading: true }));

    try {
      await dispatch(
        resourceListReadRequest(`${getGLAccountDetails}/${account}`, {
          companyId,
          year: accountingYear,
          period: accountingPeriod,
          periodType: company.currentPeriodType.toUpperCase(),
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading((state) => ({ ...state, loading: false }));
    }
  };

  const loadTableData = () => {
    checkIsCrossYear();
    loadTransactions();
  };

  const toggleModal = (newModalProps = {}) => {
    setModalProps(newModalProps);
  };

  const toggleCrossYearModal = (_, refetch) => {
    toggleModal();

    if (refetch) {
      checkIsCrossYear();
    }
  };

  const toggleAccrualsModal = (_, refetch) => {
    toggleModal();

    if (refetch) {
      loadTransactions();
    }
  };

  const handleToggleCrossYear = async () => {
    setLoading((state) => ({ ...state, loadingCrossYear: true }));

    try {
      const resp = await dispatch(
        resourceUpdateRequest(updateCrossYearApi, null, {
          uuid: companyId,
          year: accountingYear,
          active: !isCrossYear,
          accountId: account,
        }),
      );

      if (resp?.listOfBreakingLines?.length) {
        toggleModal({ type: 'crossYearUnlink', row: resp });
      } else if (isCrossYear) {
        await dispatch(resourceCreateRequest(deactivateCrossYearApi, resp));
        checkIsCrossYear();
      } else {
        checkIsCrossYear();
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading((state) => ({ ...state, loadingCrossYear: false }));
    }
  };

  const afterCloseModal = (modal, _account, _files) => {
    if (modal === 'handleDocumentUpload') {
      dispatch(
        resourceListReadSuccess(getFileListApi, {
          ...files,
          transactionFileList: _files,
        }),
      );

      if (fetchedData?.account) {
        dispatch(
          resourceListReadSuccess(`${getGLAccountDetails}/${account}`, {
            ...fetchedData,
            account: { ...fetchedData.account, hasFile: !!_files?.length },
          }),
        );
      }
    }
  };

  const renderModal = () => {
    const { row, type } = modalProps;

    let content = false;

    const handleToggleModal = (_, enabled) => {
      let key = 'hasDialogue';

      if (type === 'files') {
        key = 'hasFiles';
      }

      toggleModal();

      const collection = fetchedData?.transactionLineDtos?.map((item) => {
        const el = { ...item };

        if (row.recordId === item.recordId) {
          el[key] = enabled;
        }

        return el;
      });

      dispatch(
        resourceListReadSuccess(`${getGLAccountDetails}/${account}`, {
          ...fetchedData,
          transactionLineDtos: collection,
        }),
      );
    };

    switch (type) {
      case 'dialog': {
        content = (
          <CommentsModal
            {...{
              user,
              company,
              roleType,
              showModal: true,
              toggleModal: handleToggleModal,
              componentSetupValues: {
                recordId: row.recordId,
                transactionSID: row.transactionSid,
                commentModalType: 'lineLevelComment',
                commentsRoutes: commentsRouteInitializer('lineLevelComment', {
                  commentsRoutes: {
                    deleteCommentApi,
                    editCurrentCommentApi,
                    getTransactionDialogueApi,
                    updateTransactionCommentApi,
                    updateTransactionCommentCreateApi,
                  },
                }),
              },
            }}
          />
        );
        break;
      }

      case 'files': {
        content = (
          <FileUploadModal
            {...{
              user,
              company,
              account,
              roleType,
              recordId: row.recordId,
              showModal: true,
              toggleModal: handleToggleModal,
              fileCategory: 'TRANSACTION',
              transactionSID: row.transactionSid,
            }}
          />
        );
        break;
      }

      case 'create': {
        content = (
          <CreateReconciliationModal
            toggleModal={toggleModal}
            accountList={accountList}
            row={selectedRows?.Reconcile?.[0]}
          />
        );
        break;
      }

      case 'crossYearUnlink': {
        content = (
          <CrossYearUnlinkModal data={row} toggleModal={toggleCrossYearModal} />
        );
        break;
      }

      case 'viewDetails': {
        content = <AccrualsInfoModal item={row} toggleModal={toggleModal} />;
        break;
      }

      case 'selectOrigin': {
        let options = [];
        let selectedOptions = [];

        if (row.origin) {
          options = options.concat(row.origin);
          selectedOptions = row.origin.map((el) => el.recordId);
        }

        if (row.accrual) {
          options = options.concat(row.accrual);
        }

        content = (
          <AccrualsOriginModal
            optionsList={options}
            toggleModal={toggleAccrualsModal}
            selectedOptions={selectedOptions}
            reconciliationId={row?.reconciliationId}
            partialReconciliationId={row?.partialReconciliationId}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return content;
  };

  const topSelectConverter = () => {
    let str = '';
    const selectedOB = selectedRows.Accruals.reduce(
      (sum, row) => sum + row.sumRemaining,
      0,
    );
    const selectedUB = selectedRows.Reconcile.reduce(
      (sum, row) => sum + row.amount,
      0,
    );

    str += plural(selectedRows.Accruals.length, {
      zero: 'No Accruals rows selected',
      one: `${selectedRows.Accruals.length} Accruals row selected`,
      other: `${selectedRows.Accruals.length} Accruals rows selected`,
    });

    str += ' - ';

    str += plural(selectedRows.Reconcile.length, {
      zero: 'No Reconcile rows selected',
      one: `${selectedRows.Reconcile.length} Reconcile row selected`,
      other: `${selectedRows.Reconcile.length} Reconcile rows selected`,
    });

    str += ' → ';

    str += t`Deviation: ${formatNumber(selectedOB + selectedUB).formatted}`;

    return str;
  };

  const accrualsSelectCondition = () => {
    let valid = true;

    if (selectedRows.Accruals?.length) {
      valid = false;

      toast.error(t`Only 1 Accrual row available to select`);
    } else if (
      selectedRows.Reconcile?.length &&
      selectedRows.Reconcile.find(
        (item) => item.reconciliationStatus !== 'OPEN',
      )
    ) {
      valid = false;

      toast.error(
        // eslint-disable-next-line max-len
        t`Only OPEN Reconcile lines are allowed to be matched with Accrual, please adjust Reconcile table selection`,
      );
    }

    return valid;
  };

  const reconcileSelectCondition = (row) => {
    let valid = true;

    if (selectedRows.Accruals?.length && row.reconciliationStatus !== 'OPEN') {
      valid = false;

      toast.error(
        t`Only OPEN Reconcile lines are allowed to be matched with Accrual`,
      );
    }

    return valid;
  };

  const handleToggleAccruals = () => {
    setHideAccruals((state) => !state);
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting((state) => ({ ...state, [action]: submitting }));
  };

  const downloadLastPackage = async () => {
    _setActionSubmitting('downloadLastPackage', true);
    const query = {
      companyUUID: companyId,
      accountingYear,
      accountingPeriod,
      reportType: ['ACCOUNTS'],
      accountId: account,
    };

    const latestReport = await dispatch(
      resourceListReadRequest(getLatestReportApi, query),
    );

    if (latestReport?.ACCOUNTS?.length) {
      const { storageLocation, version } = latestReport.ACCOUNTS[0];
      await downloadFile(auth.user?.access_token, {
        /* eslint-disable max-len */
        url: `/${downloadReportApi}?companyUUID=${companyId}&file=${storageLocation}&fileName=${version}.zip`,
        name: t`${account} - ${accountName} (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`,
      });
    } else {
      toast.error('No report found');
    }
    _setActionSubmitting('downloadLastPackage', false);
  };

  const _setSelectedRows = (_props) => {
    const { tableId, item, selected } = _props;

    if (!tableId) {
      setSelectedRows({ Accruals: [], Reconcile: [] });
      return;
    }

    const newRows = { ...selectedRows };
    const idKey =
      tableId === 'Accruals' ? 'partialReconciliationId' : 'recordId';

    if (!newRows[tableId]) {
      newRows[tableId] = [];
    }

    if (item === 'all') {
      if (!selected) {
        newRows[tableId] = [];
      }
    } else if (Array.isArray(item)) {
      newRows[tableId] = [...item];
    } else if (selected) {
      const index = newRows[tableId].findIndex(
        (el) => el[idKey] === item[idKey],
      );

      if (index === -1) {
        newRows[tableId] = [...newRows[tableId], { ...item }];
      }
    } else {
      newRows[tableId] = [...newRows[tableId]];

      const index = newRows[tableId].findIndex(
        (el) => el[idKey] === item[idKey],
      );

      if (index >= 0) {
        newRows[tableId].splice(index, 1);
      }
    }

    if (newRows[tableId].length) {
      newRows[tableId] = _uniqBy(newRows[tableId], idKey);
    }

    setSelectedRows(newRows);
  };

  const handleReconcileTopSelection = async () => {
    const { Accruals, Reconcile } = selectedRows;
    const setArrItemsLoading = (_loading) => {
      setActionSubmitting((state) => {
        const newActionSubmitting = { ...state };

        Accruals.forEach((item) => {
          newActionSubmitting[item.partialReconciliationId] = _loading;
        });

        Reconcile.forEach((item) => {
          newActionSubmitting[item.recordId] = _loading;
        });

        return newActionSubmitting;
      });
    };

    try {
      setArrItemsLoading(true);

      const formatItem = (row) => ({
        amount: parseFloat(row.amount),
        lineId: row.lineSID,
        reconcileStatus: row.reconciliationStatus,
        reconcileRecordId: row.recordId,
      });

      const reconcileVoList = Reconcile.map(formatItem);

      Accruals.forEach((item) => {
        reconcileVoList.push(
          ...item.accrual.map(formatItem),
          ...item.origin.map(formatItem),
        );
      });

      await dispatch(
        resourceCreateRequest(reconcileApi, {
          account,
          accrual: true,
          reconcileVoList,
          period: accountingPeriod,
          year: accountingYear,
          companyId: currentCompanySID,
        }),
      );

      setArrItemsLoading(false);
      loadTableData();
    } catch (err) {
      setArrItemsLoading(false);
      throw err;
    }
    setSelectedRows({ Accruals: [], Reconcile: [] });
  };

  const handleCommentChange = async (comment, row, tableId) => {
    if (tableId === 'Accruals') {
      try {
        _setActionSubmitting(row.partialReconciliationId, true);

        await dispatch(
          resourceUpdateRequest(setAccrualsCommentApi, null, {
            comment,
            reconciliationId: row.reconciliationId,
            partialReconciliationId: row.partialReconciliationId,
          }),
        );

        const collection = fetchedData?.accruals?.map((item) => {
          const el = { ...item };

          if (
            row.partialReconciliationId === item.partialReconciliationId &&
            item.reconciliationId === row.reconciliationId
          ) {
            el.comment = comment;
          }

          return el;
        });

        dispatch(
          resourceListReadSuccess(`${getGLAccountDetails}/${account}`, {
            ...fetchedData,
            accruals: collection,
          }),
        );
        _setActionSubmitting(row.partialReconciliationId, false);
      } catch (err) {
        _setActionSubmitting(row.partialReconciliationId, false);
        throw err;
      }
    } else {
      try {
        _setActionSubmitting(row.recordId, true);

        await dispatch(
          resourceCreateRequest(saveReconciliationUserCommentApi, {
            year: accountingYear,
            lineSid: row.lineSID,
            companyId: currentCompanySID,
            userComment: comment,
          }),
        );

        const collection = fetchedData?.transactionLineDtos?.map((item) => {
          const el = { ...item };

          if (row.recordId === item.recordId) {
            el.comment = comment;
          }

          return el;
        });

        dispatch(
          resourceListReadSuccess(`${getGLAccountDetails}/${account}`, {
            ...fetchedData,
            transactionLineDtos: collection,
          }),
        );
        _setActionSubmitting(row.recordId, false);
      } catch (err) {
        _setActionSubmitting(row.recordId, false);
        throw err;
      }
    }
  };

  const handleCellSave = async ({ value, row, id }, tableId) => {
    if (id === 'comment') {
      await handleCommentChange(value, row, tableId);
    }
  };
  const handleUnreconcile = async (row, tableId) => {
    try {
      _setActionSubmitting(
        tableId === 'Accruals' ? row.partialReconciliationId : row.recordId,
        true,
      );

      if (
        row.reconciliationStatus === 'PARTIAL_RECONCILED' ||
        row.origin?.[0]?.reconciliationStatus === 'PARTIAL_RECONCILED'
      ) {
        await dispatch(
          resourceCreateRequest(unLinkPartialReconciliationApi, {
            reconId:
              typeof row.partialReconciliationId !== 'undefined'
                ? row.partialReconciliationId
                : row.partialReconciliationSID,
            companyId: currentCompanySID,
          }),
        );
      } else {
        await dispatch(
          resourceCreateRequest(unLinkReconciliationApi, {
            reconId:
              typeof row.reconciliationId !== 'undefined'
                ? row.reconciliationId
                : row.reconciliationSID,
            companyId: currentCompanySID,
          }),
        );
      }

      _setActionSubmitting(
        tableId === 'Accruals' ? row.partialReconciliationId : row.recordId,
        false,
      );
      loadTableData();
    } catch (err) {
      _setActionSubmitting(
        tableId === 'Accruals' ? row.partialReconciliationId : row.recordId,
        false,
      );
      throw err;
    }
  };

  const handleApprove = async (reconcileStatus, reconcileStatusList) => {
    const query = {
      reconcileStatus,
      reconcileStatusList,
      period: accountingPeriod,
      companyId: currentCompanySID,
    };

    const setArrItemsLoading = (_loading) => {
      setActionSubmitting((state) => {
        const newActionSubmitting = { ...state };
        reconcileStatusList.forEach((item) => {
          newActionSubmitting[item.recordId] = _loading;
        });

        return newActionSubmitting;
      });
    };

    try {
      setArrItemsLoading(true);

      await dispatch(
        resourceUpdateRequest(updateReconcileStatusApi, null, query),
      );

      const collection = fetchedData?.transactionLineDtos?.map((item) => {
        const el = { ...item };

        if (reconcileStatusList.find((row) => row.recordId === item.recordId)) {
          el.reconciliationStatus = reconcileStatus;
        }

        return el;
      });

      dispatch(
        resourceListReadSuccess(`${getGLAccountDetails}/${account}`, {
          ...fetchedData,
          transactionLineDtos: collection,
        }),
      );
      setArrItemsLoading(false);
    } catch (e) {
      setArrItemsLoading(false);
      throw e;
    }
  };

  const handleReconcile = async (rows) => {
    const setArrItemsLoading = (_loading) => {
      setActionSubmitting((state) => {
        const newActionSubmitting = { ...state };
        rows.forEach((item) => {
          newActionSubmitting[item.recordId] = _loading;
        });

        return newActionSubmitting;
      });
    };

    try {
      setArrItemsLoading(true);

      await dispatch(
        resourceCreateRequest(reconcileApi, {
          account,
          year: accountingYear,
          period: accountingPeriod,
          companyId: currentCompanySID,
          reconcileVoList: rows.map((row) => ({
            amount: Number.parseFloat(row.amount),
            lineId: row.lineSID,
            reconcileStatus: row.reconciliationStatus,
            reconcileRecordId: row.recordId,
          })),
        }),
      );

      setArrItemsLoading(false);
      loadTableData();
    } catch (err) {
      setArrItemsLoading(false);
      throw err;
    }
    setSelectedRows({ Accruals: [], Reconcile: [] });
  };

  const handleCleanRecons = async () => {
    setAlertProps({
      show: true,
      type: 'warning',
      title: t`Warning`,
      onConfirm: () => {
        dispatch(
          resourceCreateRequest(cleanReconsApi, {
            companySid: currentCompanySID,
            accountId: account,
          }),
        );
        setAlertProps({
          show: false,
        });
        setTimeout(() => {
          loadTableData();
        }, 625);
      },
      onCancel: () => {
        setAlertProps({
          show: false,
        });
      },
      children: (
        <Trans>
          This will break all invalid reconciliations for this account. This
          action cannot be undone.
        </Trans>
      ),
      showCancel: true,
      cancelBtnText: t`Close`,
      confirmBtnText: t`Clean`,
      closeOnClickOutside: true,
    });
  };

  const handleAction = async (action, item, tableId) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'topReconcile': {
          await handleReconcileTopSelection();
          break;
        }

        case 'rowSelect': {
          _setSelectedRows(item);
          break;
        }

        case 'files':
        case 'dialog':
        case 'viewDetails': {
          toggleModal({ type: action, row: item });
          break;
        }

        case 'crossYearUnlink': {
          await handleToggleCrossYear();
          break;
        }

        case 'selectOrigin': {
          toggleAccrualsModal(item);
          toggleModal({ type: action, row: item });
          break;
        }

        case 'create': {
          toggleModal({ type: action });
          break;
        }

        case 'cellSave': {
          await handleCellSave(item, tableId);
          break;
        }

        case 'unlink': {
          await handleUnreconcile(item, tableId);
          break;
        }

        case 'approve': {
          await handleApprove('APPROVED', item);
          break;
        }

        case 'unApprove': {
          await handleApprove('OPEN', item);
          break;
        }

        case 'partialReconcile': {
          await handleReconcile(item);
          break;
        }

        case 'cleanRecons': {
          await handleCleanRecons();
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const resetReportFilters = () => {
    setFilters({
      generalAccruals: accrualsDefaultValues,
      generalReconcile: reconcileDefaultValues,
    });
  };

  useEffect(() => {
    loadTableData();
  }, [account, accountingPeriod, accountingYear, companyId, currentCompanySID]);

  useEffect(() => {
    setHideAccruals(!Array.isArray(accrualsData) || !accrualsData?.length);
  }, [accrualsData]);

  useEffect(() => {
    if (data?.length || accrualsData?.length) {
      _setSelectedRows({ tableId: null });
    }
  }, [data, accrualsData]);

  useEffect(() => {
    fetchFiles();
  }, [
    account,
    accountingYear,
    accountingPeriod,
    currentCompanySID,
    currentPeriodType,
  ]);

  return (
    <>
      <AnimateAlert {...alertProps} />
      {renderModal()}
      {accountInfo?.accountId && !loading && (
        <AccountDrilldownInfo
          account={accountInfo}
          sourceData={fetchedData}
          reconcileTransactions={data}
          accrualsTransactions={accrualsData}
          isReadOnly={roleType.isReadOnly}
          pdfGenerating={pdfGenerating}
          approveAndGeneratePDF={approveAndGeneratePDF}
        >
          <ExtraFunctionalityHolder>
            {!roleType.isReadOnly &&
              (loadingCrossYear ? (
                <Spinner />
              ) : (
                <ToggleHolder>
                  <Toggle
                    value={isCrossYear}
                    label={t`Cross year`}
                    onClick={handleToggleCrossYear}
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip style={{ whiteSpace: 'pre-wrap' }} id="tooltip">
                        {crossYearTooltip}
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle style={{ marginLeft: 5 }} />
                  </OverlayTrigger>
                </ToggleHolder>
              ))}
            {!!accrualsData?.length && (
              <ToggleHolder>
                <Toggle
                  value={hideAccruals}
                  label={t`Hide Accruals table`}
                  onClick={handleToggleAccruals}
                />
              </ToggleHolder>
            )}
            {accountInfo?.approved && (
              <GenerateReportButton
                options="GENERAL"
                filters={filters}
                disabled={disabled}
                setFilters={setFilters}
                resetFilters={resetReportFilters}
                withVersions
                versionsMeta={{
                  reportType: 'ACCOUNTS',
                  accountId: account,
                  accountTitle: accountName,
                }}
                pdfGenerating={
                  pdfGenerating || actionSubmitting?.downloadLastPackage
                }
                generatePDF={generatePDF}
                skipDownload
                saveReport
                downloadLast={downloadLastPackage}
              />
            )}
            <TableModals
              {...{
                data: {
                  accountID: account,
                  accountTemplateCode: 'GENERAL',
                },
                user,
                company,
                account,
                roleType,
                afterCloseModal,
                filesShortPreview,
                onModalClose: loadTableData,
                enableAutoReconcile: true,
                enableDocumentUpload: true,
              }}
            />
          </ExtraFunctionalityHolder>
        </AccountDrilldownInfo>
      )}
      <GeneralTemplate
        {...{
          data,
          loading,
          roleType,
          getRowColor,
          hideAccruals,
          handleAction,
          selectedRows,
          accrualsData,
          accrualsLoading,
          actionSubmitting,
          clearSelectedIds,
          getManualSelectFuncAccruals,
          getManualSelectFuncReconcile,
          topSelectConverter,
          getRowAccrualsColor,
          accrualsSelectConverter,
          accrualsSelectCondition,
          reconcileSelectConverter,
          reconcileSelectCondition,
        }}
      />
    </>
  );
}

export default GeneralTemplateContainer;

const ExtraFunctionalityHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  ${({ mBottom }) => mBottom && 'margin-bottom: 10px;'}
`;

const ToggleHolder = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  margin-right: 20px;
  flex-direction: row;
  justify-content: flex-start;

  i {
    cursor: pointer;
    margin-left: 10px;
  }
`;
