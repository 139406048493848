import { useState, useEffect } from 'react';

const useLocalSettings = () => {
  const [localSettings, setLocalSettings] = useState({
    autoReload: true, // default value
    showHelp: false, // default value
  });

  useEffect(() => {
    const storedAutoReload = localStorage.getItem('autoReload');
    // const storedShowHelp = localStorage.getItem('showHelp');

    setLocalSettings({
      autoReload:
        storedAutoReload === null ? true : JSON.parse(storedAutoReload),
      showHelp: false, // storedShowHelp === null ? true : JSON.parse(storedShowHelp),
    });
  }, []);

  return localSettings;
};

export default useLocalSettings;
