import React from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-form';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { ButtonGroup, Modal } from 'react-bootstrap';

import {
  Button,
  Heading,
  Spinner,
  ButtonIcon,
  ReactFormField,
  CustomMultiSelectInput,
} from 'components';

import { required } from 'services/validation';

function AddEditGroupUserModal(props) {
  const {
    item,
    users,
    loading,
    onSubmit,
    closeModal,
    handleChange,
    selectedUsers,
  } = props;
  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    onSubmit,
    defaultValues: item,
  });
  const addTitle = t`Add User Group`;
  const editTitle = t`Edit User Group`;

  return (
    <Modal show onEscapeKeyDown={closeModal} bsSize="md">
      <Form>
        <Modal.Header>
          <Heading level={3}>{item.groupId ? editTitle : addTitle}</Heading>
        </Modal.Header>
        <Modal.Body>
          <ReactFormField
            noBorder
            size="small"
            name="name"
            label={t`Group name`}
            validate={[required]}
            placeholder={t`Please Enter Group name`}
            autoComplete="search"
          />
          <InputHolder>
            <CustomMultiSelectInput
              modal
              id="user"
              label={t`Selected users to be added to group`}
              loading={loading}
              options={users}
              selected={selectedUsers}
              onSelect={handleChange}
              requiredNotEmpty
              placeholder={t`Selected users to be added to group`}
            />
          </InputHolder>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              magnify
              kind="info"
              type="submit"
              disabled={!canSubmit || isSubmitting || !selectedUsers.length}
            >
              {isSubmitting ? (
                <Spinner type="white" size="md" />
              ) : (
                <>
                  <ButtonIcon as={HiCheck} /> <Trans>Save</Trans>
                </>
              )}
            </Button>
            <Button fill kind="danger" magnify onClick={closeModal}>
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddEditGroupUserModal;

const InputHolder = styled.div`
  margin-bottom: 15px;
`;
