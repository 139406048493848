import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';

export default function InfoBanner(props) {
  const { message, link, linkTitle, isExternalLink } = props;
  return (
    <div className="alert alert-info" role="alert">
      <span style={{ color: 'black' }}>{message}</span>{' '}
      {link && (
        <>
          <a
            href={link}
            rel="noreferrer"
            target={isExternalLink ? '_blank' : '_self'}
            style={{ color: 'black', textDecoration: 'underline' }}
          >
            {linkTitle}
          </a>{' '}
          {isExternalLink && (
            <FaExternalLinkAlt style={{ fontSize: '12px', color: 'black' }} />
          )}
        </>
      )}
    </div>
  );
}

InfoBanner.propTypes = {
  message: PropTypes.node.isRequired,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  isExternalLink: PropTypes.bool.isRequired,
};

InfoBanner.defaultProps = {
  link: undefined,
  linkTitle: t`Learn more `,
};
