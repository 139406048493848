import React from 'react';
import Lottie from 'react-lottie';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { BiRefresh } from '@react-icons/all-files/bi/BiRefresh';

import { Card, Button, Heading, Paragraph, ButtonIcon } from 'components';

import { ERROR_BOX } from 'constants/errorBox';
import errorAnimation from 'assets/animations/error.json';
import { useAuth } from 'react-oidc-context';

function FourOhFourPage() {
  const history = useHistory();
  const auth = useAuth();

  const title = (
    <Heading level={2} className="card-title">
      <Trans>Page not found</Trans>
    </Heading>
  );

  const { styles } = ERROR_BOX();
  const { height, ...colors } = styles.balances;

  return (
    <Container height={height}>
      <ContainerInner colors={colors}>
        {title}
        <LottieAnimation
          width="18vw"
          height="auto"
          options={{
            loop: true,
            autoplay: true,
            animationData: errorAnimation,
          }}
        />
        <ErrorContent colors={colors}>
          <Paragraph level={5} style={{ paddingBottom: '1.75rem' }}>
            <Trans>This page does not exist.</Trans>
          </Paragraph>
          <ButtonsHolder>
            <Button kind="info" fill magnify onClick={() => history.push('/')}>
              <ButtonIcon as={BiRefresh} fontSize={20} marginRight={5} />
              <Trans>Go to home</Trans>
            </Button>
            <Button
              kind="info"
              fill
              magnify
              onClick={() => auth.signoutRedirect()}
            >
              <Trans>Log out</Trans>
            </Button>
          </ButtonsHolder>
        </ErrorContent>
      </ContainerInner>
    </Container>
  );
}

export default FourOhFourPage;

const LottieAnimation = styled(Lottie)`
  height: auto;
  margin: 0;
`;

const ButtonsHolder = styled.div`
  gap: 8px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
`;

// // Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const ContainerInner = styled((props) => <Card {...props} />)`
  color: ${({ colors }) => colors.detailsColor};
  padding: 2vw;
  display: flex;
  text-align: center;
  background: ${({ colors }) => colors.background};
  align-items: center;
  border-radius: 10px;
  flex-direction: column;

  h2 {
    color: ${({ colors }) => colors.textColor};
    font-weight: 600;
  }

  .error-name {
    margin-top: 25px;
    margin-bottom: 0;
  }
`;

const ErrorContent = styled.div`
  color: ${({ colors }) => colors.textColor};
  display: flex;
  padding: 10px 0;
  background: ${({ colors }) => colors.boxColor};
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;

  font-size: 16px;

  p {
    margin: 15px 0 0;
    font-size: 16px !important;
    max-width: 80%;
  }
`;
