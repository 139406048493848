import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { t } from '@lingui/macro';
import { useAuth } from 'react-oidc-context';

import { ShowReportVersionsModal } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { getReportVersionsApi, downloadReportApi } from 'services/apihelpers';
import { fromResource, fromCompany } from 'store/selectors';
import { getErrorMessage, downloadFile } from 'services/helpers';

function ShowReportVersionsModalContainer(props) {
  const auth = useAuth();
  const { closeModal, accountId, accountTitle, reportType, period, bimonthly } =
    props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});

  const { accountingYear, accountingPeriod, companyId } = useParams();

  const reportVersionsApiUrl = new URL(
    getReportVersionsApi,
    window.location.origin,
  );
  if (accountId) {
    reportVersionsApiUrl.searchParams.append('accountId', accountId);
  }
  if (period) {
    reportVersionsApiUrl.searchParams.append('accountingPeriod', period);
  } else {
    reportVersionsApiUrl.searchParams.append(
      'accountingPeriod',
      accountingPeriod,
    );
  }

  const data = useSelector((state) =>
    fromResource.getList(
      state,
      `${reportVersionsApiUrl.pathname.substring(1)}${
        reportVersionsApiUrl.search
      }`,
    ),
  );

  const company = useSelector((state) => fromCompany.getCompany(state));

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting((prevState) => ({
      ...prevState,
      [action]: submitting,
    }));
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'downloadPDF': {
          const { storageLocation, version } = item;
          let fileName = '';
          /* eslint-disable max-len */
          switch (reportType) {
            case 'ACCOUNTS': {
              fileName = t`${accountId} - ${accountTitle} (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`;
              break;
            }
            case 'VAT': {
              fileName = t`${company.currentCompanyName} (${company.currentCompanyRegistrationNumber}) - VAT (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`;
              break;
            }
            case 'PAYROLL': {
              fileName = t`${company.currentCompanyName} (${company.currentCompanyRegistrationNumber}) - Payroll (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`;
              break;
            }
            case 'REPORT_PACKAGE': {
              fileName = t`${company.currentCompanyName} (${company.currentCompanyRegistrationNumber}) - Complete Package (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`;
              break;
            }
            default: {
              fileName = t`${company.currentCompanyName} (${company.currentCompanyRegistrationNumber}) - Report (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`;
              break;
            }
          }

          await downloadFile(auth.user?.access_token, {
            url: `/${downloadReportApi}?companyUUID=${companyId}&file=${storageLocation}&fileName=${version}.zip`,
            name: fileName,
          });
          break;
        }
        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const fetchVersions = async () => {
    try {
      setLoading(() => true);
      const query = {
        companyUUID: companyId,
        accountingYear,
        // accountingPeriod: period || accountingPeriod,
        reportType: [reportType],
      };

      await dispatch(
        resourceListReadRequest(
          `${reportVersionsApiUrl.pathname.substring(1)}${
            reportVersionsApiUrl.search
          }`,
          query,
        ),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    fetchVersions();
  }, []);

  return (
    <ShowReportVersionsModal
      data={data[reportType]}
      accountId={accountId}
      loading={loading}
      type={reportType}
      year={accountingYear}
      period={period || accountingPeriod}
      handleAction={handleAction}
      actionSubmitting={actionSubmitting}
      closeModal={closeModal}
      company={company}
      bimonthly={bimonthly}
    />
  );
}

export default ShowReportVersionsModalContainer;
