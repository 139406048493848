import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import styled from 'styled-components';

import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { AiFillWarning } from '@react-icons/all-files/ai/AiFillWarning';

export default function WarningBanner(props) {
  const {
    message,
    link,
    linkTitle,
    isExternalLink,
    action,
    actionTitle,
    icon,
  } = props;
  return (
    <div className="alert alert-warning" role="alert">
      <FlexContainer>
        {icon && (
          <AiFillWarning
            size={24}
            style={{ margin: 0, padding: 0, color: '#000' }}
          />
        )}
        <div>
          <span style={{ color: 'black' }}>{message}</span>{' '}
          {link && (
            <>
              <a
                href={link}
                rel="noreferrer"
                target="_blank"
                style={{ color: 'black' }}
              >
                {linkTitle}
              </a>{' '}
              {isExternalLink && (
                <FaExternalLinkAlt style={{ fontSize: '12px' }} />
              )}
            </>
          )}
        </div>
        {action && (
          <button className="btn btn-sm" type="button" onClick={action}>
            {actionTitle}
          </button>
        )}
      </FlexContainer>
    </div>
  );
}

WarningBanner.propTypes = {
  message: PropTypes.node.isRequired,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  isExternalLink: PropTypes.bool.isRequired,
  action: PropTypes.func,
  actionTitle: PropTypes.string,
  icon: PropTypes.bool,
};

WarningBanner.defaultProps = {
  link: undefined,
  linkTitle: t`Learn more. `,
  action: undefined,
  actionTitle: undefined,
  icon: false,
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1ch;
`;
