import React, { Component } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { withRouter } from 'react-router-dom';

import { AdminCompanySettingsTable } from 'components';

import UserPreviewModal from 'containers/UserPreviewModal';
import CompanyPreviewModal from 'containers/CompanyPreviewModal';
import CMAUserAddEditModal from 'containers/CMAUserAddEditModal';
import AddEditGroupUserModal from 'containers/AddEditGroupUserModal';
import AdminCompanyAddEditModal from 'containers/AdminCompanyAddEditModal';
import AddEditGroupCompanyModal from 'containers/AddEditGroupCompanyModal';
import AdminCompanyAddNXTModal from 'containers/AdminCompanyAddNXTModal';

import { trackEvent } from 'components/Analytics';
import { injectCompanyDataToURL } from 'services/helpers';
import { toAdminCompanySettings } from 'services/routehelpers';
import { resourceListReadSuccess } from 'store/resource/actions';
import { fromCompany, fromResource, fromAuth } from 'store/selectors';
import {
  resourceCreateRequest,
  resourceUpdateRequest,
  resourceDeleteRequest,
  companyInfoSuccess,
  resourceListReadRequest,
} from 'store/actions';
import {
  secretCompanyApi,
  changeCompanyApi,
  getCompanyByIdApi,
  getXeroLoginUrlApi,
  checkCMAUserStatusApi,
  updateCompaniesStatusApi,
  getEaccountingLoginUrlApi,
  getCompanyByOrganizationApi,
  getOrganizationAltinnAvailabilityApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function getRowColor(row) {
  let bgColorClass = '';

  if (row.unavailable) {
    bgColorClass = 'darkgray';
  }

  return bgColorClass;
}
class AdminCompanySettingsTableContainer extends Component {
  state = {
    modal: '',
    modalItem: {},
    actionSubmitting: {},
  };

  async componentDidMount() {
    await this.fetchData();
    this.checkOpenEdit();
    this.checkData();
  }

  componentDidUpdate(prevProps) {
    const { location, company } = this.props;
    const { companyIdToEdit } = queryString.parse(location.search, {
      parseNumbers: true,
    });
    const { companyIdToEdit: prevCompanyIdToEdit } = queryString.parse(
      prevProps.location.search,
      {
        parseNumbers: true,
      },
    );

    if (
      prevProps.company.organizationId !== company.organizationId ||
      prevProps.company.currentCompanySID !== company.currentCompanySID
    ) {
      this.fetchData();
    }

    if (companyIdToEdit && companyIdToEdit !== prevCompanyIdToEdit) {
      this.checkOpenEdit();
    }

    return true;
  }

  checkOpenEdit = async () => {
    const { location, history, data, getCompanyById } = this.props;
    const { companyIdToEdit } = queryString.parse(location.search, {
      parseNumbers: true,
    });

    if (companyIdToEdit) {
      let companyToEdit = data?.find(
        (item) => item.companyId === companyIdToEdit,
      );

      if (!companyToEdit) {
        companyToEdit = await getCompanyById({ id: companyIdToEdit });
      }

      if (companyToEdit) {
        this.toggleModal('addCompany', companyToEdit);
        history.replace(toAdminCompanySettings(this.getSearch()));
      }
    }
  };

  getSearch = (props = this.props) => {
    const search = queryString.parse(props.location.search, {
      parseBooleans: true,
    });

    return {
      reportMode: search.reportMode || false,
    };
  };

  fetchData = async (props) => {
    const { getCompanyByOrg, company } = props || this.props;

    try {
      await getCompanyByOrg({ organizationId: company.organizationId });
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  checkData = async () => {
    const {
      checkCMAUserStatus,
      altinnOrganisationAvailability,
      match: { params },
    } = this.props;

    await checkCMAUserStatus();

    await altinnOrganisationAvailability({
      companyId: params.companyId,
    });
  };

  handleActivationChange = async (type, selectedRows) => {
    const activate = type === 'activate';
    const { data, updateCompaniesStatus, updateReduxResourceResponse } =
      this.props;

    await updateCompaniesStatus({
      activate,
      companyIds: selectedRows.map((c) => c.companyId),
    });

    const collection = data?.map((_item) => {
      const el = { ..._item };
      const row = selectedRows.find((r) => r.companyId === _item.companyId);

      if (row) {
        // eslint-disable-next-line no-param-reassign
        el.recordStatus = activate;
      }

      return el;
    });

    updateReduxResourceResponse(getCompanyByOrganizationApi, collection);
  };

  toggleModal = (name, modalItem = {}) => {
    this.setState((prevState) => ({
      modal: prevState.modal === name ? '' : name,
      modalItem: prevState.modal === name ? {} : modalItem,
    }));
  };

  handleEaccountingLogin = async () => {
    const { company, getEaccountingLoginUrl } = this.props;

    const redirectUrl = await getEaccountingLoginUrl({
      organizationId: company.organizationId,
    });

    if (redirectUrl?.url) {
      window.location.assign(redirectUrl.url);
    }
  };

  handleXeroLogin = async () => {
    const { company, getXeroLoginUrl } = this.props;

    const redirectUrl = await getXeroLoginUrl({
      organizationId: company.organizationId,
    });

    if (redirectUrl?.url) {
      window.location.assign(redirectUrl.url);
    }
  };

  changeCompany = async (company) => {
    const { location, updateVisitedCompanies } = this.props;
    const { uuid, currentAccountingYear, currentWorkingPeriodEnd } = company;

    try {
      // Update visited companies
      await updateVisitedCompanies({ companyUuid: uuid });
    } catch (e) {
      toast.error(getErrorMessage(e));
    }

    trackEvent({
      value: uuid,
      label: 'Company context',
      action: 'User Switched company',
      category: 'Company',
    });

    window.location.pathname = injectCompanyDataToURL(location, {
      id: uuid,
      accountingYear: currentAccountingYear,
      currentWorkingPeriodEnd,
    }).pathname;
  };

  changeSecretStatus = async (company) => {
    const {
      data,
      setSecretCompany,
      deleteSecretCompany,
      updateReduxResourceResponse,
    } = this.props;

    const { companyId, secret } = company;

    this.setActionSubmitting(companyId, true);

    try {
      if (secret) {
        await deleteSecretCompany(companyId);
      } else {
        await setSecretCompany({ id: companyId });
      }

      const newData = data.map((item) =>
        item.companyId === companyId ? { ...item, secret: !secret } : item,
      );
      await updateReduxResourceResponse(getCompanyByOrganizationApi, newData);

      toast.success(t`Secret field updated successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    } finally {
      this.setActionSubmitting(companyId, false);
    }
  };

  setActionSubmitting = (action, submitting) => {
    this.setState((state) => ({
      actionSubmitting: { ...state.actionSubmitting, [action]: submitting },
    }));
  };

  handleAction = async (action, props, table) => {
    try {
      this.setActionSubmitting(action, true);

      switch (action) {
        case 'activate':
        case 'deactivate': {
          await this.handleActivationChange(action, props);
          break;
        }

        case 'companyPreview': {
          this.toggleModal('companyPreview', props);
          break;
        }

        case 'addCompany': {
          this.toggleModal('addCompany', null);
          break;
        }

        case 'addVismaBusinessNXTCompany': {
          this.toggleModal('addVismaBusinessNXTCompany', null);
          break;
        }

        case 'addEAccountingCompany': {
          await this.handleEaccountingLogin();
          break;
        }

        case 'addXeroCompany': {
          await this.handleXeroLogin();
          break;
        }

        case 'addConnectMyAppsUser': {
          this.toggleModal('addCMAUser');
          break;
        }

        case 'edit': {
          this.toggleModal('addCompany', props);
          break;
        }

        case 'changeCompany': {
          this.changeCompany(props);
          break;
        }

        case 'cellClick': {
          if (table === 'secret') {
            await this.changeSecretStatus(props);
          }
          break;
        }

        case 'addCompanyGroup': {
          this.toggleModal('addCompanyGroup', props);
          break;
        }

        case 'rowClick': {
          switch (table) {
            case 'companyPreview': {
              this.toggleModal('companyPreview', props);
              break;
            }
            case 'editCompanyGroup': {
              this.toggleModal('editCompanyGroup', props);
              break;
            }
            case 'userPreview': {
              this.toggleModal('userPreview', props);
              break;
            }
            case 'editUserGroup': {
              this.toggleModal('editUserGroup', props);
              break;
            }
            default: {
              break;
            }
          }
          break;
        }

        default: {
          break;
        }
      }

      this.setActionSubmitting(action, false);
    } catch (e) {
      this.setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  closeModal = () => {
    this.setState({
      modal: '',
      modalItem: {},
    });
  };

  closeAddEditModal = (_, refetch) => {
    this.closeModal();

    if (refetch) {
      this.fetchData();
    }
  };

  closeCMAModal = (_, refetch) => {
    this.closeModal();

    if (refetch) {
      this.props.checkCMAUserStatus();
    }
  };

  renderModal = () => {
    let modalToRender = '';
    const { roleType, match, cmaUserStatus, organizationAltinnAvailability } =
      this.props;

    switch (this.state.modal) {
      case 'addCompany': {
        modalToRender = (
          <AdminCompanyAddEditModal
            match={match}
            roleType={roleType}
            payload={this.state.modalItem}
            refetchData={this.fetchData}
            closeModal={this.closeAddEditModal}
            toggleModal={this.toggleModal}
            modalAction={!!this.state.modalItem}
            cmaUserStatus={cmaUserStatus}
            organizationAltinnAvailability={organizationAltinnAvailability}
          />
        );
        break;
      }
      case 'addVismaBusinessNXTCompany': {
        modalToRender = (
          <AdminCompanyAddNXTModal
            match={match}
            roleType={roleType}
            payload={this.state.modalItem}
            refetchData={this.fetchData}
            closeModal={this.closeAddEditModal}
            toggleModal={this.toggleModal}
          />
        );
        break;
      }
      case 'addCMAUser': {
        modalToRender = <CMAUserAddEditModal closeModal={this.closeCMAModal} />;
        break;
      }
      case 'companyPreview': {
        modalToRender = (
          <CompanyPreviewModal
            id={this.state.modalItem.companyId}
            closeModal={this.closeModal}
            handleAction={this.handleAction}
          />
        );
        break;
      }
      case 'userPreview': {
        modalToRender = (
          <UserPreviewModal
            id={this.state.modalItem.uuid}
            closeModal={this.closeModal}
            handleAction={this.handleAction}
          />
        );
        break;
      }
      case 'editUserGroup': {
        modalToRender = (
          <AddEditGroupUserModal
            item={this.state.modalItem}
            closeModal={this.closeModal}
          />
        );
        break;
      }
      case 'editCompanyGroup': {
        modalToRender = (
          <AddEditGroupCompanyModal
            item={this.state.modalItem}
            closeModal={this.closeModal}
          />
        );
        break;
      }
      case 'addCompanyGroup': {
        modalToRender = (
          <AddEditGroupCompanyModal
            create={this.state.modalItem}
            roleType={roleType}
            closeModal={this.closeModal}
          />
        );
        break;
      }
      default: {
        break;
      }
    }

    return modalToRender;
  };

  render() {
    const { actionSubmitting } = this.state;
    const { data, loading, roleType, cmaUserStatus } = this.props;
    const search = this.getSearch(this.props);

    return (
      <>
        {this.renderModal()}
        <AdminCompanySettingsTable
          {...{
            data,
            search,
            loading,
            roleType,
            cmaUserStatus,
            actionSubmitting,
            title: t`Admin Companies`,
            getRowColor,
            handleAction: this.handleAction,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const organizationAltinnAvailability =
    fromResource.getList(state, getOrganizationAltinnAvailabilityApi) || {};

  return {
    user: fromAuth.getUser(state),
    data: fromResource.getList(state, getCompanyByOrganizationApi),
    loading: pending(state, `${getCompanyByOrganizationApi}ListRead`),
    company: fromCompany.getCompany(state),
    cmaUserStatus: fromResource.getList(state, checkCMAUserStatusApi).status,
    eAccountingUrlLoading: pending(
      state,
      `${getEaccountingLoginUrlApi}ListRead`,
    ),
    organizationAltinnAvailability:
      organizationAltinnAvailability.isAltinnDataExist || false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateVisitedCompanies: (query) =>
    dispatch(resourceCreateRequest(changeCompanyApi, query)),
  changeCompanyInStorage: (company) => dispatch(companyInfoSuccess(company)),
  getCompanyByOrg: (query) =>
    dispatch(resourceListReadRequest(getCompanyByOrganizationApi, query)),
  getCompanyById: (query) =>
    dispatch(resourceListReadRequest(getCompanyByIdApi, query)),
  altinnOrganisationAvailability: (query) =>
    dispatch(
      resourceListReadRequest(getOrganizationAltinnAvailabilityApi, query),
    ),
  updateCompaniesStatus: (query) =>
    dispatch(resourceUpdateRequest(updateCompaniesStatusApi, null, query)),
  setSecretCompany: (query) =>
    dispatch(resourceCreateRequest(secretCompanyApi, query)),
  deleteSecretCompany: (id) =>
    dispatch(
      resourceDeleteRequest(
        `${secretCompanyApi}?${queryString.stringify({
          id,
        })}`,
      ),
    ),
  getEaccountingLoginUrl: (query) =>
    dispatch(resourceListReadRequest(getEaccountingLoginUrlApi, query)),
  getXeroLoginUrl: (query) =>
    dispatch(resourceListReadRequest(getXeroLoginUrlApi, query)),
  checkCMAUserStatus: (query) =>
    dispatch(resourceListReadRequest(checkCMAUserStatusApi, query)),
  updateReduxResourceResponse: (resource, payload) =>
    dispatch(resourceListReadSuccess(resource, payload)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminCompanySettingsTableContainer),
);
