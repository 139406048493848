import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import { IoMdSettings } from '@react-icons/all-files/io/IoMdSettings';
import { MdOutlineSort } from '@react-icons/all-files/md/MdOutlineSort';
import { BiInfoCircle } from '@react-icons/all-files/bi/BiInfoCircle';
import { OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  Card,
  Label,
  Field,
  Button,
  Spinner,
  ButtonIcon,
  PeriodFilterButtons,
  CustomTableWithAutoSettings,
  Heading,
  WarningBanner,
  InfoBanner,
} from 'components';

import { VATReportNewerColumns } from 'services/tableshapes';
import { vatReportNewerFilters } from 'services/tableFilterShapes';
import { getVATReportType } from 'services/helpers/vatReportType';

import useLocalSettings from 'hooks/useLocalSettings';

const periodTypes = {
  BIMONTHLY: 'biMonthly',
  MONTHLY: 'monthly',
};

const values = {
  BIMONTHLY: t`Bi-Monthly`,
  YEARLY: t`Yearly`,
  MONTHLY: t`Monthly`,
};

function VATReportNewer(props) {
  const {
    year,
    loading,
    roleType,
    disabled,
    tableData,
    allTablesData,
    isApproved,
    frequency,
    frequencies,
    approverInfo,
    pdfGenerating,
    activePeriods,
    // accumulated,
    handleAction,
    unmappedLines,
    actionSubmitting,
    handlePeriodChange,
    handleFrequencyChange,
    // handleAccumulatedChange,
    autoReconcileLoading,
  } = props;

  const { showHelp } = useLocalSettings();

  const buttonText = useMemo(
    () => (isApproved ? t`Unapprove` : t`Approve`),
    [isApproved],
  );

  const { isReportAvailable } = tableData;

  const sortBy = useMemo(
    () => [
      {
        id: 'vatCodeSaft',
        desc: false,
      },
    ],
    [],
  );

  // eslint-disable-next-line no-nested-ternary
  const buttonKind = loading ? '' : isApproved ? 'danger' : 'success';

  return (
    <Card
      id="pdfToPrint"
      data-testid="pdfToPrint-vatReportNewTable"
      header={<CardPDFTitle title={<Trans>VAT Reports, {year}</Trans>} />}
    >
      {showHelp && (
        <InfoBanner
          message={t`Numbers not matching your accounting software?`}
          link="https://help.accountflow.com/nb/articles/10532178-jeg-far-avvik-mellom-mva-melding-og-hovedbok"
          // eslint-disable-next-line max-len
          linkTitle={t`Check this page for troubleshooting steps (in Norwegian)`}
          isExternalLink
        />
      )}
      <ButtonContainer>
        <CenterColumn>
          <ButtonGroup>
            <Button
              size="sm"
              kind="default"
              onClick={() => handleAction('generatePDF', true)}
              disabled={pdfGenerating || disabled}
              magnify
            >
              {pdfGenerating ? (
                <Spinner size="md" />
              ) : (
                <>
                  <ButtonIcon
                    as={FaFilePdf}
                    marginRight={5}
                    fontSize={12}
                    marginBottom={-2}
                  />
                  {t`Generate PDF Report`}
                </>
              )}
            </Button>
            {!roleType.isReadOnly && (
              <Button
                size="sm"
                kind="info"
                onClick={() => handleAction('authenticate', true)}
              >
                {t`Fetch VAT returns`}
              </Button>
            )}
            <Button
              size="sm"
              kind="default"
              onClick={() => handleAction('showUnmappedLines', true)}
              disabled={loading || !unmappedLines?.length}
              magnify
            >
              <ButtonIcon
                as={MdOutlineSort}
                marginRight={5}
                fontSize={12}
                marginBottom={-2}
              />
              {t`Unmapped Lines`}
            </Button>
            <Button
              size="sm"
              kind="default"
              onClick={() => handleAction('goToSettings', true)}
              magnify
            >
              <ButtonIcon
                as={IoMdSettings}
                marginRight={5}
                fontSize={12}
                marginBottom={-2}
              />
              {t`Settings`}
            </Button>
          </ButtonGroup>
        </CenterColumn>
        <RightColumn>
          {isReportAvailable && approverInfo && !loading && (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip">{approverInfo}</Tooltip>}
              placement="bottom"
            >
              <InfoIcon />
            </OverlayTrigger>
          )}
          {!loading && (
            <>
              <Button
                size="sm"
                disabled={
                  roleType.isReadOnly || autoReconcileLoading || isApproved
                }
                kind="info"
                onClick={() => handleAction('autoReconcile', false)}
              >
                {autoReconcileLoading ? (
                  <Spinner btn />
                ) : (
                  <Trans>Undo reconciliation</Trans>
                )}
              </Button>
              <Button
                fill
                size="sm"
                disabled={
                  roleType.isReadOnly || autoReconcileLoading || isApproved
                }
                kind="info"
                onClick={() => handleAction('autoReconcile', true)}
              >
                {autoReconcileLoading ? (
                  <Spinner type="white" btn />
                ) : (
                  <Trans>Auto-Reconcile</Trans>
                )}
              </Button>
            </>
          )}
          {isReportAvailable && (
            <Button
              fill
              size="sm"
              disabled={roleType.isReadOnly || loading}
              kind={buttonKind}
              onClick={() => handleAction('approve', !isApproved)}
            >
              {loading ? <Spinner type="white" btn /> : buttonText}
            </Button>
          )}
          {frequency !== 'YEARLY' && !!frequency && (
            <PeriodFilterButtons
              size="small"
              singlePeriod
              periodType={periodTypes[frequency]}
              activePeriods={Array.from(Array(+activePeriods).keys())}
              handleFilterChange={handlePeriodChange}
            />
          )}
          {frequencies?.length > 1 && (
            <InfoBlock>
              <Title>
                <Trans>Frequency:</Trans>
              </Title>
              <Field
                name="selectedReport"
                type="select"
                defaultValue={frequency}
                onChange={handleFrequencyChange}
              >
                {frequencies.map((item) => (
                  <option key={`frequency-${item}`} value={item}>
                    {values[item] || item}
                  </option>
                ))}
              </Field>
            </InfoBlock>
          )}
        </RightColumn>
      </ButtonContainer>
      {(!loading && !Array.isArray(allTablesData)) ||
        (!loading && !allTablesData?.length && (
          <div style={{ margin: '2rem 0' }}>
            <WarningBanner
              // eslint-disable-next-line max-len
              message={t`No data to display for ${year}. Try to fetch VAT returns, and try again.`}
              action={() => handleAction('authenticate', true)}
              actionTitle={t`Fetch VAT returns`}
              icon
            />
          </div>
        ))}
      {allTablesData?.map((item) => (
        <>
          <Heading
            level={3}
            className="text-center mb-4"
            key={`heading-${item.reportType}`}
          >
            {getVATReportType(item.reportType)}
          </Heading>
          <MetaData key={`${item.reportType}-${frequency}`}>
            {!!item.tableData.metaData?.length && (
              <Items>
                {item.tableData.metaData.map((metaItem, i) => (
                  <InfoBlock key={`item-${i}-${metaItem._value}`}>
                    <Title>{metaItem.title}</Title>
                    <Value>{metaItem._value}</Value>
                  </InfoBlock>
                ))}
              </Items>
            )}
          </MetaData>
          <CustomTableWithAutoSettings
            key={item.reportType}
            data={item.tableData.rows}
            tableId="vatReportNewerReport"
            {...{
              loading,
              handleAction,
              actionSubmitting,
              columns: VATReportNewerColumns(),
              loadingId: 'vatCodeSaft',
              enableGroups: true,
              filterConfigs: vatReportNewerFilters(),
              initialState: { sortBy },
              headerConfigs: {
                exportFileName: `${t`VAT Report`} - ${year}`,
              },
            }}
          />
          <div
            style={{ height: 'auto', paddingBottom: '4rem' }}
            key={`divider-${item.reportType}`}
          />
        </>
      ))}
    </Card>
  );
}

export default VATReportNewer;

const InfoIcon = styled(BiInfoCircle)`
  font-size: 20px;
  margin-left: -5px;
  color: gray;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 15px;
  border-radius: 15px;
  background-color: #f5f5f5;
  margin-right: 8px;

  select {
    height: 22px;
    padding: 1px 6px;
    font-size: 12px;
  }
`;

const Title = styled((props) => <Label {...props} />)`
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 0;
`;

const Value = styled.div`
  font-size: 12px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const MetaData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-group {
    display: flex;
    align-items: center;
  }

  label {
    width: fit-content;
    min-width: fit-content;
    font-size: 12px;
    margin-bottom: 0;
  }

  select {
    background: white;
    margin-top: 2px;
  }
`;

const Items = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  position: relative;
  min-height: 40px;
  flex-direction: row;

  @media (max-width: 2000px) {
    justify-content: flex-end;
  }
`;

const CenterColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;

  .btn-group {
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }

  @media (max-width: 2000px) {
    width: auto;
    justify-content: flex-end;
    order: 1;
  }
`;

const RightColumn = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  min-width: fit-content;

  @media (max-width: 2000px) {
    position: static;
    transform: none;
    order: 2;
  }
`;
