import merge from 'lodash-es/merge';
import { User } from 'oidc-client-ts';
import { i18n } from '../utils/i18n/index';
import { config } from '../../config';

export function getUser() {
  const { client_id: clientId, authority } = config?.oidc || {};
  const oidcStorage = localStorage.getItem(
    `oidc.user:${authority}:${clientId}`,
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export const getTokens = () => {
  const user = getUser();
  return user?.access_token;
};

export const checkStatus = (response) => {
  if (response.ok) {
    return response;
  }
  const error = new Error(`${response.status} ${response.statusText}`);
  error.response = response;
  throw error;
};

export const parseJSON = async (response) => {
  const resData = await response.text();
  const responseStatus = await response.status;

  let data = resData ? JSON.parse(resData) : {};

  // set status for boolean or responses with no data && success status code
  if (
    typeof data === 'boolean' ||
    (!resData && responseStatus.toString()[0] === '2')
  ) {
    data = {
      // set or boolean or true in case of no response.
      status: typeof data === 'boolean' ? data : true,
    };
  }

  return data;
};

export const parseSettings = (props = {}) => {
  const { method = 'get', data, locale, ...otherSettings } = props;
  const accessToken = getTokens();

  let headers = {
    Accept: 'application/json',
    'Accept-Language': locale || i18n.locale || 'en',
  };

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  let formData = data;

  if (Object.prototype.toString.call(data) !== '[object FormData]') {
    headers['Content-Type'] = 'application/json';
    formData = data ? JSON.stringify(data) : undefined;
  }

  if (otherSettings.headers) {
    headers = {
      ...headers,
      ...otherSettings.headers,
    };
  }

  return merge(
    {
      body: formData,
      method,
      headers,
      credentials: 'omit',
    },
    otherSettings,
  );
};

export const parseEndpoint = (endpoint, params) => {
  const url = new URL(endpoint, window.location.origin);
  if (params) {
    Object.keys(params).forEach((key) => {
      if (typeof params[key] === 'undefined') return;
      url.searchParams.append(key, params[key]);
    });
  }
  return url;
};

export const api = {};

api.request = (endpoint, { params, ...settings } = {}) =>
  fetch(parseEndpoint(endpoint, params), parseSettings(settings))
    .then(checkStatus)
    .then(parseJSON);
['delete', 'get'].forEach((method) => {
  api[method] = (endpoint, settings) =>
    api.request(endpoint, { method, ...settings });
});
['post', 'put', 'patch'].forEach((method) => {
  api[method] = (endpoint, data, settings) =>
    api.request(endpoint, { method, data, ...settings });
});

/* eslint-disable no-shadow, no-param-reassign */
api.create = (settings = {}) => ({
  settings,

  request(endpoint, settings) {
    return api.request(endpoint, merge({}, this.settings, settings));
  },

  post(endpoint, data, settings) {
    return this.request(endpoint, { method: 'post', data, ...settings });
  },

  get(endpoint, settings) {
    return this.request(endpoint, { method: 'get', ...settings });
  },

  put(endpoint, data, settings) {
    return this.request(endpoint, { method: 'put', data, ...settings });
  },

  patch(endpoint, data, settings) {
    return this.request(endpoint, { method: 'patch', data, ...settings });
  },

  delete(endpoint, data, settings) {
    return this.request(endpoint, { method: 'delete', data, ...settings });
  },
});

export default api;
