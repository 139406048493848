import React from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal, Row } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { payrollWTDrilldownGLColumns } from 'services/tableshapes';
import { DESKTOP, TABLET } from 'constants/mediaSizes';
import { formatNumber } from 'services/helpers';

function PayrollWTDrilldownModal(props) {
  const { row, toggleModal, loading, handleAction } = props;

  const title = row.drilldown ? t`Payroll Drilldown` : t`Payroll Withheld Tax`;

  let subTitle = t`GL Base`;

  switch (row.type) {
    case 'bankAccount':
      subTitle = t`Deposits in Withheld Tax Account`;
      break;
    case 'bankGuarantee':
      subTitle = t`Bank Guarantee`;
      break;
    case 'withheldTax':
      subTitle = t`Account ${row.accountNumber} – ${row.accountDescription}`;
      break;
    case 'garnishment':
      subTitle = t`Account ${row.accountNumber} – ${row.accountDescription}`;
      break;
    case 'difference':
      subTitle = t`Difference`;
      break;
    default:
      break;
  }

  if (!row.drilldown) {
    return null;
  }

  let predefinedTotal = { amount: row.drilldown?.sum };

  let data = [];

  switch (row.type) {
    case 'bankAccount':
    case 'bankGuarantee':
    case 'withheldTax':
    case 'garnishment':
      data = row.drilldown;
      predefinedTotal = { amount: row.value };
      break;
    default:
      data = row.drilldown?.generalLedgerDrilldownLines;
      predefinedTotal = { amount: row.drilldown?.sum };
      break;
  }

  const content = (
    <>
      <GlobalContainer>
        <TableContainer width={100}>
          <Heading alignment="center" level={5}>
            {subTitle}
          </Heading>
          <CustomTableWithAutoSettings
            {...{
              loading,
              isModal: true,
              data,
              columns: payrollWTDrilldownGLColumns(row.type),
              tableId: 'payrollWTDrillDownGl',
              reportMode: false,
              isRowDisabled: (_row) => _row.excluded,
              hideFilters: true,
              headerConfigs: {
                exportFileName: t`GL Base`,
              },
              predefinedTotal,
              handleAction,
            }}
          />
        </TableContainer>
      </GlobalContainer>
      {Number.isFinite(row.difference) ? (
        <Row>
          <Heading alignment="right" level={6} style={{ marginRight: '15px' }}>
            <Trans>Deviation</Trans>{' '}
            {formatNumber(row.difference, true).formatted}
          </Heading>
        </Row>
      ) : null}
    </>
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={toggleModal}>
      <Modal.Header>
        <Heading level={3}>{title}</Heading>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button fill magnify kind="danger" onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PayrollWTDrilldownModal;

const GlobalContainer = styled.div`
  width: 100%;
  padding: 0 0 10px;
  gap: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TableContainer = styled.div`
  width: ${({ width }) => `${width}%`};
`;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 1200px;
    }

    @media (min-width: ${TABLET.max}px) and (max-width: ${DESKTOP.min}px) {
      width: 80vw;
    }
  }
`;
