import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Lottie from 'react-lottie';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import CountUp from 'react-countup';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap';
import { BsChevronDown } from '@react-icons/all-files/bs/BsChevronDown';
import { BsChevronLeft } from '@react-icons/all-files/bs/BsChevronLeft';
import { BsFillFilePdfFill } from '@react-icons/all-files/bs/BsFillFilePdfFill';
import { BsFillFileEarmarkExcelFill } from '@react-icons/all-files/bs/BsFillFileEarmarkExcelFill'; // eslint-disable-line max-len
import { FaRegFilePdf } from '@react-icons/all-files/fa/FaRegFilePdf';
import { IoEllipsisHorizontalSharp } from '@react-icons/all-files/io5/IoEllipsisHorizontalSharp'; // eslint-disable-line max-len
import Popup from 'reactjs-popup';
import {
  Button,
  Tooltip,
  Spinner,
  CustomScrollbar,
  CustomTableEditableCell,
} from 'components';

import { LARGE_SCREENS } from 'constants/mediaSizes';
import { MAX_HEIGHT_FOR_MODAL, LOTTIE_SIZES } from 'constants/table';
import {
  formatDate,
  formatNumber,
  searchSelection,
  getDateWithoutTimezoneOffset,
} from 'services/helpers';

import crossAnimation from 'assets/animations/cross.json';
import successAnimation from 'assets/animations/success_check.json';
import controlledAnimation from 'assets/animations/controlled.json';
import notFoundVATReport from 'assets/animations/not_found.json';
import partiallyControlled from 'assets/animations/partially.json';

const IndeterminateCheckbox = React.forwardRef((props, ref) => {
  const {
    row,
    page,
    index,
    tableId,
    onChange,
    handleAction,
    indeterminate,
    selectCondition,
    setSelectedRowIds,
    ...rest
  } = props;
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleClick = (e) => {
    e.stopPropagation();

    if (e.shiftKey) {
      const firstSelected = page.findIndex((item) => item.isSelected);

      if (firstSelected !== -1) {
        const itemsToSelect = [];
        const idsToChange = {};
        const selected = !page[index].isSelected;

        if (firstSelected < index) {
          // eslint-disable-next-line no-plusplus
          for (let i = firstSelected; i <= index; i++) {
            if (
              typeof selectCondition !== 'function' ||
              selectCondition(page[i].original)
            ) {
              idsToChange[page[i].id] = selected;
              itemsToSelect.push(page[i].original);
            }
          }
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = index; i <= firstSelected; i++) {
            if (
              typeof selectCondition !== 'function' ||
              selectCondition(page[i].original)
            ) {
              idsToChange[page[i].id] = selected;
              itemsToSelect.push(page[i].original);
            }
          }
        }

        if (itemsToSelect.length) {
          setSelectedRowIds((state) => {
            const ids = { ...state };

            Object.keys(idsToChange).forEach((key) => {
              if (idsToChange[key]) {
                ids[key] = true;
              } else {
                delete ids[key];
              }
            });

            setTimeout(() => {
              handleAction('rowSelect', {
                tableId,
                selected,
                item: Object.keys(ids).map(
                  (id) => page.find((p) => p.id === id)?.original,
                ),
              });
            }, 0);

            return ids;
          });
        }
      }
    }
  };
  const handleChange = (e) => {
    let selectAllowed = true;

    if (typeof selectCondition === 'function' && e.target.checked) {
      selectAllowed = selectCondition(row.original);
    }

    if (selectAllowed) {
      if (page[index].isSelected) {
        setSelectedRowIds((state) => {
          const ids = { ...state };
          delete ids[page[index].id];

          return ids;
        });
      } else {
        setSelectedRowIds((state) => ({ ...state, [page[index].id]: true }));
      }

      handleAction('rowSelect', {
        tableId,
        item: row.original,
        selected: e.target.checked,
      });
    }
  };

  return (
    <input
      ref={resolvedRef}
      type="checkbox"
      onClick={handleClick}
      onChange={handleChange}
      data-testid="indeterminate"
      {...rest}
    />
  );
});

IndeterminateCheckbox.propTypes = {
  row: PropTypes.shape({}).isRequired,
  page: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  tableId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  selectCondition: PropTypes.func,
};

IndeterminateCheckbox.defaultProps = {
  tableId: undefined,
  indeterminate: false,
  selectCondition: undefined,
};

let rowRefs = {};

export function CustomTableBody(props) {
  const {
    grid,
    tableId,
    isModal,
    groupId,
    minHeight,
    loadingId,
    reportMode,
    tableHeight,
    getRowColor,
    handleAction,
    withRowClick,
    enableGroups,
    withCellClick,
    selectConfigs,
    actionSubmitting,
    dynamicRowHeight,
    defaultRowHeight,
    selectedRowIds,
    setSelectedRowIds,
    calculateCellWidth,
    responsiveRowWidth,
    toggleShowWarningAlert,
    tableProps: { page, state, prepareRow, getTableBodyProps },
    isRowDisabled,
  } = props;

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: defaultRowHeight,
  });

  const { selectCondition } = selectConfigs;
  const [groups, setGroups] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expendedAccessor, setExpandedAccessor] = useState(null);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const createRef = (ref, row) => {
    rowRefs[row.original.accountId] = ref;
  };

  const getCells = ({ row, key, index }) =>
    row.cells.map((cell, columnIndex) => {
      const isDisabled = isRowDisabled ? isRowDisabled(row.original) : false;
      const cellWidth = calculateCellWidth(cell.column.width);
      const cellProps = cell.getCellProps({
        style: { minWidth: cellWidth, width: cellWidth },
      });
      let cellValue = cell.value;
      let content = '';
      let className = '';
      let withToolTip = cell.column.withTooltip;
      let onCopy = null;

      if (typeof cell.column.cell?.converter === 'function') {
        cellValue = cell.column.cell?.converter(cellValue, row.original, index);
      }

      if (cell.column.cell?.disableClick) {
        cellProps.onClick = stopPropagation;
      }

      if (
        columnIndex === 0 && // Only for first column
        enableGroups && // Only if groups enabled
        cell?.column?.cell?.type !== 'selection' && // Disabled for selection
        cell?.column?.cell?.type !== 'input' && // Disabled for inputs
        page?.[index - 1]?.values?.[cell?.column.id] === cell.value // Value should equals to previous row
      ) {
        content = '';
      } else if (
        typeof cell.column.cell?.editable === 'function'
          ? cell.column.cell?.editable(row.original)
          : cell.column.cell?.editable
      ) {
        withToolTip = false;
        content = (
          <CustomTableEditableCell
            row={row}
            cell={cell}
            grid={grid.id}
            search={state?.globalFilter?.search}
            actions={cell.column?.cell?.actions}
            tableId={tableId}
            cellWidth={cellWidth}
            handleAction={handleAction}
          />
        );
      } else if (typeof cell.column.cell?.type === 'function') {
        const dynamicType = cell.column.cell.type(row.original);

        switch (dynamicType) {
          case 'number': {
            const formattedVal = formatNumber(
              cellValue,
              cell.column.cell?.rounded,
            );

            onCopy = (event) => {
              event.preventDefault();
              event.clipboardData.setData(
                'text/plain',
                formattedVal.formatted.replace(/\s/g, '').replace('−', '-'),
              );
            };

            content = formattedVal.formatted;
            className = isDisabled ? '' : formattedVal.className;
            break;
          }
          case 'vatStatuses': {
            const { success, negative, notFound } = LOTTIE_SIZES[grid.id];
            const { status, approver, time, reportVersions } = cellValue || {};

            let size = status ? success : negative;
            let tooltip = status
              ? t`Approved by ${approver} - ${formatDate(time, true)}`
              : t`Unapproved by ${approver} - ${formatDate(time, true)}`;
            let animationData = status ? successAnimation : crossAnimation;

            if (status === null || status === undefined) {
              animationData = notFoundVATReport;
              size = notFound;
              tooltip = t`No VAT Report available`;
            }

            if (!approver && !time) {
              tooltip = null;
            }

            const handleClick = (e, action) => {
              e.stopPropagation();

              handleAction(action, row.original, cell?.column?.id);
            };

            const icon = (
              <LottieContainer
                onClick={(e) => handleClick(e, 'approveTerm')}
                className={cx({ pointer: status !== null })}
              >
                <Lottie
                  style={{ margin: 0, cursor: 'pointer' }}
                  width={size}
                  height={size}
                  options={{
                    loop: false,
                    animationData,
                    autoplay: true,
                  }}
                />
              </LottieContainer>
            );

            const currentReportVersion =
              reportVersions?.sort((a, b) => b.version - a.version)?.[0] ||
              null;

            const reportVersionsButton = (
              <Popup
                trigger={
                  <div>
                    <Button simple magnify kind="default">
                      <FaRegFilePdf />
                    </Button>
                  </div>
                }
                on={['hover', 'focus']}
                position="left center"
                closeOnDocumentClick
              >
                {!currentReportVersion?.version && (
                  <Option onClick={(e) => handleClick(e, 'generateReport')}>
                    {t`Generate PDF Report`}
                  </Option>
                )}
                {currentReportVersion?.version && (
                  <Option style={{ fontWeight: 600 }}>
                    {t`Current version`}: {currentReportVersion?.version || ''}
                  </Option>
                )}
                {currentReportVersion?.createdBy && (
                  <Option style={{ fontWeight: 600 }}>
                    {t`By`}: {currentReportVersion?.createdBy || ''}
                  </Option>
                )}
                {reportVersions?.length ? (
                  <>
                    <Option
                      onClick={(e) => handleClick(e, 'downloadLastReport')}
                    >
                      {t`Download last report`}
                    </Option>
                    <Option
                      onClick={(e) => handleClick(e, 'showReportVersions')}
                    >
                      {t`Show report versions`}
                    </Option>
                  </>
                ) : null}
              </Popup>
            );

            content = tooltip ? (
              <>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<BTooltip id="tooltip">{tooltip}</BTooltip>}
                >
                  {icon}
                </OverlayTrigger>
                {(status || reportVersions) && reportVersionsButton}
              </>
            ) : (
              <>
                {icon}
                {(status || reportVersions) && reportVersionsButton}
              </>
            );
            break;
          }
          case 'vatFiles': {
            const handleClick = (e, action) => {
              e.stopPropagation();
              handleAction(action, row.original, cell?.column?.id);
            };

            content = (
              <>
                {cellValue?.xml?.blobImage && (
                  <Button
                    simple
                    magnify
                    onClick={(e) => handleClick(e, 'downloadXML')}
                  >
                    <BsFillFileEarmarkExcelFill />
                  </Button>
                )}
                {cellValue?.pdf?.uuid && (
                  <Button
                    simple
                    magnify
                    onClick={(e) => handleClick(e, 'downloadPdf')}
                  >
                    <PDFIcon />
                  </Button>
                )}
              </>
            );
            break;
          }
          case 'string': {
            content = (
              <Tooltip
                grid={grid.id}
                width={cellWidth}
                tooltip={cellValue}
                additionalSpace={cell.column.cell?.buttonClassName && 20}
              >
                {cellValue}
              </Tooltip>
            );
            break;
          }
          default: {
            break;
          }
        }
      } else if (
        cell.column.cell?.type === 'number' ||
        (cell.column.cell?.type === 'input' &&
          cell.column.cell?.inputType === 'number')
      ) {
        const formattedVal = formatNumber(cellValue, cell.column.cell?.rounded);

        onCopy = (event) => {
          event.preventDefault();
          event.clipboardData.setData(
            'text/plain',
            formattedVal.formatted.replace(/\s/g, '').replace('−', '-'),
          );
        };

        content = formattedVal.formatted;
        className = isDisabled ? '' : formattedVal.className;

        if (
          row.original?.countUp?.enabled &&
          row.original?.countUp?.id === cell.column?.id
        ) {
          content = (
            <CountUp
              end={formattedVal.value}
              start={row.original?.countUp?.start || 0}
              decimal="."
              decimals={2}
              duration={1}
              separator=","
            />
          );
        }
      } else if (
        cell.column.cell?.type === 'date' ||
        (cell.column.cell?.type === 'input' &&
          cell.column.cell?.inputType === 'date')
      ) {
        content = formatDate(getDateWithoutTimezoneOffset(cellValue));
      } else if (cell.column.cell?.type === 'datetime') {
        const datetime =
          typeof cell.column.cell?.converter === 'function'
            ? cell.column.cell?.converter(row.original)
            : cellValue;

        content = formatDate(datetime, true);
      } else if (cell.column.cell?.type === 'boolean') {
        const { success, negative } = LOTTIE_SIZES[grid.id];

        content = (
          <Lottie
            style={{ margin: 0 }}
            width={cellValue ? success : negative}
            height={cellValue ? success : negative}
            options={{
              loop: false,
              autoplay: true,
              animationData: cellValue ? successAnimation : crossAnimation,
            }}
          />
        );
      } else if (cell.column.cell?.type === 'arrayOfBoolean') {
        content = cell.column.cell?.booleans
          ?.filter((item) =>
            item.displayIfTrue ? row?.original?.[item.id] : true,
          )
          .map((item) => {
            const { success, negative, controlled } = LOTTIE_SIZES[grid.id];

            const animationData = row?.original?.[item.id]
              ? successAnimation
              : crossAnimation;

            const controlledAnimationData = row.original?.partiallyControlled
              ? partiallyControlled
              : controlledAnimation;

            const size = row?.original?.[item.id] ? success : negative;

            content = (
              <div key={`boolean-${item?.id}`}>
                <Lottie
                  key={`boolean-${item?.id}`}
                  style={{ margin: 0 }}
                  width={item?.id === 'controlled' ? controlled : size}
                  height={item?.id === 'controlled' ? controlled : size}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData:
                      item?.id === 'controlled'
                        ? controlledAnimationData
                        : animationData,
                  }}
                />
              </div>
            );

            if (cell.column.cell?.withTooltip && row.original?.[item.tooltip]) {
              let tooltip = row.original?.[item.tooltip];
              tooltip = Array.isArray(tooltip)
                ? tooltip.map((ti, inx) =>
                    inx < tooltip.length - 1 ? (
                      <React.Fragment key={inx}>
                        {ti}
                        <br />
                        <br />
                      </React.Fragment>
                    ) : (
                      ti
                    ),
                  )
                : tooltip;

              content = (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<BTooltip id="tooltip">{tooltip}</BTooltip>}
                >
                  {content}
                </OverlayTrigger>
              );
            }

            if (typeof cell.column.cell?.hidden === 'function') {
              return !cell.column.cell?.hidden(row.original) ? content : '';
            }

            return content;
          });
      } else if (
        cell.column.cell?.type === 'actions' &&
        !row.original.subtitle
      ) {
        const visibleActions = cell.column.cell.actions.filter((action) => {
          let visible = true;

          if (typeof action.visible === 'function') {
            visible = action.visible(row.original);
          } else if (action.visible) {
            visible = row.original[action.visible];
          }

          return visible;
        });
        content = (
          <>
            {visibleActions.map((action) => {
              const loading =
                actionSubmitting[`${action.id}-${row.original[loadingId]}`];
              const handleClick = (e) => {
                e.stopPropagation();

                if (action.id === 'delete') {
                  toggleShowWarningAlert({ row: row.original, tableId });
                } else {
                  handleAction(action.id, row.original, tableId);
                }
              };

              let { tooltip, badge } = action;

              if (typeof tooltip === 'function') {
                tooltip = action.tooltip(row.original);
              }

              if (typeof badge === 'function') {
                badge = action.badge(row.original);
              }

              const iconHolder = badge ? (
                <BadgeHolder>
                  {action.icon?.()}
                  <NumberFiles>{badge}</NumberFiles>
                </BadgeHolder>
              ) : (
                action.icon?.()
              );

              let button = (
                <Button
                  simple
                  magnify
                  key={action.id}
                  kind={row.original[action.accessor] ? 'success' : 'default'}
                  onClick={handleClick}
                  id={`btn-${action.id}-${key}`}
                >
                  {loading ? <Spinner size="sm" /> : iconHolder}
                </Button>
              );

              if (tooltip && button && !loading) {
                button = (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<BTooltip id="tooltip">{tooltip}</BTooltip>}
                  >
                    {button}
                  </OverlayTrigger>
                );
              }

              if (Array.isArray(action.options) && action.options.length > 0) {
                button = (
                  <Popup
                    trigger={
                      <div>
                        <Button simple magnify kind="default">
                          {loading ? <Spinner size="sm" /> : iconHolder}
                        </Button>
                      </div>
                    }
                    on={['hover', 'focus']}
                    position="left center"
                    closeOnDocumentClick
                  >
                    {action.options.map((option) => {
                      const optionDisabled =
                        typeof option.disabled === 'function'
                          ? option.disabled(row.original)
                          : option.disabled;

                      const isVisible = option.visible?.(row.original) ?? true;

                      const textOnly = option.textOnly ?? false;

                      const onOptionClick = (e) => {
                        e.stopPropagation();

                        if (!optionDisabled) {
                          handleAction(option.id, row.original, tableId);
                        }
                      };

                      if (textOnly && isVisible)
                        return (
                          <Option key={option.id} style={{ fontWeight: 600 }}>
                            {typeof option.label === 'function'
                              ? option.label(row.original)
                              : option.label}
                          </Option>
                        );

                      return isVisible ? (
                        <Option
                          key={option.id}
                          className={cx({ disabled: optionDisabled })}
                          onClick={onOptionClick}
                        >
                          {option.icon?.()}
                          {typeof option.label === 'function'
                            ? option.label(row.original)
                            : option.label}
                        </Option>
                      ) : null;
                    })}
                  </Popup>
                );
              }

              return button;
            })}
          </>
        );

        if (visibleActions.length > 4) {
          content = (
            <ActionsTooltip>
              <Button simple magnify kind="default">
                <IoEllipsisHorizontalSharp />
              </Button>
              <TooltipContent>{content}</TooltipContent>
            </ActionsTooltip>
          );
        }

        if (row.original?.hideActions) {
          content = <Button simple magnify kind="default" />;
        }
      } else if (cell.column.cell?.type === 'selection') {
        const isSelectionDisplayed =
          typeof cell.column.cell?.isVisible === 'function'
            ? cell.column.cell?.isVisible(row.original)
            : true;

        if (isSelectionDisplayed) {
          content = (
            <IndeterminateCheckbox
              {...{
                ...row.getToggleRowSelectedProps(),
                row,
                page,
                index,
                tableId,
                handleAction,
                selectCondition,
                setSelectedRowIds,
              }}
            />
          );
        }
      } else if (cell.column.cell?.type === 'button') {
        const currentCell = cell.column.cell;

        content =
          !row.original?.hiddenButton &&
          cell.column.cell.buttons?.map((item) => {
            const handleActionClick = (e) => {
              e.stopPropagation();

              handleAction(item.id, row.original, tableId);
            };

            const title =
              typeof currentCell.titleConverter === 'function'
                ? currentCell.titleConverter(row.original)
                : item.title;

            const disabled =
              typeof item.disabled === 'function'
                ? item.disabled(row.original)
                : item.disabled;

            const visible =
              typeof item.visible === 'function'
                ? item.visible(row.original)
                : true;

            const fill =
              typeof item.fill === 'function'
                ? item.fill(row.original)
                : item.fill;

            return visible ? (
              <Button
                key={`button-${item.id}`}
                {...item}
                onClick={handleActionClick}
                className="additional-padding"
                disabled={disabled}
                fill={fill}
              >
                {title}
              </Button>
            ) : null;
          });
      } else if (cell.column.cell?.type === 'expand') {
        const handleExpandedClick = (e) => {
          e.stopPropagation();
          const newExpanded = [...expandedRows];

          if (newExpanded.includes(`row-${key}-${index}`)) {
            newExpanded.splice(newExpanded.indexOf(`row-${key}-${index}`), 1);
          } else {
            newExpanded.push(`row-${key}-${index}`);
          }

          setExpandedAccessor(cell?.column?.cell?.accessor);
          setExpandedRows(newExpanded);
        };

        content = row.original[cell?.column?.cell?.accessor] && (
          <Button simple magnify onClick={handleExpandedClick}>
            {expandedRows.includes(`row-${key}-${index}`) ? (
              <BsChevronDown className="arrow" />
            ) : (
              <BsChevronLeft className="arrow" />
            )}
          </Button>
        );
      } else if (typeof cell.column.cell?.converter === 'function') {
        content = cellValue;
      } else {
        content = cellValue?.toString() || cell.render('Cell');
      }

      if (
        cell.column.cell?.action ||
        (row.original?.action &&
          row.original?.action?.id &&
          row.original?.action?.forCell === cell.column?.id)
      ) {
        const action =
          typeof cell.column.cell?.action === 'function'
            ? cell.column.cell.action(row.original)
            : cell.column.cell?.action || row.original.action?.id;

        const handleActionClick = (e) => {
          e.stopPropagation();

          if (action === 'delete') {
            toggleShowWarningAlert({ row: row.original });
          } else {
            handleAction(action, row.original);
          }
        };

        if (typeof content === 'string' && state?.globalFilter?.search) {
          content = searchSelection(content, state?.globalFilter?.search);
        }

        const isNumberLink = cell.column.cell?.type === 'number';

        if (isNumberLink) {
          content = (
            <ActionWrapper onClick={handleActionClick}>{content}</ActionWrapper>
          );
        }

        if (!row.original.subtitle && action) {
          content = (
            <>
              {content}
              {!isNumberLink && (
                <Button
                  simple
                  magnify
                  kind="default"
                  onClick={handleActionClick}
                  className={cx('action', {
                    [cell.column.cell?.buttonClassName]:
                      cell.column.cell?.buttonClassName,
                  })}
                >
                  {cell.column.cell?.icon?.() || row.original.action?.icon?.()}
                </Button>
              )}
            </>
          );
        }
      }

      if (typeof content === 'string' && state?.globalFilter?.search) {
        content = searchSelection(content, state?.globalFilter?.search);
      }

      if (row.original.rowHeight) {
        content = <ValueHolder>{content}</ValueHolder>;
      }

      const handleCellClick = () => {
        if (withCellClick && typeof handleAction === 'function') {
          handleAction('cellClick', row.original, cell?.column?.id);
        }
      };

      if (row.original?.[cell?.column?.id]?.className) {
        className += row.original[cell?.column?.id].className;
      }

      if (cell?.column?.cell?.tooltip) {
        const customTooltip =
          typeof cell?.column?.cell?.tooltip === 'function'
            ? cell?.column?.cell?.tooltip(row.original)
            : cell?.column?.cell?.tooltip;

        if (customTooltip) {
          content = (
            <OverlayTrigger
              overlay={<BTooltip id="tooltip">{customTooltip}</BTooltip>}
              placement="bottom"
            >
              <div style={{ width: '100%' }}>{content}</div>
            </OverlayTrigger>
          );
        }
      }

      return (
        // eslint-disable-next-line react/jsx-key,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={`${key}-${columnIndex}`}
          className={cx('td', className, cell.column.className, {
            disableClick: cell.column.cell?.disableClick,
            strong: row.original.subtitle,
            clickable: withCellClick && !cell.column.cell?.disableClick,
            hasFixedIconRight: cell.column.cell?.buttonClassName,
            hideHorizontalGrid:
              enableGroups && groups?.[index] === groups?.[index + 1],
          })}
          onClick={handleCellClick}
          onCopy={onCopy}
          {...cellProps}
        >
          {withToolTip && typeof cell.value !== 'object' ? (
            <Tooltip
              grid={grid.id}
              width={cellWidth}
              tooltip={cell.value}
              additionalSpace={cell.column.cell?.buttonClassName && 20}
            >
              {content}
            </Tooltip>
          ) : (
            content
          )}
        </div>
      );
    });

  const renderExpandedRows = (row, key, index) => {
    if (!expandedRows.length) return null;
    return (
      <div>
        {row?.original?.[expendedAccessor].map((item, i) => {
          const cells = [];
          const _handleSubRowClick = () => {
            if (withRowClick && typeof handleAction === 'function') {
              handleAction('subRowClick', item);
            }
          };

          row.cells.forEach((c) => {
            const { column, getCellProps } = c;
            cells.push({
              column,
              getCellProps,
              render: () => item[column.id],
              value: item[column.id],
            });
          });

          const additionalRow = {
            cells,
            original: item,
            id: `${row.id}-${i}`,
          };

          const subRowColor = getRowColor(item);
          return (
            <RowHolder
              id={additionalRow.id}
              key={`subrow-${key}-${tableId}-${index}-${i}`}
              ref={(ref) => createRef(ref, additionalRow)}
              height={defaultRowHeight}
              onClick={_handleSubRowClick}
              className={cx('tr body-row', {
                withBG: index % 2 === 0,
                [subRowColor]: true,
              })}
            >
              {getCells({
                row: additionalRow,
                key: `${key}-${i}`,
                index: `${index}-${i}`,
              })}
            </RowHolder>
          );
        })}
      </div>
    );
  };

  const renderRow = ({ index, key, style, parent }) => {
    const row = page[index];
    prepareRow(row);
    const rowHeight = rowRefs?.[row.original.accountId]?.offsetHeight;

    const _handleRowClick = () => {
      if (
        withRowClick &&
        !withCellClick &&
        typeof handleAction === 'function'
      ) {
        handleAction('rowClick', row.original);
      }
    };

    let rowColor = getRowColor(row.original);
    let additionalRows;

    if (
      expandedRows.includes(`row-${key}-${index}`) &&
      row.original[expendedAccessor] &&
      row.original[expendedAccessor].length
    ) {
      rowColor = rowColor || 'info';
      additionalRows = renderExpandedRows(row, key, index);
    }

    if (selectedRowIds?.[row.id] && rowColor !== 'warning') {
      rowColor = 'selected';
    }

    const isDisabled = isRowDisabled ? isRowDisabled(row.original) : false;

    const rowHolder = (withSubRows) => (
      <RowHolder
        id={row.id}
        ref={(ref) => createRef(ref, row)}
        height={defaultRowHeight}
        key={`${key}-${tableId}`}
        {...row.getRowProps()}
        style={!withSubRows ? style : undefined}
        onClick={_handleRowClick}
        className={cx('tr body-row', {
          withBG: enableGroups ? groups?.[index] : index % 2 === 0,
          clickable: withRowClick,
          [rowColor]: !isDisabled && rowColor,
          'text-muted': isDisabled,
        })}
        data-testid={`${tableId}-row-${index}`}
      >
        {actionSubmitting[row.original[loadingId]] ? (
          <SkeletonTheme color="#F0EEEE" highlightColor="#d6d6d6">
            <Skeleton
              width={responsiveRowWidth}
              style={{ position: 'relative', top: '-2px' }}
              height={rowHeight ? rowHeight - 3 : 35}
            />
          </SkeletonTheme>
        ) : (
          getCells({ row, key, index })
        )}
      </RowHolder>
    );

    return dynamicRowHeight ? (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {additionalRows ? (
          <RowWrapper key={`row-wrapper-${key}-${tableId}`} style={style}>
            {rowHolder(true)}
            {additionalRows}
          </RowWrapper>
        ) : (
          <RowWrapper key={`row-wrapper-${key}-${tableId}`} style={style}>
            {rowHolder(true)}
          </RowWrapper>
        )}
      </CellMeasurer>
    ) : (
      rowHolder()
    );
  };

  useEffect(
    () => () => {
      rowRefs = {};
    },
    [],
  );

  const defineGroups = (array) => {
    const newArray = [];

    for (let i = 0; i < array.length; i += 1) {
      const item = array[i].original[groupId || loadingId];

      if (i === 0) {
        newArray.push(true);
      } else {
        // Add the negation of the previous value if the current item is different.
        newArray.push(
          item === array[i - 1].original[groupId || loadingId]
            ? newArray[i - 1]
            : !newArray[i - 1],
        );
      }
    }

    setGroups(newArray);
  };

  useEffect(() => {
    setExpandedRows(() => []);

    if (enableGroups) {
      defineGroups(page);
    }
  }, [page]);

  let height = tableHeight < minHeight ? minHeight : tableHeight;

  if (isModal && height > MAX_HEIGHT_FOR_MODAL) {
    height = MAX_HEIGHT_FOR_MODAL;
  }

  return (
    <TableBodyWrapper
      grid={grid.id}
      {...getTableBodyProps()}
      data-testid={`table-${tableId}`}
    >
      {reportMode ? (
        <>
          {page.map((item, index) =>
            renderRow({ index, key: item.id, style: {} }),
          )}
        </>
      ) : (
        <CustomScrollbar
          as={List}
          key={defaultRowHeight}
          width={responsiveRowWidth}
          height={height + 64}
          rowCount={page.length}
          rowHeight={dynamicRowHeight ? cache.rowHeight : defaultRowHeight}
          rowRenderer={renderRow}
          deferredMeasurementCache={dynamicRowHeight && cache}
        />
      )}
    </TableBodyWrapper>
  );
}

CustomTableBody.propTypes = {
  grid: PropTypes.shape({}),
  tableId: PropTypes.string,
  isModal: PropTypes.bool,
  groupId: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  rowWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  loadingId: PropTypes.string,
  reportMode: PropTypes.bool,
  tableHeight: PropTypes.number.isRequired,
  getRowColor: PropTypes.func,
  withRowClick: PropTypes.bool,
  withCellClick: PropTypes.bool,
  selectConfigs: PropTypes.shape({}),
  defaultRowHeight: PropTypes.number,
  actionSubmitting: PropTypes.shape({}),
  tableProps: PropTypes.shape({
    page: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    prepareRow: PropTypes.func.isRequired,
    getTableBodyProps: PropTypes.func.isRequired,
  }).isRequired,
};

CustomTableBody.defaultProps = {
  grid: { id: 'md' },
  tableId: undefined,
  groupId: undefined,
  isModal: false,
  loadingId: '',
  reportMode: false,
  getRowColor: () => {},
  withRowClick: false,
  withCellClick: false,
  selectConfigs: {},
  actionSubmitting: {},
  defaultRowHeight: 40,
};

export default CustomTableBody;

const TableBodyWrapper = styled.div`
  .ReactVirtualized__Grid {
    overflow-x: hidden !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  button {
    svg {
      cursor: pointer;
      font-size: 16px;

      @media (max-width: ${LARGE_SCREENS.max}px) {
        font-size: ${({ grid }) => (grid === 'sm' ? '13px' : '16px')};
      }

      &.arrow {
        font-size: 13px;
      }
    }
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowHolder = styled.div`
  display: flex;
  min-height: ${({ height }) => `${height || 40}px`};

  svg {
    cursor: default;
  }
`;

const ValueHolder = styled.div`
  white-space: pre-line;
`;

const TooltipContent = styled.div`
  color: #fff;
  width: fit-content;
  display: block;
  padding: 5px 0;
  text-align: center;
  border-radius: 6px;
  background-color: white;

  top: -5px;
  right: 125%;
  z-index: 1;
  position: absolute;

  &::after {
    top: 50%;
    left: 100%;
    content: '';
    position: absolute;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent white;
  }
`;

const ActionsTooltip = styled.div`
  display: inline-block;
  position: relative;

  &:hover ${TooltipContent} {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const NumberFiles = styled.p`
  top: -7px;
  right: -9px;
  color: white;
  padding: 1px 4px;
  position: absolute;
  font-size: 8px;
  text-align: center;
  border-radius: 50%;
  background-color: #7ac29a;
`;

const BadgeHolder = styled.div`
  position: relative;
`;

const ActionWrapper = styled.div`
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #0a64bf;
    transform: scale(1.1);
  }
`;

const LottieContainer = styled.div`
  &.pointer svg {
    cursor: pointer;
  }
`;

const PDFIcon = styled(BsFillFilePdfFill)`
  fill: #dd4040;
`;

const Option = styled.div`
  white-space: nowrap;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &.disabled {
    color: lightgray;
    cursor: default;
  }

  &:not(.disabled):hover {
    background: #efefef;
  }

  &:first-child {
    padding-top: 10px;
  }
`;
