import React from 'react';
import { t, Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import { Row, Col } from 'react-bootstrap';

import { Button, Spinner, Paragraph, ReactFormField } from 'components';

import { email, required, isMobile } from 'services/validation';
import { colorModes } from 'services/shapes';

function UserSettings(props) {
  const { failed, onSubmit, submitting, defaultValues, getLanguageList } =
    props;

  const oidcAuthority =
    process.env.OIDC_AUTHORITY ||
    'https://auth1.accountflow.com/realms/Production';

  if (failed) {
    return (
      <Paragraph>
        <Trans>Something went wrong! Please try again.</Trans>
      </Paragraph>
    );
  }

  const {
    Form,
    meta: { canSubmit, isTouched },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm({ onSubmit, defaultValues });
  const disabled = submitting || !canSubmit || !isTouched;

  let languageOptions;
  if (!isEmpty(getLanguageList)) {
    languageOptions = [
      <option key="selectOptionDefaultLanguage" value="" disabled>
        {t`Please Select Your Language`}
      </option>,
      Object.values(getLanguageList).map((item) => (
        <option
          key={`languageList-${item.languageCode}`}
          value={item.languageCode}
        >
          {item.language}
        </option>
      )),
    ];
  }
  const colorModeOptions = [
    <option key="selectOptionDefaultTheme" value="" disabled>
      {t`Please Select Your Theme`}
    </option>,
    colorModes().map((item) => (
      <option key={`selectOptionTheme-${item.id}`} value={item.id}>
        {item.label}
      </option>
    )),
  ];

  return (
    <div>
      <Form>
        <Row>
          <Col md={6}>
            <ReactFormField
              noBorder
              size="sm"
              name="name"
              label={t`Name`}
              validate={[required]}
              placeholder={t`Please Enter The Name`}
            />
            <ReactFormField
              noBorder
              disabled
              size="sm"
              name="username"
              label={t`Email`}
              validate={[required, email]}
              placeholder={t`Please Select Email`}
            />
            <ReactFormField
              noBorder
              size="sm"
              name="contactNo"
              label={t`Mobile Phone`}
              validate={[isMobile]}
              placeholder={t`Please Enter The Phone Number`}
            />
            <ReactFormField
              noBorder
              size="sm"
              name="entries"
              type="number"
              label={t`Entries`}
              validate={[required]}
              placeholder={t`Please Enter Number of Entries`}
            />
          </Col>
          <Col md={6}>
            <ReactFormField
              noBorder
              size="sm"
              type="select"
              name="languageCode"
              label={t`Language`}
            >
              {languageOptions}
            </ReactFormField>
            <ReactFormField
              noBorder
              size="sm"
              type="select"
              name="colorMode"
              label={t`Theme`}
            >
              {colorModeOptions}
            </ReactFormField>
            <ReactFormField
              noBorder
              size="small"
              type="checkbox"
              id="autoReload"
              name="autoReload"
              label={t`Refresh automatically after a balance update`}
            />
            <ReactFormField
              noBorder
              size="small"
              type="checkbox"
              id="showHelp"
              name="showHelp"
              label={t`Show Help Banners`}
              disabled
            />
            <PasswordMessageHolder>
              <Trans>
                To change your password, set your multi-factor authentication
                settings, or change your log-in settings, please visit the{' '}
                <a href={`${oidcAuthority}/account`}>Account Center</a>.
              </Trans>
            </PasswordMessageHolder>
          </Col>
        </Row>
        <div style={{ paddingLeft: '36%' }}>
          <Button fill wide type="submit" disabled={disabled}>
            {submitting ? (
              <Spinner type="white" size="md" />
            ) : (
              <Trans>Save</Trans>
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}

UserSettings.propTypes = {
  user: PropTypes.shape({}).isRequired,
  failed: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  getLanguageList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

UserSettings.defaultProps = {
  failed: false,
  submitting: false,
};

export default UserSettings;

const PasswordMessageHolder = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
`;
