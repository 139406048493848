import React from 'react';
import OpeningBalanceRegistration from 'containers/OpeningBalanceRegistration';

/**
 * Opening Balance Registration Page component
 */

function OpeningBalanceRegistrationPage(props) {
  return <OpeningBalanceRegistration {...props} />;
}

export default OpeningBalanceRegistrationPage;
