/* eslint-disable import/no-import-module-exports  */
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { Router } from 'react-router-dom';
import { InstantSearch } from 'react-instantsearch-dom';
import { HelmetProvider } from 'react-helmet-async';
import { IntercomProvider } from 'react-use-intercom';
import { AuthProvider } from 'react-oidc-context';

import I18nLoader from './services/utils/i18n';
import store, { history } from './store/configure';
import { config } from './config';

import GlobalTheme from './styles/ThemeProvider';
import ConnectedApp from './components/ConnectedApp';

import 'assets/css/bootstrap.min.css';
import 'assets/sass/paper-dashboard.scss';
import 'assets/css/fonts.css';
import 'assets/css/custom.css';
import 'react-sweet-progress/lib/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-day-picker/dist/style.css';
import 'reactjs-popup/dist/index.css';

// Only track in production environment
if (config.isProdEnv) {
  ReactGA.initialize(config.gaTrackingID, {
    debug: config.isDev,
    titleCase: false,
  });
}
// TODO: Fetch these from config instead of process.env directly
const searchClient = algoliasearch(
  process.env.ALGOLIA_APPLICATION_ID || '',
  process.env.ALGOLIA_SEARCH_CLIENT || '',
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
}

function App() {
  return (
    <Provider store={store}>
      <I18nLoader>
        <AuthProvider {...config.oidc}>
          <Router history={history} basename="/">
            <HelmetProvider>
              <InstantSearch
                indexName={process.env.BALANCES_ENV || 'local'}
                searchClient={searchClient}
              >
                <IntercomProvider appId="gldv675w">
                  <GlobalTheme>
                    <ConnectedApp />
                  </GlobalTheme>
                </IntercomProvider>
              </InstantSearch>
            </HelmetProvider>
          </Router>
        </AuthProvider>
      </I18nLoader>
    </Provider>
  );
}

ReactDOM.createRoot(document.getElementById('app')).render(<App />);
