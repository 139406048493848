import ReactGA from 'react-ga4';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import { config } from '../config';

export const trackPage = (page, _userId) => {
  if (config.isProdEnv) {
    ReactGA.set({ page, userId: _userId || 'anonymous' });
    ReactGA.send({ hitType: 'pageview', page });
  }
};

export const trackEvent = (event) => {
  if (config.isProdEnv) ReactGA.event({ ...event });
};

function Analytics() {
  const [userLocation, setLocation] = useState('');
  const location = useLocation();
  const auth = useAuth();
  useEffect(() => {
    if (location.pathname && userLocation !== location.pathname) {
      setLocation(location.pathname);
      trackPage(location.pathname, auth.user?.sub);
    }
  }, [location.pathname, auth.user?.sub]);

  return null;
}

export default Analytics;
