import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { useForm } from 'react-form';
import styled from 'styled-components/macro';
import { Trans, t } from '@lingui/macro';
import { Modal, Col, Row } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';

import {
  AutoSuggestInput,
  Button,
  Heading,
  Spinner,
  Paragraph,
  ButtonIcon,
  ReactFormField,
  WarningBanner,
} from 'components';

import { periodTypes } from 'services/shapes';
import { required } from 'services/validation';
import { sortCollection } from 'services/helpers';

const titles = () => ({
  edit: t`Edit Company`,
  add: t`Add Company`,
});

function AdminNXTCompanySettingsModal(props) {
  const {
    payload,
    loading,
    roleType,
    onSubmit,
    countries,
    companies,
    closeModal,
    modalAction,
    defaultValues,
    companyGroupsList,
    userListByCompany,
    isCompanyGroupRequired,
    isOrganizationNXTEnabled,

    // LOADINGS while data fetches
    getUsersLoading,
    getGroupsLoading,
    getCountriesLoading,
    isGroupRequiredLoading,
  } = props;
  // const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState(null);

  const fetchLoading =
    loading ||
    getUsersLoading ||
    getGroupsLoading ||
    getCountriesLoading ||
    isGroupRequiredLoading;

  const {
    Form,
    values: currentValues,
    meta: { canSubmit, isSubmitting },
  } = useForm({ onSubmit, defaultValues });

  useEffect(() => {
    if (typeof selectedCompany !== 'undefined' && selectedCompany !== null) {
      currentValues.companyname = selectedCompany.data.name;
      currentValues.registrationNumber = selectedCompany.data.companyNo;
      currentValues.vismaNetCompanyId = selectedCompany.data.vismaNetCompanyId;
    }
  }, [selectedCompany]);

  const { edit, add } = titles();

  const periodTypesOptions = useMemo(
    () =>
      periodTypes().map((item) => (
        <option key={`periodTypesOptions-${item.id}`} value={item.id}>
          {item.label}
        </option>
      )),
    [],
  );

  const countryOptions = useMemo(() => {
    const options = [
      <option key="selectOptionDefaultCountry" value="" disabled>
        {t`Please Select The Country`}
      </option>,
    ];

    if (!isEmpty(countries)) {
      options.push(
        ...Object.values(countries).map((item) => (
          <option
            key={`countryList-${item.countrySID}`}
            value={item.countryCode}
          >
            {item.countryName}
          </option>
        )),
      );
    }

    return options;
  }, [JSON.stringify(countries)]);

  const userOptions = useMemo(
    () =>
      !isEmpty(userListByCompany)
        ? sortCollection(
            userListByCompany
              .filter((item) => item.recordStatus)
              .map((item) => ({
                value: item.uuid,
                label: item.name,
              })),
            'Asc',
            'label',
          )
        : [],
    [JSON.stringify(userListByCompany)],
  );

  const companyGroupsOptions = useMemo(
    () =>
      !isEmpty(companyGroupsList)
        ? companyGroupsList.map((item) => ({
            value: item.groupId,
            label: item.name,
          }))
        : [],
    [JSON.stringify(companyGroupsList)],
  );

  const disabled = modalAction || !isOrganizationNXTEnabled;

  const helpingMessage = () => {
    if (loading || fetchLoading || isSubmitting) return;

    if (!canSubmit) {
      // eslint-disable-next-line consistent-return
      return t`All required fields must be filled`;
    }

    // eslint-disable-next-line consistent-return
    return '';
  };

  const loadingBody = (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );

  const pickCompanyBody = (
    <Modal.Body>
      {!isOrganizationNXTEnabled && (
        <WarningBanner
          // eslint-disable-next-line max-len
          message={t`The Visma Business NXT integration is not enabled for this organization. Contact us to enable it.`}
        />
      )}
      {isOrganizationNXTEnabled && companies?.length === 0 && (
        <WarningBanner
          // eslint-disable-next-line max-len
          message={t`There are no Visma Business NXT companies available to configure.
            Either all companies that could be configured are already configured
            or there is a problem with the integration. Check that you allowed
            Accountflow to access the company you are looking to configure, or
            contact us for assistance.`}
        />
      )}
      {isOrganizationNXTEnabled && companies?.length > 0 && (
        <>
          <AutoSuggestInput
            clearButton
            id="pick-company"
            bsSize="small"
            options={companies.map((c) => ({
              id: c.vismaNetCompanyId,
              label: `${c.name} - ${c.companyNo}`,
              disabled: c.alreadyActivated,
              data: c,
            }))}
            vismaNxt
            placeholder={t`Pick company from dropdown...`}
            selected={selectedCompany ? [selectedCompany] : []}
            onChange={(e) => {
              if (Array.isArray(e)) {
                setSelectedCompany(e[0]);
              } else {
                setSelectedCompany(null);
              }
            }}
          />
          {!selectedCompany && (
            <NotListed>
              <Trans>
                Is the company you are looking to add not present in the list?
                <br />
                Please check your settings in Visma Business NXT, or contact
                Accountflow.
              </Trans>
            </NotListed>
          )}
        </>
      )}
    </Modal.Body>
  );

  const body = (
    <Modal.Body>
      <Row>
        <Col md="6">
          <ReactFormField
            noBorder
            feedback
            name="companyname"
            size="small"
            label={t`Company Name`}
            validate={[required]}
            placeholder={t`Enter Company Name`}
            autoComplete="search"
            value={selectedCompany?.data.name}
          />
          <ReactFormField
            noBorder
            feedback
            name="registrationNumber"
            size="small"
            label={t`Registration Number`}
            disabled
            placeholder={t`Enter the Registration Number`}
            validate={[required]}
            value={selectedCompany?.data.companyNo}
          />
          <ReactFormField
            noBorder
            feedback
            name="periodtypes"
            size="small"
            type="select"
            label={t`Period Types`}
          >
            {periodTypesOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            feedback
            name="country"
            size="small"
            type="select"
            label={t`Country`}
          >
            {countryOptions}
          </ReactFormField>
          <ReactFormField
            noBorder
            feedback
            name="user"
            size="sm"
            type="autoSuggest"
            label={t`Assigned User`}
            options={userOptions}
            validate={[required]}
          />
          <ReactFormField
            noBorder
            multiple
            size="small"
            name="companyGroupIds"
            type="autoSuggest"
            label={t`Company Groups`}
            options={companyGroupsOptions}
            validate={isCompanyGroupRequired?.status ? [required] : undefined}
          />
          <ReactFormField
            disabled
            id="isMainCompany"
            name="isMainCompany"
            size="small"
            type="checkbox"
            label={t`Is Main Company`}
            defaultChecked={payload?.isMainCompany}
          />
          {roleType?.isAdmin && (
            <>
              <ReactFormField
                id="payrollEnabled"
                name="payrollEnabled"
                size="small"
                type="checkbox"
                label={t`Enable Payroll`}
              />
              <ReactFormField
                id="vatEnabled"
                name="vatEnabled"
                size="small"
                type="checkbox"
                label={t`Enable VAT`}
              />
              <ReactFormField
                id="assetsEnabled"
                name="assetsEnabled"
                size="small"
                type="checkbox"
                label={t`Enable Assets`}
              />
            </>
          )}
        </Col>
        <Col md={6}>
          <ReactFormField
            noBorder
            size="small"
            type="checkbox"
            name="deviatingFiscalYear"
            id="deviatingFiscalYear"
            label={t`Deviating Fiscal Year?`}
          />
          {JSON.parse(currentValues.deviatingFiscalYear) === true ? (
            <>
              <ReactFormField
                noBorder
                size="small"
                type="date"
                name="startDate"
                id="startDate"
                label={t`Fiscal Start Date`}
                placeholder={t`Enter a start date`}
              />
              <ReactFormField
                noBorder
                size="small"
                type="date"
                name="endDate"
                id="endDate"
                label={t`Fiscal End Date`}
                placeholder={t`Enter a start date`}
              />
            </>
          ) : null}
          <ReactFormField
            hidden
            type="hidden"
            id="vismaNetCompanyId"
            name="vismaNetCompanyId"
            value={selectedCompany?.data.vismaNetCompanyId}
            validate={[required]}
          />
        </Col>
      </Row>
    </Modal.Body>
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={closeModal}>
      <Form>
        <Modal.Header>
          <Heading level={3}>{modalAction ? edit : add}</Heading>
        </Modal.Header>
        {fetchLoading ? loadingBody : pickCompanyBody}
        {selectedCompany && !fetchLoading ? body : null}
        <Footer>
          {!canSubmit && (
            <HelpingMessage kind="danger">{helpingMessage()}</HelpingMessage>
          )}
          <Button
            fill
            kind="success"
            magnify
            type="submit"
            disabled={
              !isOrganizationNXTEnabled ||
              disabled ||
              loading ||
              !canSubmit ||
              isSubmitting
            }
          >
            {loading || isSubmitting ? (
              <Spinner type="white" size="md" />
            ) : (
              <>
                {modalAction ? (
                  <ButtonIcon as={HiCheck} />
                ) : (
                  <ButtonIcon as={HiPlus} />
                )}{' '}
                <Trans>Save</Trans>
              </>
            )}
          </Button>
          <Button fill magnify kind="danger" onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
}

export default AdminNXTCompanySettingsModal;

AdminNXTCompanySettingsModal.propTypes = {
  payload: PropTypes.shape({}),
  loading: PropTypes.bool,
  roleType: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeModal: PropTypes.func.isRequired,
  modalAction: PropTypes.bool.isRequired,
  defaultValues: PropTypes.shape({}).isRequired,
  companyGroupsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userListByCompany: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCompanyGroupRequired: PropTypes.bool.isRequired,

  getUsersLoading: PropTypes.bool,
  getGroupsLoading: PropTypes.bool,
  getCountriesLoading: PropTypes.bool,
  isGroupRequiredLoading: PropTypes.bool,
};

AdminNXTCompanySettingsModal.defaultProps = {
  payload: {},
  loading: undefined,
  getUsersLoading: undefined,
  getGroupsLoading: undefined,
  getCountriesLoading: undefined,
  isGroupRequiredLoading: undefined,
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const HelpingMessage = styled((props) => <Paragraph {...props} />)`
  margin: 0 20px 0 0 !important;
`;

const Footer = styled((props) => <Modal.Footer {...props} />)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NotListed = styled(Paragraph)`
  font-size: 1.475rem;
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
`;
