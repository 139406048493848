import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useAuth, hasAuthParams } from 'react-oidc-context';

import { LoadingScreen } from 'components';

import { checkIfTestStagingEnv } from 'services/helpers';
import { fromAuth, fromCompany, fromRoot } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { checkIsUserFromOrg } from 'services/apihelpers';
import { decodeUrl } from 'services/routehelpers';
import { period } from 'services/helpers/periods';
import { loginRequest } from '../store/auth/actions';

import App from './App';

function LoginAgain() {
  const auth = useAuth();
  const needsLogin = useSelector(fromRoot.needsLogin);

  useEffect(() => {
    if (needsLogin) {
      auth.signinSilent();
    }
  }, [needsLogin]);

  return null;
}

function ConnectedApp(props) {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const dispatch = useDispatch();
  const metaUserData = useSelector(fromAuth.getMetaUserData);
  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);
  const appReady = props.isAppReady;
  const { pathname } = window.location;
  const [companyUuid, setCompanyUuid] = useState();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      localStorage.setItem(
        'activeUrl',
        window.location.pathname + window.location.search,
      );
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    if (pathname.split('/')[1] === 'client') {
      setCompanyUuid(pathname.split('/')[2]);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      !company ||
      !company.currentPeriodType ||
      pathname.split('/')[1] !== 'client'
    )
      return;

    const { step } = period(company.currentPeriodType || 'monthly');
    const currentUrl = decodeUrl(pathname);
    const adjustedCurrentPeriod = Math.ceil(+currentUrl.period / step) * step;

    if (
      !Number.isNaN(adjustedCurrentPeriod) &&
      adjustedCurrentPeriod !== currentUrl.period
    ) {
      const searchParams = window.location.search;
      const oldUrl = pathname.split('/');
      const newUrl = [
        ...oldUrl.slice(0, 4),
        adjustedCurrentPeriod.toString(),
        ...oldUrl.slice(5),
      ];
      const oldPath = pathname + searchParams;
      const newPath = newUrl.join('/') + searchParams;

      if (oldPath !== newPath) dispatch(push(newPath));
    }
  }, [company, pathname, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && !auth.isLoading) {
      if (pathname.split('/')[1] === 'client' && pathname.split('/')[2]) {
        dispatch(loginRequest(decodeUrl(pathname)));
      } else {
        dispatch(loginRequest());
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, pathname, dispatch]);

  const { boot, shutdown } = useIntercom();

  const checkIfUserFromOrg = async () => {
    try {
      await dispatch(
        resourceListReadRequest(checkIsUserFromOrg, {
          companyId: companyUuid ?? undefined,
        }),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  useEffect(() => {
    if (metaUserData?.id) {
      checkIfUserFromOrg();
    }

    if (checkIfTestStagingEnv(window.location.href) || !metaUserData?.id) {
      shutdown();
    } else {
      boot({
        name: metaUserData.displayName,
        email: metaUserData.key,
        customAttributes: {
          user_id: metaUserData.id,
          user_hash: metaUserData.intercomUserHash,
          created_at: metaUserData.createdAtUnixTimestamp,
        },
      });
    }
  }, [metaUserData?.intercomUserHash]);

  if (user?.currentCompanyId === 0) {
    dispatch(push('/select-company'));
  }

  return appReady ? (
    <>
      <LoginAgain />
      <App {...props} />
    </>
  ) : (
    <LoadingScreen />
  );
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  company: fromCompany.getCompany(state),
  isAppReady: fromRoot.isAppReady(state),
  isTimeout: fromAuth.getTimeoutStatus(state),
});

ConnectedApp.propTypes = {
  children: PropTypes.node,
};

ConnectedApp.defaultProps = {
  children: null,
};

export default connect(mapStateToProps)(ConnectedApp);
