import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pending } from 'redux-saga-thunk';

import ConfigureMFAModal from 'containers/ConfigureMFAModal';
import { OrganizationSettingsModal } from 'components';

import { fromResource } from 'store/selectors';
import { resourceCreateRequest } from 'store/actions';
import {
  getFeatureUnleashApi,
  editOrganizationBySidApi,
} from 'services/apihelpers';

class OrganizationSettingsModalContainer extends Component {
  static propTypes = {
    company: PropTypes.shape({
      currentCompanySID: PropTypes.number.isRequired,
      currentAccountingYear: PropTypes.string.isRequired,
      currentWorkingPeriodEnd: PropTypes.number.isRequired,
    }).isRequired,
    payload: PropTypes.shape({}),
    onAutoReconcileGLLoading: PropTypes.bool,
    onAutoReconcileGLComplete: PropTypes.bool,
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,

    /** @type {function} totalAutoGLReconcile */
    totalAutoGLReconcile: PropTypes.func.isRequired,
    onReconcileClose: PropTypes.func.isRequired,
    account: PropTypes.string,
  };

  static defaultProps = {
    account: undefined,
    payload: undefined,
    onAutoReconcileGLLoading: true,
    onAutoReconcileGLComplete: false,
  };

  state = {
    modal: '',
    isMainAltinnData: false,
    ...this.props.payload,
  };

  handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    this.setState({ [name]: value });
  };

  handleToggleChange = (name) => {
    this.setState((state) => ({ [name]: !state[name] }));
  };

  handleSubmit = async () => {
    const {
      altinnUsername,
      altinnPassword,
      organizationName,
      organizationCode,
      isMainAltinnData,
      isUserGroupRequired,
      isCompanyGroupRequired,
      vismanxtCompanyId,
    } = this.state;

    const { payload, closeModal, editOrganizationSettings } = this.props;

    const query = {
      altinnUsername: altinnUsername || null,
      altinnPassword: altinnPassword || null,
      isMainAltinnData,
      organizationName,
      isUserGroupRequired,
      isCompanyGroupRequired,
      organizationSid: parseInt(payload.organizationSID, 10),
      organizationNumber: organizationCode,
      vismanxtCompanyId: vismanxtCompanyId || null,
    };

    await editOrganizationSettings(query);
    closeModal(false, true);
  };

  handleIsMainAltinnDataChange = () => {
    this.setState((prevState) => ({
      isMainAltinnData: !prevState.isMainAltinnData,
    }));
  };

  openMFA = () => this.setState({ modal: 'mfa' });

  closeModal = (e, refetch) => {
    this.setState({ modal: '' });

    if (refetch) {
      this.props.closeModal(false, true);
    }
  };

  renderModal = () => {
    const { payload } = this.props;
    let modalToRender = '';

    switch (this.state.modal) {
      case 'mfa': {
        modalToRender = (
          <ConfigureMFAModal
            type="organization"
            closeModal={this.closeModal}
            organization={payload}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return modalToRender;
  };

  render() {
    const { payload, loading, showMFA, roleType, closeModal } = this.props;

    return (
      <>
        {this.renderModal()}
        <OrganizationSettingsModal
          {...{
            ...this.state,
            showMFA,
            payload,
            loading,
            roleType,
            closeModal,
            openMFA: this.openMFA,
            handleChange: this.handleChange,
            handleSubmit: this.handleSubmit,
            handleToggleChange: this.handleToggleChange,
            handleIsMainAltinnDataChange: this.handleIsMainAltinnDataChange,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  showMFA: fromResource.getList(state, `${getFeatureUnleashApi}/mfa`).status,
  loading: pending(state, `${editOrganizationBySidApi}Create`),
});

const mapDispatchToProps = (dispatch) => ({
  editOrganizationSettings: (query) =>
    dispatch(
      resourceCreateRequest(editOrganizationBySidApi, {
        ...query,
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationSettingsModalContainer);
