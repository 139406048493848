import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { BsCheckLg } from '@react-icons/all-files/bs/BsCheckLg';
import { BsXLg } from '@react-icons/all-files/bs/BsXLg';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  ButtonIcon,
  Card,
  CustomTableWithAutoSettings,
  WarningBanner,
} from 'components';

import { userDetailsColumns } from 'services/tableshapes';
import { adminUsersSettingsFilters } from 'services/tableFilterShapes';
import { filterByActivatedStatuses } from 'services/shapes';

function UserDetailsTable(props) {
  const {
    data,
    title,
    search,
    loading,
    handleAction,
    actionSubmitting,
    organizationExternallyManaged,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'name',
        desc: false,
      },
    ],
    [],
  );

  const globalFilter = useMemo(
    () => ({
      recordStatus: filterByActivatedStatuses().filter(
        (item) => item.id === 'activated',
      ),
    }),
    [],
  );

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-userDetails">
      <CardPDFTitle title={title} />
      {organizationExternallyManaged && (
        <WarningBanner
          // eslint-disable-next-line max-len
          message={t`This organization is managed externally. Some features may not be
            available.`}
          linkTitle={t`Learn more.`}
          link="https://help.accountflow.com/nb/articles/10576818-hvorfor-kan-jeg-ikke-oppdatere-brukernes-roller"
          isExternalLink
        />
      )}
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          columns: userDetailsColumns(search),
          tableId: 'userDetails',
          loadingId: 'uuid',
          reportMode: search.reportMode,
          filterConfigs: adminUsersSettingsFilters(),
          headerConfigs: {
            exportFileName: title,
            extraButtons: [
              {
                id: 'addUser',
                icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                title: t`Add User`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                },
              },
            ],
          },
          selectConfigs: {
            buttons: [
              {
                id: 'addUserGroup',
                icon: <ButtonIcon as={FaPlus} type="extraButton" />,
                title: t`Create New User Group`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: true,
                  magnify: true,
                },
              },
              {
                id: 'activate',
                icon: <ButtonIcon as={BsCheckLg} type="extraButton" />,
                title: t`Activate`,
                buttonProps: {
                  size: 'sm',
                  kind: 'success',
                  fill: true,
                  magnify: true,
                },
              },
              {
                id: 'deactivate',
                icon: <ButtonIcon as={BsXLg} type="extraButton" />,
                title: t`Deactivate`,
                buttonProps: {
                  size: 'sm',
                  kind: 'danger',
                  fill: true,
                  magnify: true,
                },
              },
            ],
          },
          initialState: {
            sortBy,
            globalFilter,
          },
        }}
      />
    </Card>
  );
}

export default UserDetailsTable;
