import React, { useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';

import { Card, Heading, CustomTableWithAutoSettings, Field } from 'components';

import { VATNewerDetailedColumns } from 'services/tableshapes';
import { vatReportNewerDetailedFilters } from 'services/tableFilterShapes';

function VATNewerDetailed(props) {
  const { data, frequency, loading, type, id, term, accumulated, reportType } =
    props;
  const [showAllTypes, setShowAllTypes] = useState(false);

  const filteredData = useMemo(() => {
    const rows = [];

    if (
      Array.isArray(data?.data) &&
      !_isEmpty(data?.data) &&
      term !== undefined
    ) {
      // First find the matching frequency data
      const frequencyData = data.data.find(
        (item) => item.periodType === frequency,
      );

      if (
        Array.isArray(frequencyData?.reports) &&
        !_isEmpty(frequencyData?.reports)
      ) {
        const currentReport = frequencyData.reports.find(
          (report) =>
            report.reportType === reportType &&
            report.reportItems.some((item) => +item.term === +term),
        );

        const currentReportItem = currentReport?.reportItems.find(
          (item) => +item.term === +term,
        );

        // Use accumulatedRows only when accumulated is true
        const rowsToProcess = accumulated
          ? currentReportItem?.accumulatedRows
          : currentReportItem?.rows;

        const currentRow = rowsToProcess?.find((row) => row.id === +id);

        if (!_isEmpty(currentRow)) {
          const filteredByType = !showAllTypes
            ? currentRow.glTransactions.filter(
                (transaction) =>
                  !!transaction.glLines.filter((line) => line.type === type)
                    .length,
              )
            : currentRow.glTransactions;

          let groupId = 0;

          filteredByType?.forEach((row) => {
            const { glLines, ...rest } = row;

            const lines = showAllTypes
              ? glLines
              : glLines.filter((line) => line.type === type);

            let mainRow = { ...rest };

            if (lines.length === 1) {
              mainRow = { ...mainRow, ...lines[0] };
            }

            rows.push({ ...mainRow, groupId });
            groupId = !groupId;

            if (lines.length > 1) {
              rows.push(...lines.map((line) => ({ ...line, groupId })));
              groupId = !groupId;
            }
          });
        }
      }
    }

    return rows;
  }, [
    JSON.stringify(data),
    showAllTypes,
    term,
    id,
    frequency,
    accumulated,
    reportType,
  ]);

  const onShowAllChange = () => setShowAllTypes(!showAllTypes);

  const content = (
    <CustomTableWithAutoSettings
      {...{
        loading,
        data: filteredData,
        loadingId: 'accountId',
        tableId: 'vatNewerDetailed',
        enableGroups: true,
        groupId: 'groupId',
        filterConfigs: vatReportNewerDetailedFilters(),
        columns: VATNewerDetailedColumns(),
        headerConfigs: {
          exportFileName: t`VAT Detailed`,
        },
      }}
    />
  );

  return (
    <Card
      header={
        <HeaderContainer>
          <Heading level={3} className="card-title">
            <Trans>VAT Detailed</Trans>
          </Heading>
          <Field
            horizontal
            id="allTypes"
            type="checkbox"
            name="allTypes"
            label={t`Show all line types`}
            checked={showAllTypes}
            onChange={onShowAllChange}
          />
        </HeaderContainer>
      }
    >
      {content}
    </Card>
  );
}

export default VATNewerDetailed;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
