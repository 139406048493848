import { takeEvery, put, call, take } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import {
  user,
  userExtraDataApi,
  registerCompanyApi,
  currentUserDetailsApi,
  confirmRegistrationApi,
} from 'services/apihelpers';

import * as actions from './actions';
import { appReady } from '../root/actions';
import {
  companyInfoRequest,
  updateCompanyInfoFromUrl,
  COMPANY_INFO_SUCCESS,
  COMPANY_INFO_FAILURE,
} from '../company/actions';

export function* loadExtraUserData(api) {
  try {
    const extraUserData = yield call([api, api.get], `/${userExtraDataApi}`);

    if (extraUserData?.id) {
      const { ...userData } = extraUserData;
      yield put(actions.metaUserDataSuccess(userData));
    }
  } catch (error) {
    yield put(actions.metaUserDataFailure(error));
  }
}

/* eslint-disable no-shadow */
export function* loginUser(api, payload, { thunk }) {
  try {
    const response = yield call([api, api.get], `/${currentUserDetailsApi}`);

    if (response?.currentCompanyId === 0) {
      yield put(actions.loginSuccess(response, {}, thunk));
      yield put(appReady(true));
      return;
    }

    yield call(loadExtraUserData, api);

    if (payload && payload.companyUuid !== 'go') {
      yield put(updateCompanyInfoFromUrl(payload));
    } else {
      yield put(companyInfoRequest());
    }

    const company = yield take([COMPANY_INFO_SUCCESS, COMPANY_INFO_FAILURE]);

    if (company.type === COMPANY_INFO_FAILURE) {
      yield put(actions.loginSuccess(response, {}, thunk));
      yield put(appReady(true));
      yield put(push('/select-company'));
      return;
    }

    yield put(actions.loginSuccess(response, {}, thunk));
  } catch (error) {
    yield put(actions.loginFailure(error, {}, thunk));
    yield put(push('/error'));
  }
}

/* eslint-disable no-shadow */
export function* fetchUser(api, payload, { thunk }) {
  try {
    const userData = yield call([api, api.get], `/${user}`);
    yield put(actions.userSuccess(userData, {}, thunk));

    yield call(loadExtraUserData, api);
  } catch (error) {
    yield put(actions.userFailure(error, {}, thunk));
  }
}

export function* registerCompany(api, { registrationData }, { thunk }) {
  try {
    const user = yield call(
      [api, api.post],
      `/${registerCompanyApi}`,
      registrationData,
    );
    yield put(actions.registrationSuccess(user, { registrationData }, thunk));
  } catch (error) {
    yield put(actions.registrationFailure(error, { registrationData }, thunk));
  }
}

export function* timeoutUser(api, { thunk }) {
  try {
    yield put(actions.timeoutSuccess('OK', thunk));
  } catch (error) {
    yield put(actions.timeoutFailure(error, thunk));
  }
}

export function* validateRegistration(api, params, { thunk }) {
  try {
    const { companyId } = params;
    const user = yield call(
      [api, api.get],
      `/${confirmRegistrationApi}companyId=${companyId}`,
    );
    yield put(
      actions.validateRegistrationTokenSuccess(user, { params }, thunk),
    );
  } catch (error) {
    yield put(
      actions.validateRegistrationTokenFailure(error, { params }, thunk),
    );
  }
}

export function* watchLoadExraDataRequest(api, { payload, meta }) {
  yield call(loadExtraUserData, api, payload, meta);
}

export function* watchloginUserRequest(api, { payload, meta }) {
  yield call(loginUser, api, payload, meta);
  yield take(actions.LOGIN_SUCCESS);
  yield put(appReady(true));
}

export function* watchFetchUserRequest(api, { payload, meta }) {
  yield call(fetchUser, api, payload, meta);
}

export function* watchRegisterCompanyRequest(api, { payload, meta }) {
  yield call(registerCompany, api, payload, meta);
}

export function* watchtimeoutUserRequest(api, { meta }) {
  yield call(timeoutUser, api, meta);
}

export function* watchRegistrationValidation(api, { payload, meta }) {
  yield call(validateRegistration, api, payload, meta);
}

export default function* _({ api }) {
  yield takeEvery(
    actions.META_USER_DATA_REQUEST,
    watchLoadExraDataRequest,
    api,
  );
  yield takeEvery(actions.LOGIN_REQUEST, watchloginUserRequest, api);
  yield takeEvery(actions.USER_REQUEST, watchFetchUserRequest, api);
  yield takeEvery(actions.REGISTER_REQUEST, watchRegisterCompanyRequest, api);
  yield takeEvery(actions.TIMEOUT_REQUEST, watchtimeoutUserRequest, api);
  yield takeEvery(
    actions.VALIDATE_REGISTRATION_TOKEN_REQUEST,
    watchRegistrationValidation,
    api,
  );
}
