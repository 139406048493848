import React, { Component } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';

import { OrganizationSettings } from 'components';
import OrganizationSettingsModal from 'containers/OrganizationSettingsModal';

import { fromAuth, fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import { getOrganizationsByUserApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

class OrganizationSettingsContainer extends Component {
  state = {
    modal: '',
    modalItem: {},
    actionSubmitting: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.match.params) !==
        JSON.stringify(nextProps.match.params) ||
      this.props.user.organizationSID !== nextProps.user.organizationSID
    ) {
      this.fetchData(nextProps);
    }

    return true;
  }

  getSearch = (props = this.props) => {
    const { location } = props;
    const search = queryString.parse(location.search, { parseBooleans: true });

    return {
      reportMode: search.reportMode || false,
    };
  };

  fetchData = (props) => {
    const { getAllOrganization, user } = props || this.props;

    getAllOrganization({
      organizationSid: user.organizationSID,
    });
  };

  toggleModal = (name, modalItem = {}) => {
    this.setState((prevState) => ({
      modal: prevState.modal ? '' : name,
      modalItem: prevState.modal ? {} : modalItem,
    }));
  };

  setActionSubmitting = (action, submitting) => {
    this.setState((state) => ({
      actionSubmitting: { ...state.actionSubmitting, [action]: submitting },
    }));
  };

  handleAction = async (action, props) => {
    try {
      this.setActionSubmitting(action, true);

      switch (action) {
        case 'edit': {
          this.toggleModal('edit', props);
          break;
        }

        default: {
          break;
        }
      }

      this.setActionSubmitting(action, false);
    } catch (e) {
      this.setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  closeModal = () => {
    this.setState({
      modal: '',
      modalItem: {},
    });
  };

  closeAddEditModal = (_, refetch) => {
    this.closeModal();

    if (refetch) {
      this.fetchData();
    }
  };

  renderModal = () => {
    let modalToRender = '';

    switch (this.state.modal) {
      case 'edit': {
        modalToRender = (
          <OrganizationSettingsModal
            payload={this.state.modalItem}
            roleType={this.props.roleType}
            closeModal={this.closeAddEditModal}
          />
        );
        break;
      }

      default: {
        break;
      }
    }

    return modalToRender;
  };

  render() {
    const { actionSubmitting } = this.state;
    const { data, loading, canEditOrganization } = this.props;
    const search = this.getSearch(this.props);

    return (
      <>
        {this.renderModal()}
        <OrganizationSettings
          {...{
            data,
            search,
            loading,
            actionSubmitting,
            canEditOrganization,
            title: t`Organizations`,
            handleAction: this.handleAction,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: fromAuth.getUser(state),
  data: fromResource.getList(state, getOrganizationsByUserApi),
  loading: pending(state, `${getOrganizationsByUserApi}ListRead`),
});

const mapDispatchToProps = (dispatch) => ({
  getAllOrganization: (query) =>
    dispatch(resourceListReadRequest(getOrganizationsByUserApi, query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationSettingsContainer);
