import { useCallback, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { t } from '@lingui/macro';

let globalPopupRef = null;

function Altinn3Popup(props) {
  const { title, onHide, width, height } = props;
  const intervalRef = useRef(null);

  const openPopup = useCallback(
    (url) => {
      const popupWidth = width || 800;
      const popupHeight = height || 600;
      const browserWindowX = window.screenX || window.screenLeft;
      const browserWindowY = window.screenY || window.screenTop;
      const browserWidth = window.outerWidth;
      const browserHeight = window.outerHeight;
      const left = browserWindowX + browserWidth / 2 - popupWidth / 2;
      const top = browserWindowY + browserHeight / 2 - popupHeight / 2;

      const popup = window.open(
        url,
        title,
        `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
      );

      globalPopupRef = popup;

      if (!popup) {
        toast.error(
          // eslint-disable-next-line max-len
          t`The Altinn login pop-up was blocked. Please allow pop-ups and try again.`,
        );
        onHide?.();
      } else {
        intervalRef.current = setInterval(() => {
          if (globalPopupRef?.closed) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            globalPopupRef = null;
            onHide?.();
          }
        }, 100);
      }
    },
    [props],
  );

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return { openPopup };
}

export default Altinn3Popup;
