import React, { useEffect, useState, useMemo } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';

import { PayrollReconciliationTable } from 'components';

import PayrollReport from 'containers/PayrollReport';
import CommentsModal from 'containers/CommentsModal';
import FileUploadModal from 'containers/FileUploadModal';
import AltinnConfigModal from 'containers/AltinnConfigModal';
import PayrollDrilldownModal from 'containers/PayrollDrilldownModal';
import VacationPayRowsModal from 'containers/VacationPayRowsModal';
import CreateManualReportModal from 'containers/CreateManualReportModal';
import PayrollSocialSecurityModal from 'containers/PayrollSocialSecurityModal';
import MapPayrollTransactionModal from 'containers/MapPayrollTransactionModal';
import PayrollWTDrilldownModal from 'containers/PayrollWTDrilldownModal';
// eslint-disable-next-line max-len
import MapPayrollWTTransactionModal from 'containers/MapPayrollWTTransactionModal';

import { usePayrollReportGenerator } from 'services/hooks/reports';
import { toPayrollReconciliation } from 'services/routehelpers';
import { fromAuth, fromResource } from 'store/selectors';
import {
  resourceCreateRequest,
  resourceListReadRequest,
  resourceListReadSuccess,
} from 'store/actions';
import {
  downloadFile,
  commentsRouteInitializer,
  periodSelectorValueInitialize,
} from 'services/helpers';
import { monthNumberToMonthName } from 'services/helpers/periods';
import {
  getA07XMLApi,
  altinnFetchApi,
  getFileListApi,
  uploadedFilesApi,
  isPayrollApprovedApi,
  savePayrollCommentApi,
  uploadBankTransactionApi,
  updateApprovalStatusPayrollReportApi,
  getLatestReportApi,
  downloadReportApi,
  bankGuaranteeApi,
} from 'services/apihelpers';
import {
  useA07Data,
  usePRandOBdata,
  useNewWithheldTaxData,
  useVacationPayData,
  useReportedSalaryData,
  useSocialSecurityData,
  useReportedSalaryDetailsData,
} from 'services/hooks/payroll';
import { bankGuaranteeFormatter } from 'services/dataFormatters/payroll';

const getSearch = (location, currentWorkingPeriodEnd) => {
  const search = location
    ? queryString.parse(location.search, {
        parseBooleans: true,
      })
    : {};

  const result = {
    bimonthly:
      typeof search.bimonthly === 'undefined' ? true : search.bimonthly,
    onlyNumbers: search.onlyNumbers || false,
    subTabEntry: search.subTabEntry || 'report',
    activePeriods: search.activePeriods || currentWorkingPeriodEnd || 12,
    accumulated: search.accumulated,
  };

  if (result.accumulated === undefined) {
    result.accumulated = true;
  }

  return result;
};

const commentsRoutes = commentsRouteInitializer('payrollComment', {});
export function PayrollReconciliationTableContainer(props) {
  const auth = useAuth();
  const { location, roleType, history, company } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const { accountingYear, companyId, accountingPeriod } = params;
  const {
    uuid,
    currentCompanySID,
    currentCompanyName,
    currentWorkingPeriodEnd,
    currentCompanyRegistrationNumber,
  } = company;

  const [mapItem, setMapItem] = useState({});
  const [showModal, setShowModal] = useState('');
  const [loadingA07XML, setLoadingA07XML] = useState({});
  const [downloadingLatest, setDownloadingLatest] = useState(false);

  const user = useSelector(fromAuth.getUser);
  const files = useSelector((state) =>
    fromResource.getList(state, uploadedFilesApi),
  );
  const payrollApproved = useSelector((state) =>
    fromResource.getList(state, isPayrollApprovedApi),
  ).status;
  const payrollApprovedLoading = useSelector((state) =>
    pending(state, `${isPayrollApprovedApi}ListRead`),
  );
  const loadingAltinnFetch = useSelector((state) =>
    pending(state, `${altinnFetchApi}Create`),
  );
  const latestReport = useSelector((state) =>
    fromResource.getList(state, getLatestReportApi),
  );
  const bankGuaranteeData = useSelector((state) =>
    fromResource.getList(state, bankGuaranteeApi),
  );

  const tabs = useMemo(
    () => [
      {
        title: t`Reported Salary`,
        eventKey: 'report',
      },
      {
        title: t`Reported Salary Details`,
        eventKey: 'salary-details',
      },
      {
        title: t`Social Security`,
        eventKey: 'SSTP',
      },
      {
        title: t`Withheld Tax`,
        eventKey: 'WTP',
      },
      {
        title: t`Financial Tax`,
        eventKey: 'FTP',
      },
      {
        title: t`Vacation Pay`,
        eventKey: 'VP',
      },
    ],
    [],
  );
  const search = useMemo(
    () => getSearch(location, currentWorkingPeriodEnd),
    [location, currentWorkingPeriodEnd],
  );

  const { bimonthly, onlyNumbers, accumulated, subTabEntry, activePeriods } =
    search;

  const term = Math.ceil(activePeriods / 2);

  const [reportPeriod, setReportPeriod] = useState(activePeriods);

  const handleCheckboxChange = ({ target: { checked } }, field) => {
    history.push(
      toPayrollReconciliation({
        ...search,
        [field]: checked,
      }),
    );
  };

  const getFiles = async () => {
    try {
      const response = await dispatch(
        resourceListReadRequest(getFileListApi, {
          account: '',
          showAll: true,
          toPeriod: activePeriods || 12,
          companyId: currentCompanySID,
          fromPeriod: 1,
          periodType: company.currentPeriodType,
          periodYear: accountingYear,
          fileCategory: 'PAYROLL',
        }),
      );

      if (response?.transactionFileList) {
        return response.transactionFileList.map((item) => ({
          key: item.fileKey,
          name: item.fileName,
        }));
      }
    } catch {
      return [];
    }

    return [];
  };

  const dataSocialSecurity = useSocialSecurityData({
    uuid,
    bimonthly,
    accumulated,
    activePeriods,
    accountingYear,
  });
  const reportedSalaryData = useReportedSalaryData({
    uuid,
    bimonthly,
    accumulated,
    onlyNumbers,
    activePeriods,
    accountingYear,
    handleCheckboxChange,
  });
  const reportedSalaryDetailsData = useReportedSalaryDetailsData({
    uuid,
    bimonthly,
    accumulated,
    activePeriods,
    accountingYear,
  });
  const PRandOBdata = usePRandOBdata({ activePeriods, accountingYear, uuid });
  const withheldTaxData = useNewWithheldTaxData({
    accountingYear,
    currentCompanySID,
    month: monthNumberToMonthName(activePeriods),
  });
  const vacationPayData = useVacationPayData({
    uuid,
    bimonthly,
    activePeriods,
    accountingYear,
    currentCompanySID,
  });
  const a07Data = useA07Data({
    bimonthly,
    accumulated,
    activePeriods,
    accountingYear,
    currentCompanySID,
  });
  const { generatePDF, disabled, pdfGenerating, Preview } =
    usePayrollReportGenerator({
      getFiles,
      bimonthly,
      onlyNumbers,
      accumulated,
      activePeriods,
      accountingYear,
      payrollApproved,
      company: currentCompanyName,
      orgNumber: currentCompanyRegistrationNumber,

      a07Data,
      PRandOBdata,
      withheldTaxData,
      vacationPayData,
      dataSocialSecurity,
      reportedSalaryData,
      reportedSalaryDetailsData,
      bankGuaranteeStatus: withheldTaxData.bankGuaranteeStatus,
      payDay: withheldTaxData.payDay,
      bankGuaranteeData: bankGuaranteeFormatter(bankGuaranteeData),
    });
  const { approveAndGeneratePDF } = usePayrollReportGenerator({
    bimonthly,
    onlyNumbers: true,
    accumulated: false,
    activePeriods: reportPeriod,
    accountingYear,
    payrollApproved: true,
    company: currentCompanyName,
    orgNumber: currentCompanyRegistrationNumber,

    a07Data,
    PRandOBdata,
    withheldTaxData,
    vacationPayData,
    dataSocialSecurity,
    reportedSalaryData,
    reportedSalaryDetailsData,
    bankGuaranteeStatus: withheldTaxData.bankGuaranteeStatus,
    payDay: withheldTaxData.payDay,
    bankGuaranteeData: bankGuaranteeFormatter(bankGuaranteeData),
  });
  const { loading } = a07Data;

  const handleTabSelect = (key) => {
    history.push(
      toPayrollReconciliation({
        ...search,
        subTabEntry: key,
      }),
    );
  };
  const getApproveStatus = () => {
    dispatch(
      resourceListReadRequest(isPayrollApprovedApi, {
        bimonthly,
        year: accountingYear,
        companyId: currentCompanySID,
        period: bimonthly ? term : activePeriods,
      }),
    );
  };
  const getLatestReport = async () => {
    const query = {
      companyUUID: companyId,
      accountingYear,
      accountingPeriod: reportPeriod,
      reportType: ['PAYROLL'],
    };
    await dispatch(resourceListReadRequest(getLatestReportApi, query));
  };
  const closeModal = (_e, refetch, type, uploadedFiles) => {
    setShowModal('');
    setMapItem({});

    if (refetch) {
      a07Data.fetchData();
    }

    if (type === 'files') {
      // Update uploaded files in redux
      const newFiles = [
        {
          accountId: null,
          fileNames: uploadedFiles?.map((item) => item.fileName),
        },
      ];

      dispatch(resourceListReadSuccess(uploadedFilesApi, newFiles));
    }
  };
  const toggleModal = (type, item, settlement) => {
    setMapItem({});

    if (type === 'mapReports') {
      if (item.id) {
        setMapItem({ ...item });
      }
    } else if (type === 'mapWTTransaction') {
      setMapItem(item);
    } else if (item) {
      const selectedRow = { ...item };
      selectedRow.settlement = settlement;

      setMapItem(selectedRow);
    }

    setShowModal(type);
  };
  const handleRowClick = (item, side, manual) => {
    setMapItem({ ...item, accumulated, side, manual });
    setShowModal('drilldown');
  };
  const handleSSRowClick = (type) => {
    setMapItem({ type });
    setShowModal('socialSecurityDrillDown');
  };
  const handlePeriodChange = (event, periodType) => {
    const { target } = event;
    const period = Number.parseInt(target.getAttribute('id'), 10);
    const periodEnd = periodSelectorValueInitialize(periodType, period);
    const _activePeriods = Array.from(Array(periodEnd || 1).keys());
    setReportPeriod(periodEnd);

    history.push(
      toPayrollReconciliation({
        ...search,
        activePeriods: _activePeriods.length,
      }),
    );
  };
  const handleApprovedChange = async () => {
    const query = {
      bimonthly,
      year: accountingYear,
      companyId: currentCompanySID,
      payrollPeriod: bimonthly ? term : activePeriods,
    };

    try {
      await dispatch(
        resourceCreateRequest(updateApprovalStatusPayrollReportApi, {
          ...query,
          updateStatus: !payrollApproved,
        }),
      );
      if (!payrollApproved) {
        await approveAndGeneratePDF({
          skipDownload: true,
          saveReport: true,
          periodOverride: reportPeriod,
        });
        toast.success(t`Report generated successfully`);
      }
      getApproveStatus();
      getLatestReport();
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };
  const handleAccumulatedChange = () => {
    history.push(
      toPayrollReconciliation({
        ...search,
        accumulated: !accumulated,
      }),
    );
  };
  const handleCommentChange = async (value, row) => {
    await dispatch(
      resourceCreateRequest(savePayrollCommentApi, {
        bimonthly,
        companyId,
        year: accountingYear,
        period: bimonthly ? term : activePeriods,
        comment: value,
        rowNumber: row.rowNumber,
      }),
    );
  };
  const handleDownloadA07XML = async (id, name) => {
    setLoadingA07XML({ ...loadingA07XML, [id]: true });

    await downloadFile(
      auth.user?.access_token,
      {
        url: `/${getA07XMLApi}/${id}`,
        name: `${name}.xml`,
      },
      () => {
        setLoadingA07XML({ ...loadingA07XML, [id]: false });
      },
    );
  };
  const handleDownloadLatestReport = async () => {
    setDownloadingLatest(true);
    if (latestReport.PAYROLL?.[0]) {
      await downloadFile(auth.user?.access_token, {
        /* eslint-disable max-len */
        url: `/${downloadReportApi}?companyUUID=${companyId}&file=${latestReport.PAYROLL[0].storageLocation}&fileName=${latestReport.PAYROLL[0].version}.zip`,
        name: t`${currentCompanyName} (${currentCompanyRegistrationNumber}) - Payroll (Period ${accountingPeriod}, ${accountingYear}) - Version ${latestReport.PAYROLL[0].version}.zip`,
      });
    }
    setDownloadingLatest(false);
  };

  const renderModal = () => {
    switch (showModal) {
      case 'socialSecurityDrillDown':
        return (
          <PayrollSocialSecurityModal
            {...{
              ...mapItem,
              search,
              activePeriods,
              toggleModal: closeModal,
              data: dataSocialSecurity.data,
              loading: dataSocialSecurity.loading,
              refetch: dataSocialSecurity.fetchData,
            }}
          />
        );
      case 'drilldown':
        return (
          <PayrollDrilldownModal
            {...{
              bimonthly,
              row: mapItem,
              activePeriods,
              toggleModal: closeModal,
              accumulated: mapItem.accumulated,
            }}
          />
        );
      case 'showLines':
        return (
          <PayrollWTDrilldownModal
            {...{
              row: mapItem,
              toggleModal: closeModal,
              loading: withheldTaxData.loading,
              refetch: withheldTaxData.fetchData,
            }}
          />
        );
      case 'pay_rows':
        return (
          <VacationPayRowsModal
            show
            {...{
              period: term,
              toggleModal: closeModal,
              refetch: vacationPayData.refetch,
              current: vacationPayData.currentMainPercentage,
              currentZone: vacationPayData.currentZone,
              periodType: 'BIMONTHLY', // TODO: get this as a variable
              year: accountingYear,
              reportId: vacationPayData.zoneReportId,
            }}
          />
        );
      case 'altinn':
        return (
          <AltinnConfigModal
            toggleWindow
            {...{
              bimonthly,
              activePeriods,
              mode: 'company',
              altinnSignIn: () => closeModal(null, true),
            }}
          />
        );
      case 'create-manual':
        return (
          <CreateManualReportModal
            roleType={roleType}
            toggleModal={closeModal}
            activePeriods={activePeriods}
          />
        );
      case 'report':
        return (
          <PayrollReport
            {...{
              data: a07Data.data,
              loading: a07Data.loading,
              bimonthly,
              loadingA07XML,
              activePeriods,
              accountingYear,
              accumulated,
              handleAccumulatedChange,
              handleDownloadA07XML,
              fetchData: a07Data.fetchData,
              togglePayrollReportModal: closeModal,
              refetch: dataSocialSecurity.fetchData,
              updatePercentage: a07Data.updatePercentage,
            }}
          />
        );

      case 'dialog':
        return (
          <CommentsModal
            showModal
            {...{
              user,
              roleType,
              componentSetupValues: {
                commentsRoutes,
                commentModalType: 'payrollComment',
              },
              company: {
                ...company,
                bimonthly,
                currentWorkingPeriodEnd: activePeriods,
              },
            }}
            toggleModal={closeModal}
          />
        );

      case 'file_upload':
        return (
          <FileUploadModal
            showModal
            {...{
              user,
              history,
              roleType,
              account: '',
              toRoute: toPayrollReconciliation,
              fileCategory: 'PAYROLL',
              company: {
                ...company,
                bimonthly,
                currentWorkingPeriodEnd: activePeriods,
              },
            }}
            modalTitle={t`Payroll File Upload`}
            toggleModal={(e, r, _, _files) => closeModal(e, r, 'files', _files)}
            uploadFilesTo={uploadBankTransactionApi}
          />
        );

      case 'mapReports':
      case 'mapSettlements':
        return (
          <MapPayrollTransactionModal
            row={mapItem}
            type={showModal === 'mapReports' ? 'report' : ''}
            bimonthly={bimonthly}
            subTabEntry={subTabEntry}
            toggleModal={closeModal}
            refetchParent={
              showModal === 'mapReports' ? null : PRandOBdata.fetchData
            }
          />
        );

      case 'mapWTTransaction':
        return (
          <MapPayrollWTTransactionModal
            row={mapItem}
            toggleModal={closeModal}
            refetchParent={withheldTaxData.fetchData}
          />
        );

      default:
        return false;
    }
  };

  useEffect(() => {
    if (showModal === 'showLines' && withheldTaxData.rows) {
      const dataToUpdate = withheldTaxData.rows.find(
        (withheldTaxMonth) => withheldTaxMonth.month === mapItem.month,
      );
      if (dataToUpdate) {
        setMapItem({
          ...mapItem,
          drilldown: dataToUpdate.drilldown,
          generalLedgerDrilldownLines: dataToUpdate.generalLedgerDrilldownLines,
          difference: dataToUpdate.difference,
        });
      }
    }
  }, [withheldTaxData.rows, showModal, mapItem]);

  const fetchFilesNumber = async () => {
    const query = {
      companyId: uuid,
      year: +accountingYear,
      period: +accountingPeriod,
      fileCategory: 'PAYROLL',
    };

    await dispatch(resourceListReadRequest(uploadedFilesApi, query));
  };

  useEffect(() => {
    fetchFilesNumber();
  }, [uuid, accountingYear, accountingPeriod]);
  useEffect(() => {
    getApproveStatus();
    getLatestReport();
  }, [bimonthly, currentCompanySID, accountingYear, activePeriods]);

  useEffect(() => {
    const _search = { ...search };
    let redirect = false;

    if (subTabEntry === 'VP') {
      if (_search.bimonthly === false) {
        _search.bimonthly = undefined;
        redirect = true;
      }
    }

    if (redirect && showModal !== 'report') {
      history.push(toPayrollReconciliation(_search));
    }
  }, [subTabEntry, accumulated]);

  return (
    <>
      {renderModal()}
      <PayrollReconciliationTable
        a07Loading={loading}
        {...{
          tabs,
          term,
          files,
          search,
          company,
          disabled,
          roleType,
          Preview,
          generatePDF,
          latestReport,
          PRandOBdata,
          toggleModal,
          pdfGenerating,
          accountingYear,
          handleRowClick,
          vacationPayData,
          handleTabSelect,
          payrollApproved,
          withheldTaxData,
          handleSSRowClick,
          reportedSalaryData,
          dataSocialSecurity,
          handlePeriodChange,
          loadingAltinnFetch,
          handleCommentChange,
          handleApprovedChange,
          handleCheckboxChange,
          payrollApprovedLoading,
          handleAccumulatedChange,
          reportedSalaryDetailsData,
          handleDownloadLatestReport,
          downloadingLatest,
        }}
      />
    </>
  );
}

export default PayrollReconciliationTableContainer;
