import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useForm } from 'react-form';
import { Trans, t } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal as BootstrapModal, ButtonGroup } from 'react-bootstrap';
import { format } from 'date-fns';

import {
  Field,
  Button,
  Spinner,
  Heading,
  ButtonIcon,
  ReactFormField,
  ReconciliationPeriodFilterButtons,
  CustomTableWithAutoSettings,
} from 'components';

import { altinnConfigModalColumns } from 'services/tableshapes';

import './style.css';
import { DESKTOP } from 'constants/mediaSizes';

function AltinnConfigModal(props) {
  const {
    mode,
    error,
    loading,
    company,
    onSubmit,
    bimonthly,
    toggleWindow,
    altinnSignIn,
    initialState,
    fetchSuccess,
    handleAction,
    activePeriods,
    initialResponse,
    sessionValidate,
    actionSubmitting,
    periodSelectPerRow,
    handlePeriodChange,
    altinnFetchDetails,
    authenticateResponse,
    altinnUserCredentials,
    altinnUpdatedDataList,
    handlePeriodSelectPerRow,
    reconcilingFromDate,
  } = props;

  const {
    Form,
    reset,
    values,
    handleSubmit,
    meta: { canSubmit },
  } = useForm({ onSubmit });

  // Check if form is valid for submission
  const getFormValidation = () => {
    if (initialState) {
      // Initial login requires SSN and password
      return values?.ssn && values?.altinnUserPassword;
    }

    if (
      sessionValidate === false &&
      (!error ||
        (authenticateResponse && +authenticateResponse.errorId === 989))
    ) {
      // OTP verification step
      return values?.otp;
    }

    // Other states
    return true;
  };

  const isFormValid = getFormValidation();
  const disabled = !canSubmit || !isFormValid;

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  const showPeriodSelectPerRow = mode !== 'VAT' && periodSelectPerRow;

  const data = useMemo(
    () =>
      Array.isArray(altinnUpdatedDataList)
        ? altinnUpdatedDataList.map((row) => ({
            ...row,
            periodSelectPerRow,
          }))
        : [],
    [JSON.stringify(altinnUpdatedDataList), periodSelectPerRow],
  );

  const showReconcilingDateInfo = useMemo(() => {
    if (!reconcilingFromDate) return false;
    const reconcilingYear = new Date(reconcilingFromDate).getFullYear();
    return company.currentAccountingYear <= reconcilingYear;
  }, [reconcilingFromDate, company.currentAccountingYear]);

  const isYearBeforeReconciling = useMemo(() => {
    if (!reconcilingFromDate) return false;
    const reconcilingYear = new Date(reconcilingFromDate).getFullYear();
    return company.currentAccountingYear < reconcilingYear;
  }, [reconcilingFromDate, company.currentAccountingYear]);

  const periodSelectors = (
    <SelectorsHolder>
      {periodSelectPerRow ? (
        <div />
      ) : (
        <PeriodButtonsContainer>
          {isYearBeforeReconciling ? (
            <ReconciliationWarning>
              <Trans>
                Data cannot be retrieved. The reconciliation start date (
                {format(new Date(reconcilingFromDate), 'yyyy-MM-dd')}) is later
                than the current accounting year (
                {company.currentAccountingYear}).
              </Trans>
            </ReconciliationWarning>
          ) : (
            <>
              <ReconciliationPeriodFilterButtons
                size="sm"
                periodType={bimonthly ? 'biMonthly' : 'monthly'}
                activePeriods={[activePeriods]}
                handleFilterChange={handlePeriodChange}
                disabled={isYearBeforeReconciling}
                reconcilingFromDate={reconcilingFromDate}
                currentYear={company.currentAccountingYear}
              />
              <Year level={5}>{`- ${company.currentAccountingYear}`}</Year>
              {showReconcilingDateInfo && (
                <ReconcilingDateInfo>
                  <Trans>
                    Reconciliation start date:{' '}
                    {format(new Date(reconcilingFromDate), 'yyyy-MM-dd')}
                  </Trans>
                </ReconcilingDateInfo>
              )}
            </>
          )}
        </PeriodButtonsContainer>
      )}
      <Field
        noBorder
        id="periodSelectPerRow"
        type="checkbox"
        size="small"
        name="periodSelectPerRow"
        label={t`Single period select`}
        onChange={handlePeriodSelectPerRow}
        disabled={isYearBeforeReconciling}
      />
    </SelectorsHolder>
  );

  const table = (
    <CustomTableWithAutoSettings
      {...{
        data: isYearBeforeReconciling ? [] : data,
        loading,
        isModal: true,
        hideFilters: true,
        handleAction,
        actionSubmitting,
        columns: altinnConfigModalColumns(showPeriodSelectPerRow),
        tableId: 'altinnConfigModal',
        loadingId: 'period',
        headerConfigs: {
          exportFileName: t`Altinn Config Update`,
        },
      }}
    />
  );

  return (
    <Modal show={toggleWindow} onEscapeKeyDown={() => altinnSignIn(1)}>
      <Form>
        <Modal.Header>
          <Heading level={3}>
            <Trans>Altinn Login</Trans>
          </Heading>
        </Modal.Header>
        <Modal.Body>
          {loading || sessionValidate === undefined ? (
            <LoadingContainer>
              <Spinner size="md" />
            </LoadingContainer>
          ) : (
            <>
              {company.altinnUsername === null && (
                <div>
                  <h4 style={{ textAlign: 'center' }}>
                    <Trans>
                      Please setup Altinn credentials through{' '}
                      <strong>Integrations</strong>
                    </Trans>
                  </h4>
                </div>
              )}
              {company.altinnUsername !== null && isYearBeforeReconciling && (
                <ReconciliationWarning>
                  <Trans>
                    Data cannot be retrieved. The reconciliation start date (
                    {format(new Date(reconcilingFromDate), 'yyyy-MM-dd')}) is
                    later than the current accounting year (
                    {company.currentAccountingYear}).
                  </Trans>
                </ReconciliationWarning>
              )}
              {company.altinnUsername !== null && !isYearBeforeReconciling && (
                <>
                  {initialState && (
                    <div>
                      <ReactFormField
                        autoFocus
                        name="ssn"
                        type="text"
                        size="small"
                        label={t`Social Security Number:`}
                        disabled={loading}
                        required
                      />
                      <ReactFormField
                        size="small"
                        type="password"
                        name="altinnUserPassword"
                        label={t`Altinn User Password:`}
                        disabled={loading}
                        onKeyDown={handleOnKeyDown}
                        autoComplete="new-password"
                        required
                      />
                      {(mode === 'company' || mode === 'VAT') && (
                        <>
                          {mode === 'company' && periodSelectors}
                          {table}
                        </>
                      )}
                    </div>
                  )}
                  {initialState === false &&
                    altinnFetchDetails === false &&
                    fetchSuccess === false && (
                      <div>
                        {sessionValidate === false &&
                          (!error ||
                            (authenticateResponse &&
                              +authenticateResponse.errorId === 989)) && (
                            <ReactFormField
                              autoFocus
                              name="otp"
                              type="text"
                              size="small"
                              label={t`SMS Code:`}
                              disabled={loading}
                              onKeyDown={handleOnKeyDown}
                              autoComplete="one-time-code"
                            />
                          )}
                        {(error ||
                          (authenticateResponse &&
                            authenticateResponse?.errorId)) && (
                          <Heading
                            level={6}
                            alignment="center"
                            background="danger"
                          >
                            {(authenticateResponse &&
                              authenticateResponse?.message) ||
                              error}
                          </Heading>
                        )}
                        {sessionValidate === true &&
                          (mode === 'company' || mode === 'VAT') && (
                            <>
                              {mode === 'company' && periodSelectors}
                              {table}
                            </>
                          )}
                        {/* eslint-disable-next-line */}
                        {sessionValidate === true && mode === 'organization' && (
                          <div>
                            <Heading level={5}>
                              <Trans>
                                Your Credentials Has Been Saved. Refetch A07
                                Records.
                              </Trans>
                            </Heading>
                          </div>
                        )}
                      </div>
                    )}
                  {initialState === false &&
                    altinnFetchDetails === true &&
                    fetchSuccess === false && (
                      <div>
                        <h4 style={{ textAlign: 'center' }}>
                          <Trans>
                            Waiting for a response from Altinn... This can take
                            a couple of minutes
                          </Trans>
                        </h4>
                      </div>
                    )}
                  {initialState === false &&
                    altinnFetchDetails === false &&
                    fetchSuccess === true &&
                    authenticateResponse !== undefined &&
                    authenticateResponse?.status !== null &&
                    authenticateResponse?.status !== 'Fail' && (
                      <div>
                        <h4 style={{ textAlign: 'center' }}>
                          <Trans>Fetching completed.</Trans>
                        </h4>
                      </div>
                    )}
                  {initialState === false &&
                    authenticateResponse !== undefined &&
                    authenticateResponse?.status !== null &&
                    authenticateResponse?.status === 'Fail' && (
                      <div>
                        <h4 style={{ textAlign: 'center' }}>
                          <Trans>Fetching Failed.</Trans>
                        </h4>
                      </div>
                    )}
                </>
              )}
              <br />
              {initialState === true &&
                initialResponse &&
                initialResponse.message && (
                  <pre
                    className={
                      initialState === true &&
                      initialResponse &&
                      initialResponse.status === 'InvalidCredentials'
                        ? 'bg-danger'
                        : 'bg-success'
                    }
                  >
                    {initialResponse.message}
                  </pre>
                )}

              {initialState === false &&
                authenticateResponse &&
                authenticateResponse?.message && (
                  <pre
                    className={
                      authenticateResponse?.status === 'Fail'
                        ? 'bg-danger'
                        : 'bg-success'
                    }
                  >
                    {authenticateResponse?.message}
                  </pre>
                )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup bsSize="small">
            <Button
              fill
              wide
              kind="info"
              type="submit"
              disabled={
                loading ||
                sessionValidate === undefined ||
                disabled ||
                altinnUserCredentials ||
                altinnFetchDetails ||
                company.altinnUsername === null ||
                (altinnFetchDetails === false && fetchSuccess === true) ||
                isYearBeforeReconciling
              }
            >
              {altinnUserCredentials || altinnFetchDetails ? (
                <Spinner type="white" size="md" />
              ) : (
                <Trans>Fetch Altinn Details</Trans>
              )}
            </Button>

            <Button
              fill
              magnify
              kind="danger"
              onClick={() => {
                reset();
                altinnSignIn(1);
              }}
            >
              <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
AltinnConfigModal.propTypes = {
  initialResponse: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({}).isRequired,
  authenticateResponse: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  altinnUserCredentials: PropTypes.bool,
  altinnFetchDetails: PropTypes.bool,
  sessionValidate: PropTypes.bool,
  error: PropTypes.string,
  fetchSuccess: PropTypes.bool,
  initialState: PropTypes.bool,
  toggleWindow: PropTypes.bool,
  altinnUpdatedDataList: PropTypes.shape({}).isRequired,
  mode: PropTypes.string,
  altinnSignIn: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bimonthly: PropTypes.bool,
  handleAction: PropTypes.func,
  activePeriods: PropTypes.number,
  actionSubmitting: PropTypes.shape({}),
  periodSelectPerRow: PropTypes.bool,
  handlePeriodChange: PropTypes.func.isRequired,
  handlePeriodSelectPerRow: PropTypes.func.isRequired,
  reconcilingFromDate: PropTypes.string,
};

AltinnConfigModal.defaultProps = {
  mode: 'company',
  error: '',
  handleAction: () => {},
  bimonthly: false,
  toggleWindow: false,
  fetchSuccess: false,
  initialState: true,
  actionSubmitting: {},
  activePeriods: 12,
  sessionValidate: false,
  periodSelectPerRow: false,
  altinnFetchDetails: false,
  altinnUserCredentials: false,
  reconcilingFromDate: null,
};

export default AltinnConfigModal;

const SelectorsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Year = styled((props) => <Heading {...props} />)`
  margin: 0 0 0 10px;
  font-weight: 400;
`;

const Modal = styled(BootstrapModal)`
  .modal-dialog {
    @media (min-width: ${DESKTOP.min}px) {
      width: 650px;
    }
  }
`;

const PeriodButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ReconcilingDateInfo = styled.div`
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.info};
  font-size: 0.9em;
`;

const ReconciliationWarning = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.dangerLight};
  border-radius: 4px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;
