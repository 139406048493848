import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';

import { Label, Button, Spinner } from 'components';

function ReconciliationPeriodFilterButtons(props) {
  const {
    size,
    label,
    loading,
    disabled,
    periodType,
    singlePeriod,
    activePeriods,
    handleFilterChange,
    reconcilingFromDate,
    currentYear,
  } = props;

  // Only show loading state while reconciling date is being fetched initially
  const isLoading = loading && !reconcilingFromDate;

  // Show loading state while waiting for reconciling date
  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner size="sm" />
      </LoadingContainer>
    );
  }

  // Get allowed periods based on reconciliation date
  const getAllowedPeriods = () => {
    if (!reconcilingFromDate) return [];

    const reconcilingDate = new Date(reconcilingFromDate);
    const reconcilingYear = reconcilingDate.getFullYear();
    const reconcilingMonth = reconcilingDate.getMonth() + 1;

    if (currentYear < reconcilingYear) return [];
    if (currentYear > reconcilingYear) {
      return periodType === 'biMonthly'
        ? [1, 2, 3, 4, 5, 6]
        : Array.from({ length: 12 }, (_, i) => i + 1);
    }

    // For current year, only show periods from reconciling month onwards
    if (periodType === 'biMonthly') {
      const startPeriod = Math.ceil(reconcilingMonth / 2);
      return Array.from(
        { length: 6 - startPeriod + 1 },
        (_, i) => startPeriod + i,
      );
    }

    return Array.from(
      { length: 12 - reconcilingMonth + 1 },
      (_, i) => reconcilingMonth + i,
    );
  };

  const allowedPeriods = getAllowedPeriods();

  // If no periods are allowed, don't render anything
  if (allowedPeriods.length === 0) {
    return null;
  }

  // Create buttons only for allowed periods
  const renderPeriodButtons = () =>
    allowedPeriods.map((period) => {
      const isSelected = singlePeriod
        ? activePeriods.includes(period)
        : activePeriods.includes(period) ||
          period <= Math.max(...activePeriods, 0);

      return (
        <StyledButton
          id={period}
          key={`periodFilterButton-${period}`}
          size={size}
          fill={isSelected}
          onClick={(e) => handleFilterChange(e, periodType)}
          disabled={disabled}
        >
          {period}
        </StyledButton>
      );
    });

  const content = <ButtonGroup>{renderPeriodButtons()}</ButtonGroup>;

  return label ? (
    <SelectorHolder>
      <Label>{label}</Label>
      {content}
    </SelectorHolder>
  ) : (
    content
  );
}

ReconciliationPeriodFilterButtons.propTypes = {
  size: PropTypes.oneOf(['lg', 'large', 'sm', 'small', 'xs', 'xsmall']),
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  periodType: PropTypes.string,
  singlePeriod: PropTypes.bool,
  activePeriods: PropTypes.arrayOf(PropTypes.number),
  handleFilterChange: PropTypes.func,
  reconcilingFromDate: PropTypes.string,
  currentYear: PropTypes.number.isRequired,
};

ReconciliationPeriodFilterButtons.defaultProps = {
  size: undefined,
  label: undefined,
  loading: false,
  disabled: false,
  periodType: 'monthly',
  singlePeriod: false,
  activePeriods: [1],
  handleFilterChange: undefined,
  reconcilingFromDate: null,
};

export default ReconciliationPeriodFilterButtons;

const SelectorHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 38px; // Typical button height
`;

const StyledButton = styled(Button)``;
