import { t } from '@lingui/macro';

export const colorModes = () => [
  { id: 'AUTO', label: t`Auto` },
  { id: 'LIGHT', label: t`Light` },
  { id: 'DARK', label: t`Dark` },
];

export const depreciationFrequency = () => [
  { id: 'MONTHLY', label: t`Monthly` },
  { id: 'MONTHLY_FIRST', label: t`Monthly First` },
  { id: 'MONTHLY_LAST', label: t`Monthly Last` },
  { id: 'WEEKLY', label: t`Weekly` },
];

export const periodsToDepreciate = () => [
  { id: '36', label: t`3 years` },
  { id: '60', label: t`5 years` },
  { id: '120', label: t`10 years` },
  { id: '0', label: t`Custom range in months` },
];

export const depreciationEventTypes = () => [
  { id: 'REVALUATION', label: t`Revaluation` },
  { id: 'SALE', label: t`Sale` },
];

export const assetTypes = () => [
  { id: 'BALANCE', label: t`Balance` },
  { id: 'LINEAR', label: t`Linear` },
];

export const periodTypes = () => [
  { id: 'monthly', label: t`Monthly` },
  { id: 'biMonthly', label: t`Bi-Monthly` },
  { id: 'quarterly', label: t`Quarterly` },
  { id: 'tertiary', label: t`Tertiary` },
  { id: 'halfYearly', label: t`Half-Yearly` },
  { id: 'yearly', label: t`Yearly` },
];

export const glTypes = () => [
  { id: 'Result', label: t`Result` },
  { id: 'Balance', label: t`Balance` },
  { id: 'all', label: t`All` },
];

export const filterByGlTypes = () => [
  { id: 'Balance', label: t`Balance` },
  { id: 'Result', label: t`Result` },
];

export const recordFilterList = () => [
  { id: 'OPEN', label: t`Open` },
  { id: 'APPROVED', label: t`Approved` },
  { id: 'RECONCILED', label: t`Reconciled` },
  { id: 'PARTIAL_RECONCILED', label: t`Partially Reconciled` },
];

export const filterByApproveStatuses = () => [
  { id: 'approved', label: t`Approved` },
  { id: 'unApproved', label: t`Unapproved` },
];

export const filterHideZeroVATRows = () => [
  { id: 'hide', label: t`Hide` },
  { id: 'show', label: t`Show` },
];

export const filterHideZeroBalanceAccountRows = () => [
  { id: 'hide', label: t`Hide` },
  { id: 'show', label: t`Show` },
];

export const filterByControlledStatuses = () => [
  { id: 'controlled', label: t`Controlled` },
  { id: 'unControlled', label: t`Uncontrolled` },
];

export const filterByActivatedStatuses = () => [
  { id: 'activated', label: t`Activated` },
  { id: 'deactivated', label: t`Deactivated` },
];

export const filterByAvailabilityStatuses = () => [
  { id: 'available', label: t`Available` },
  { id: 'not_available', label: t`Not Available` },
];

export const filterByTemplateStatuses = () => [
  { id: 'BANK', label: t`Bank` },
  { id: 'GENERAL', label: t`General Ledger` },
  { id: 'PAYROLL', label: t`Payroll` },
  { id: 'VAT', label: t`VAT` },
  { id: 'SIMPLE_LEDGER', label: t`Simple Ledger` },
  { id: 'ASSET', label: t`Asset` },
];

export const filterByReconciledMatchedTransaction = () => [
  { id: 'MATCHED', label: t`Matched/Reconciled` },
  { id: 'UNMATCHED', label: t`Unmatched/Unreconciled` },
];

export const accountReconciliationCategories = () => [
  { id: ['Balance'], label: t`Balance`, type: 'visible' },
  { id: ['Result'], label: t`Result`, type: 'visible' },
  { id: ['Balance', 'Result'], label: t`All`, type: 'visible' },
];

export const viewCommentsOptions = () => [
  { id: 'withComments', label: t`With Comments` },
  { id: 'withoutComments', label: t`Without Comments` },
];

export const viewFinishedAccrualsOptions = () => [
  { id: 'withFinishedAccruals', label: t`With Finished Accruals` },
  { id: 'withoutFinishedAccruals', label: t`Without Finished Accruals` },
];

export const filterByRole = () => [
  { id: 'ADMIN', label: t`Admin` },
  { id: 'AUDITOR', label: t`Auditor` },
  { id: 'ACCOUNTANT', label: t`Accountant` },
  { id: 'EXTERNAL_ACCOUNTANT', label: t`External Accountant` },
  { id: 'SUPER_ADMIN', label: t`Super Administrator` },
  { id: 'SYSTEM_ADMIN', label: t`System Administrator` },
  { id: 'CHIEF_ACCOUNTANT', label: t`Chief Accountant` },
  { id: 'FINANCIAL_MANAGER', label: t`Financial Manager` },
  { id: 'PARTNER', label: t`Partner` },
];

export const filterByAccountingSystem = () => [
  { id: 'XERO', label: 'Xero' },
  { id: 'XLEDGER', label: 'Xledger' },
  { id: 'TRIPLETEX', label: 'Tripletex' },
  { id: 'EACCOUNTING', label: 'Visma eAccounting' },
  { id: 'POWER OFFICE', label: 'PowerOffice' },
  { id: '24SEVEN OFFICE', label: '24SevenOffice' },
  { id: 'Visma Business', label: 'Visma Business' },
  { id: 'On Property - SQL', label: 'On Property - SQL' },
  { id: 'Uni Economy', label: 'Uni Economy' },
  { id: 'Duett', label: 'Duett' },
  {
    id: 'Sparebank 1 Regnskap Nord-Norge',
    label: 'Sparebank 1 Regnskap Nord-Norge',
  },
  { id: 'DNB Regnskap', label: 'DNB Regnskap' },
  { id: 'Visma Business NXT', label: 'Visma Business NXT' },
  { id: 'File import', label: 'File import' },
  {
    id: 'Sparebank 1 Regnskap Østlandet',
    label: 'Sparebank 1 Regnskap Østlandet',
  },
  {
    id: 'Sparebank 1 Regnskap Nordvest',
    label: 'Sparebank 1 Regnskap Nordvest',
  },
  {
    id: 'Sparebank 1 Regnskap Gudbrandsdal',
    label: 'Sparebank 1 Regnskap Gudbrandsdal',
  },
  {
    id: 'Sparebank 1 Regnskap Søre Sunnmøre',
    label: 'Sparebank 1 Regnskap Søre Sunnmøre',
  },
  {
    id: 'Sparebank 1 Regnskap Lom og Skjåk',
    label: 'Sparebank 1 Regnskap Lom og Skjåk',
  },
  {
    id: 'Sparebank 1 Regnskap Nord-Norge',
    label: 'Sparebank 1 Regnskap Nord-Norge',
  },
  { id: 'Sparebank 1 Regnskap SR-Bank', label: 'Sparebank 1 Regnskap SR-Bank' },
  {
    id: 'Sparebank 1 Regnskap Hallingdal Valdres',
    label: 'Sparebank 1 Regnskap Hallingdal Valdres',
  },
  { id: 'SAF-T Import', label: 'SAF-T Import' },
  { id: 'UniMicro', label: 'UniMicro' },
  { id: 'Visma Business NXT', label: 'Visma Business NXT' },
  { id: 'UniMicro SMB', label: 'UniMicro SMB' },
  { id: 'SpareBank 1 Regnskap SørØst', label: 'SpareBank 1 Regnskap SørØst' },
];

export const transactionsOptions = () => [
  { id: 'withData', label: t`With Transactions` },
  { id: 'withoutData', label: t`Without Transactions` },
];

export const filterByPeriod = () => [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
  { id: 6, label: '6' },
  { id: 7, label: '7' },
  { id: 8, label: '8' },
  { id: 9, label: '9' },
  { id: 10, label: '10' },
  { id: 11, label: '11' },
  { id: 12, label: '12' },
];

export const textAvailability = () => [
  { id: 'withText', label: t`With Text` },
  { id: 'withoutText', label: t`Without Text` },
];

export const deviationAvailabilityOptions = () => [
  { id: 'withoutDeviation', label: t`Without Deviation` },
  { id: 'withDeviation', label: t`With Deviation` },
];

export const accumulatedOptions = () => [
  { id: 'accumulated', label: t`Yes` },
  { id: 'withoutAccumulated', label: t`No` },
];

export const enableAccountOptions = () => [
  { id: true, label: t`Enabled` },
  { id: false, label: t`Disabled` },
];

export const hiddenOptions = () => [
  { id: true, label: t`Hidden` },
  { id: false, label: t`Visible` },
];

export const requireDocumentsOptions = () => [
  { id: true, label: t`Required` },
  { id: false, label: t`Not required` },
];

export const mappedOptions = () => [
  { id: true, label: t`Mapped` },
  { id: false, label: t`Not mapped` },
];

export const months = () => [
  { id: 'JANUARY', label: t`January` },
  { id: 'FEBRUARY', label: t`February` },
  { id: 'MARCH', label: t`March` },
  { id: 'APRIL', label: t`April` },
  { id: 'MAY', label: t`May` },
  { id: 'JUNE', label: t`June` },
  { id: 'JULY', label: t`July` },
  { id: 'AUGUST', label: t`August` },
  { id: 'SEPTEMBER', label: t`September` },
  { id: 'OCTOBER', label: t`October` },
  { id: 'NOVEMBER', label: t`November` },
  { id: 'DECEMBER', label: t`December` },
];

export const filterByFileCategory = () => [
  { id: 'LINE', label: t`Line` },
  { id: 'ACCOUNT', label: t`Account` },
  { id: 'PAYROLL', label: t`Payroll` },
  { id: 'VAT', label: t`VAT` },
  { id: 'PAYROLL_SETTLEMENTS', label: t`Payroll Settlements` },
  { id: 'BANK_STATEMENT', label: t`Simple Ledger` },
  { id: 'TRANSACTION', label: t`Transaction` },
];

export const years = () => {
  const startedAt = 2018;
  const currentYear = new Date().getFullYear();
  const size = currentYear - startedAt + 3;

  return [...Array(size).keys()].map((item) => ({
    id: item + startedAt,
    label: (item + startedAt).toString(),
  }));
};

export const filterByRecordStatus = () => [
  { id: true, label: t`Activated` },
  { id: false, label: t`Deactivated` },
];

export const vatGroupTypeOptions = () => [
  {
    id: t({
      id: 'vatGroupTypeOptions.salesNorway',
      message: 'Sales of goods and services in Norway',
    }),
    label: t({
      id: 'vatGroupTypeOptions.salesNorway',
      message: 'Sales of goods and services in Norway',
    }),
    tableId: 'SALES_NORWAY',
  },
  {
    id: t({
      id: 'vatRateTypeOptions.salesExport',
      message: 'Sales of goods and services to other countries (exports)',
    }),
    label: t({
      id: 'vatRateTypeOptions.salesExport',
      message: 'Sales of goods and services to other countries (exports)',
    }),
    tableId: 'SALES_EXPORT',
  },
  {
    id: t({
      id: 'vatRateTypeOptions.purchasesNorway',
      message: 'Purchases of goods and services in Norway (deduction)',
    }),
    label: t({
      id: 'vatRateTypeOptions.purchasesNorway',
      message: 'Purchases of goods and services in Norway (deduction)',
    }),
    tableId: 'PURCHASES_NORWAY',
  },
  {
    id: t({
      id: 'vatRateTypeOptions.purchasesGoodsImport',
      message: 'Purchases of goods from abroad (import)',
    }),
    label: t({
      id: 'vatRateTypeOptions.purchasesGoodsImport',
      message: 'Purchases of goods from abroad (import)',
    }),
    tableId: 'PURCHASES_GOODS_IMPORT',
  },
  {
    id: t({
      id: 'vatRateTypeOptions.purchasesServicesImport',
      message: 'Purchases of services from abroad (import)',
    }),
    label: t({
      id: 'vatRateTypeOptions.purchasesServicesImport',
      message: 'Purchases of services from abroad (import)',
    }),
    tableId: 'PURCHASES_SERVICES_IMPORT',
  },
  {
    id: t({
      id: 'vatRateTypeOptions.fishEtc',
      message: 'Fish etc.',
    }),
    label: t({
      id: 'vatRateTypeOptions.fishEtc',
      message: 'Fish etc.',
    }),
    tableId: 'FISH_ETC',
  },
  {
    id: t({
      id: 'vatRateTypeOptions.emissionGold',
      message: 'Emission allowances and gold',
    }),
    label: t({
      id: 'vatRateTypeOptions.emissionGold',
      message: 'Emission allowances and gold',
    }),
    tableId: 'EMISSION_GOLD',
  },
];

export const vatDrilldownTypeOptions = () => [
  {
    id: 'ingoingTax',
    label: t({
      id: 'ingoingTax',
      message: 'Incoming tax',
    }),
  },
  {
    id: 'outgoingTax',
    label: t({
      id: 'outgoingTax',
      message: 'Outgoing tax',
    }),
  },
  {
    id: 'base',
    label: t({
      id: 'base',
      message: 'Base',
    }),
  },
];
