import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';

import { SelectCompany } from 'components';

import { fromResource } from 'store/selectors';
import { checkIfCompanyIsAvailableApi } from 'services/apihelpers';
import {
  resourceListReadRequest,
  companyReloadSuccess,
  updateUserSuccess,
  appReady,
} from 'store/actions';

function SelectCompanyContainer(props) {
  const { user } = props;
  const auth = useAuth();
  const [selectedCompany, setSelectedCompany] = useState();
  const availableCompanies = useSelector((state) =>
    fromResource.getList(state, checkIfCompanyIsAvailableApi),
  );
  const dispatch = useDispatch();

  const handleChangeCompany = (value) => {
    if (value.length > 0) {
      setSelectedCompany(value[0].id);
    } else {
      setSelectedCompany(null);
    }
  };

  const onLogout = () => auth.signoutRedirect();

  const onRedirect = async () => {
    const company = availableCompanies.find(
      (item) => item.uuid === selectedCompany,
    );

    if (!isEmpty(company)) {
      // Change currentCompanyId for user
      const updatedUser = {
        ...user,
        currentCompanyId: company.companyId,
      };
      await dispatch(appReady(false));
      await dispatch(updateUserSuccess(updatedUser));
      await dispatch(companyReloadSuccess(company));
    }
  };

  const fetchAvailableCompanies = async () => {
    await dispatch(resourceListReadRequest(checkIfCompanyIsAvailableApi));
  };

  useEffect(() => {
    fetchAvailableCompanies();
  }, []);

  return (
    <SelectCompany
      {...props}
      logout={onLogout}
      onRedirect={onRedirect}
      availableCompanies={availableCompanies}
      selectedCompany={selectedCompany}
      handleChangeCompany={handleChangeCompany}
    />
  );
}

export default SelectCompanyContainer;
