import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Switch,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { fromAuth, fromResource } from 'store/selectors';
import { setUserRoleProperties } from 'services/helpers';
import { checkIsUserFromOrg } from 'services/apihelpers';
import FourOhFourPage from 'components/pages/FourOhFourPage';

export function RenderRoutes({ routes, props }) {
  const user = useSelector(fromAuth.getUser);
  const isUserFromOrg = useSelector((state) =>
    fromResource.getList(state, checkIsUserFromOrg),
  ).status;

  const history = useHistory();
  const location = useLocation();
  const { isAdmin } = setUserRoleProperties(user, isUserFromOrg);

  return (
    <Switch>
      {routes
        .filter((route) => route !== undefined)
        .map((route) => {
          const { path, exact, component: Component, title } = route;
          const obj = matchPath(location.pathname, route);

          if (route.adminOnly && !isAdmin && obj && obj.isExact) {
            history.push('/');
            return false;
          }

          return (
            <Route
              key={`route_${path}`}
              path={path}
              exact={exact}
              render={(routeProps) => (
                <Component
                  {...{
                    ...props,
                    ...user,
                    ...routeProps,
                    title,
                    route,
                  }}
                />
              )}
            />
          );
        })}
      <Route path="*" component={FourOhFourPage} />
    </Switch>
  );
}

export default RenderRoutes;
