import React, { useMemo } from 'react';

import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings } from 'components';

import { organizationTableGrid } from 'services/tableshapes';
import { organizationSettingsFilters } from 'services/tableFilterShapes';

function OrganizationSettings(props) {
  const {
    data,
    title,
    search,
    loading,
    handleAction,
    actionSubmitting,
    canEditOrganization,
  } = props;
  const sortBy = useMemo(
    () => [
      {
        id: 'organizationName',
        desc: false,
      },
    ],
    [],
  );

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-organizationSettings">
      <CardPDFTitle title={title} />
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          columns: organizationTableGrid({ ...search, canEditOrganization }),
          tableId: 'organizationSettings',
          loadingId: 'organizationName',
          reportMode: search.reportMode,
          filterConfigs: organizationSettingsFilters(),
          headerConfigs: {
            exportFileName: title,
          },
          initialState: {
            sortBy,
          },
        }}
      />
    </Card>
  );
}

export default OrganizationSettings;
