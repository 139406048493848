import React, { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { VATNewerDetailed } from 'components';

import { resourceListReadRequest } from 'store/actions';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import { getVatDetailsNewer } from 'services/apihelpers';

const getSearch = (user, location) => {
  const search = location ? queryString.parse(location.search) : {};

  return {
    type: search.type,
    term: search.term,
    rowsType: search.rowsType || 'all',
    frequency: search.frequency || 'BIMONTHLY',
    accumulated: search.accumulated || false,
    reportType: search.reportType,
  };
};

function VATReconciliationDetailedTableContainer() {
  const { id } = useParams();
  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);
  const dispatch = useDispatch();
  const location = useLocation();
  const search = useMemo(() => getSearch(user, location), [user, location]);
  const { rowsType, term, frequency, reportType } = search || {};
  const newerVATData = useSelector((state) =>
    fromResource.getList(state, getVatDetailsNewer),
  );
  const newerVATLoading = useSelector((state) =>
    pending(state, `${getVatDetailsNewer}ListRead`),
  );

  const fetchNewerData = async () => {
    try {
      await dispatch(
        resourceListReadRequest(getVatDetailsNewer, {
          year: +company.currentAccountingYear,
          companyId: company.currentCompanySID,
        }),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  useEffect(() => {
    if (_isEmpty(newerVATData)) {
      fetchNewerData();
    }
  }, [newerVATData]);

  return (
    <VATNewerDetailed
      {...{
        id,
        term,
        frequency,
        type: rowsType,
        data: newerVATData,
        loading: newerVATLoading,
        reportType,
      }}
    />
  );
}

export default VATReconciliationDetailedTableContainer;
