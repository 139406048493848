import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import Snowfall from 'react-snowfall';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { ChristmasModeContext } from 'services/context/ChristmasMode';
import ErrorBoundary from 'containers/ErrorBoundary';

/**
 * PageTemplate component
 */
function PageTemplate(props) {
  const {
    location,
    fullPage,
    pageType,
    sidebar,
    filterColor,
    navbar,
    children,
    footer,
    image,
    background,
    helmetTitle,
  } = props;
  const christmasMode = useContext(ChristmasModeContext);

  // Custom Pagetemplate contextual wrapper classes
  const wrapperClasses = classNames('wrapper', {
    'wrapper-full-page': fullPage,
  });
  // Custom Pagetemplate contextual full-page classes
  const fullPageClasses = classNames('full-page', {
    'login-page': pageType === 'login-page',
    'register-page': pageType === 'register-page',
    'lock-page': pageType === 'lock-page',
  });

  const helmet = (
    <Helmet>
      <title>{helmetTitle}</title>
    </Helmet>
  );

  const FullPageWrapper = (
    <div className={wrapperClasses}>
      {helmet}
      <div
        className={fullPageClasses}
        data-color={filterColor}
        data-image={image}
      >
        <ContentStyles pageType={pageType} className="content">
          {children}
        </ContentStyles>
        {footer}
        {background}
      </div>
    </div>
  );

  const NotFullPageWrapper = (
    <div className={wrapperClasses}>
      {helmet}
      {sidebar}
      <div className="main-panel" id="scrollingContent">
        {navbar}
        {location.pathname && (
          <div className="content">
            <div className="container-fluid">{children}</div>
          </div>
        )}
        {!location.pathname && (
          <div className="content" style={{ marginTop: 75 }}>
            <div className="container-fluid">{children}</div>
          </div>
        )}
        {footer}
        {/* TODO: create statuful close-layer component
          {document.documentElement.classList.contains('nav-open')
          && <div className="close-layer" /> */}
      </div>
      {christmasMode.isOn && (
        <Snowfall style={{ zIndex: 100 }} snowflakeCount={50} />
      )}
    </div>
  );

  if (fullPage) {
    return (
      <ErrorBoundary>
        <div>
          {helmet}
          {navbar}
          {fullPage ? FullPageWrapper : NotFullPageWrapper}
        </div>
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      {fullPage ? FullPageWrapper : NotFullPageWrapper}
    </ErrorBoundary>
  );
}

PageTemplate.propTypes = {
  fullPage: PropTypes.bool,
  pageType: PropTypes.string,
  location: PropTypes.shape({}),
  navbar: PropTypes.node,
  sidebar: PropTypes.node,
  filterColor: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  background: PropTypes.node,
  image: PropTypes.string,
  helmetTitle: PropTypes.string,
};
PageTemplate.defaultProps = {
  location: {},
  helmetTitle: t`Accountflow`,
  fullPage: false,
  pageType: undefined,
  navbar: undefined,
  sidebar: undefined,
  filterColor: undefined,
  children: undefined,
  footer: undefined,
  background: undefined,
  image: undefined,
};

const ContentStyles = styled.div`
  ${(props) => props.pageType === 'sanity' && 'height: 100vh !important'}
  background: ${(props) => props.theme.colorsThemed.background.static}
`;

export default PageTemplate;
