import React, { useState, useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { t } from '@lingui/macro';
import Lottie from 'react-lottie';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';
import { List, AutoSizer } from 'react-virtualized';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { usePopper } from 'react-popper';

import { Label, Spinner, HelpBlock } from 'components';

import { useOnClickOutside } from 'services/hooks';

import checkBoxAnim from 'assets/animations/checkbox.json';

const getRowHeight = (small) => (small ? 30 : 40);

function CustomMultiSelectInput(props) {
  const {
    id,
    grid,
    small,
    label,
    single,
    onBlur,
    options,
    loading,
    selected,
    required,
    requiredNotEmpty,
    onSelect,
    maxHeight,
    autoFocus,
    markActive,
    disableSort,
    placeholder,
    clearSearch,
    setClearSearch,
    modal,
  } = props;

  const rowHeight = useMemo(() => getRowHeight(small), [small]);

  /* References */
  const [containerElRef, setContainerElRef] = useState(null);
  const inputElRef = useRef();
  const [popperElRef, setPopperElRef] = useState(null);

  /* Local State */
  const [search, setSearch] = useState('');
  const [isFocused, setFocus] = useState(false);
  const [inverted, setInverted] = useState(false);

  /* Popper */
  const flipModifier = useMemo(
    () => ({
      name: 'flip',
      options: {
        fallbackPlacements: ['top'],
      },
    }),
    [],
  );
  const { styles, attributes, state } = usePopper(containerElRef, popperElRef, {
    placement: 'bottom-start',
    modifiers: [flipModifier],
  });

  useEffect(() => {
    if (state?.placement) {
      setInverted(state.placement.includes('top'));
    }
  }, [state]);

  const handleBlur = () => {
    setFocus(false);
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleOptionClick = (option, checked) => {
    let newSelectedValues = [...selected];

    if (single) {
      if (checked && !required) {
        newSelectedValues = [];
      } else {
        newSelectedValues = [option];
      }
      handleBlur();
    } else if (checked) {
      newSelectedValues = newSelectedValues.filter((el) => el.id !== option.id);
    } else {
      newSelectedValues.push(option);
    }

    onSelect(newSelectedValues, id);
  };
  const filterOption = (option) =>
    option.label?.toLowerCase()?.includes(search?.toLowerCase());
  const handleToggleFocused = () => setFocus(!isFocused);
  const sortOptions = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }

    return 0;
  };
  const filteredOptions = options.filter(filterOption);
  const totalHeight = filteredOptions.length * rowHeight;
  const labelStyles = {};
  const height = maxHeight || (totalHeight > 400 ? 400 : totalHeight);

  if (markActive) {
    if (!selected?.length) {
      labelStyles.color = '#66615b';
    } else {
      labelStyles.color = 'white';
    }
  }
  const selectedLabel = selected.map((el) => el.label).join(', ');
  const sortedOptions = disableSort
    ? filteredOptions
    : filteredOptions.sort(sortOptions);

  const renderOption = ({ index, key, style }) => {
    const option = sortedOptions[index];
    const checked = !!selected.find((el) => el.id === option.id);

    return (
      <div key={key} style={style}>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip
              id="tooltip"
              style={{
                wordBreak: 'break-all',
              }}
            >
              {option.label}
            </Tooltip>
          }
        >
          <DropDownItem
            onClick={() =>
              !option.disabled && handleOptionClick(option, checked)
            }
            disabled={option.disabled}
            rowHeight={rowHeight}
          >
            <LabelHolder checked={checked} disabled={option.disabled}>
              {option.label}
            </LabelHolder>
            {checked && (
              <Lottie
                speed={3}
                width="20px"
                height="20px"
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: checkBoxAnim,
                }}
              />
            )}
          </DropDownItem>
        </OverlayTrigger>
      </div>
    );
  };

  useEffect(() => {
    if (clearSearch) {
      setSearch('');
      if (inputElRef.current) inputElRef.current.value = '';
      setClearSearch(false);
    }
  }, [clearSearch]);

  useEffect(() => {
    let timeout;

    if (isFocused) {
      timeout = setTimeout(() => {
        inputElRef.current?.focus();
      }, 100);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isFocused]);

  useEffect(() => {
    let timeout;

    if (autoFocus) {
      timeout = setTimeout(() => {
        setFocus(true);
      }, 100);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [autoFocus]);

  useOnClickOutside(popperElRef, handleBlur);

  const dropdown = modal ? (
    <DropDownHolder
      height={height + 40}
      width={containerElRef?.offsetWidth}
      reverted={inverted}
      ref={setPopperElRef}
      style={styles.popper}
      {...attributes.popper}
    >
      {!inverted && (
        <SearchInput
          tabIndex={0}
          autoFocus
          ref={inputElRef}
          onChange={handleSearchChange}
          placeholder={t`Type to search`}
          autoComplete="search"
        />
      )}
      <DropDownContent rowHeight={rowHeight}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              width={width}
              height={height}
              rowCount={sortedOptions.length}
              rowHeight={rowHeight}
              rowRenderer={renderOption}
            />
          )}
        </AutoSizer>
      </DropDownContent>
      {inverted && (
        <SearchInput
          autoFocus
          ref={inputElRef}
          onChange={handleSearchChange}
          placeholder={t`Type to search`}
          autoComplete="search"
        />
      )}
    </DropDownHolder>
  ) : (
    createPortal(
      <DropDownHolder
        height={height + 40}
        width={containerElRef?.offsetWidth}
        reverted={inverted}
        ref={setPopperElRef}
        style={styles.popper}
        {...attributes.popper}
      >
        {!inverted && (
          <SearchInput
            tabIndex={0}
            autoFocus
            ref={inputElRef}
            onChange={handleSearchChange}
            placeholder={t`Type to search`}
            autoComplete="search"
          />
        )}
        <DropDownContent rowHeight={rowHeight}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                width={width}
                height={height}
                rowCount={sortedOptions.length}
                rowHeight={rowHeight}
                rowRenderer={renderOption}
              />
            )}
          </AutoSizer>
        </DropDownContent>
        {inverted && (
          <SearchInput
            autoFocus
            ref={inputElRef}
            onChange={handleSearchChange}
            placeholder={t`Type to search`}
            autoComplete="search"
          />
        )}
      </DropDownHolder>,
      document.querySelector('#popper'),
    )
  );

  return (
    <ContentWrapper>
      {!!label && (
        <Label style={labelStyles} htmlFor={id}>
          {label}
        </Label>
      )}
      <InputHolder ref={setContainerElRef}>
        <Placeholder
          grid={grid}
          onClick={handleToggleFocused}
          selected={!!selectedLabel}
          data-testid={`placeholder-${id}`}
        >
          {selectedLabel || placeholder}
        </Placeholder>
        <ArrowHolder focused={isFocused} onClick={handleToggleFocused}>
          <FiChevronDown />
        </ArrowHolder>
        {loading && (
          <SpinnerHolder>
            <Spinner size="md" />
          </SpinnerHolder>
        )}
        {isFocused && dropdown}
      </InputHolder>
      {requiredNotEmpty && !selected.length && (
        <HelpBlock
          style={{ color: 'red' }}
        >{t`Please select at least one option`}</HelpBlock>
      )}
    </ContentWrapper>
  );
}

CustomMultiSelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  single: PropTypes.bool,
  onBlur: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoHeight: PropTypes.bool,
  markActive: PropTypes.bool,
  placeholder: PropTypes.string,
  disableSort: PropTypes.bool,
  clearSearch: PropTypes.bool,
  setClearSearch: PropTypes.func,
};

CustomMultiSelectInput.defaultProps = {
  id: '',
  label: '',
  onBlur: () => {},
  single: false,
  loading: false,
  options: [],
  selected: [],
  required: false,
  autoFocus: false,
  autoHeight: false,
  markActive: false,
  placeholder: '',
  disableSort: false,
  clearSearch: false,
  setClearSearch: () => {},
};

export default CustomMultiSelectInput;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Placeholder = styled.div`
  width: 100%;
  color: rgba(102, 97, 91, 1);
  cursor: pointer;
  height: ${({ grid }) => (grid === 'sm' ? '20px' : '30px')};
  padding: ${({ grid }) => (grid === 'sm' ? '1px 15px' : '5px 15px')};
  overflow: hidden;
  font-size: 12px;
  max-width: 100%;
  white-space: nowrap;
  line-height: 1.5;
  border-radius: 3px;
  text-overflow: ellipsis;
  background-color: #f3f2ee;
  ${(props) => (props.selected ? '' : 'color: rgba(102, 97, 91, 0.4);')}
`;

const LabelHolder = styled.div`
  width: calc(100% - 20px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props) => (props.disabled ? 'opacity: 0.5;' : '')}
`;

const InputHolder = styled.div`
  cursor: pointer;
  position: relative;
`;

const DropDownHolder = styled.div`
  z-index: 9999;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  box-shadow: 0 5px 30px -13px rgba(40, 40, 40, 0.4);
  border-radius: 11px;
  background-color: white;
`;

const DropDownItem = styled.div`
  color: #66615b;
  height: ${({ rowHeight }) => `${rowHeight}px`};
  cursor: pointer;
  display: flex;
  padding: 15px;
  font-size: 13.5px;
  align-items: center;
  justify-content: space-between;

  &:hover {
    ${(props) =>
      props.disabled
        ? `
      cursor: not-allowed;
        `
        : `
      color: white;
      background-color: lightgrey;
    `}
  }
`;

const DropDownContent = styled.div`
  height: calc(100% - 40px);
  overflow: hidden;
`;

const SearchInput = styled.input`
  width: 100%;
  color: #66615b;
  height: 40px;
  padding: 0 10px;
  font-size: 13.5px;

  &::placeholder {
    opacity: 0.4;
  }
`;

const SpinnerHolder = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowHolder = styled.div`
  top: 50%;
  right: 5px;
  display: flex;
  position: absolute;
  transform: translate(0, -50%);
  align-items: center;
  justify-content: center;

  svg {
    font-size: 13px;
    transition: all ease 0.5s;
    ${(props) => (props.focused ? 'transform: rotate(180deg);' : '')}
  }
`;
