import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { Modal, ButtonGroup } from 'react-bootstrap';
import { useForm } from 'react-form';

import { isValidVismaNxtCompanyId } from 'services/validation';

import {
  Toggle,
  ReactFormField,
  Button,
  Heading,
  Spinner,
  ButtonIcon,
} from 'components';

function BalanceDataModal(props) {
  const {
    roleType,
    closeModal,
    handleChange,
    handleSubmit,
    altinnUsername,
    altinnPassword,
    organizationCode,
    organizationName,
    isMainAltinnData,
    handleToggleChange,
    isUserGroupRequired,
    isCompanyGroupRequired,
    handleIsMainAltinnDataChange,
    vismanxtCompanyId,
  } = props;
  // eslint-disable-next-line max-len
  const checkLabel = t`Add Altinn integration to all clients in organization`;

  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = useForm({
    defaultValues: {
      organizationName,
      organizationCode,
      vismanxtCompanyId,
      isMainAltinnData,
      altinnUsername,
      isUserGroupRequired,
      isCompanyGroupRequired,
      altinnPassword,
    },
    onSubmit: handleSubmit,
    onChange: handleChange,
  });

  return (
    <Modal show onEscapeKeyDown={closeModal}>
      <Modal.Header>
        <Heading level={3}>
          <Trans>Edit Organization Admin Modal</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Form onChange={handleChange}>
          <ReactFormField
            noBorder
            type="text"
            size="small"
            name="organizationName"
            label={t`Organization Name`}
            defaultValue={organizationName}
          />
          <ReactFormField
            noBorder
            type="text"
            size="small"
            name="organizationCode"
            label={t`Organization Code`}
            defaultValue={organizationCode}
          />
          {!roleType.isReadOnly && (
            <>
              <ToggleHolder>
                <Toggle
                  id="isUserGroupRequired"
                  value={isUserGroupRequired}
                  label={t`User Group required`}
                  onClick={handleToggleChange}
                />
              </ToggleHolder>
              <ToggleHolder>
                <Toggle
                  id="isCompanyGroupRequired"
                  value={isCompanyGroupRequired}
                  label={t`Company Group required`}
                  onClick={handleToggleChange}
                />
              </ToggleHolder>
            </>
          )}
          <hr />
          <Heading level={4}>
            <Trans> Altinn Integration </Trans>
          </Heading>
          <ReactFormField
            noBorder
            size="small"
            name="altinnUsername"
            type="text"
            label={t`Altinn System ID`}
            placeholder={t`Please Enter Altinn System ID`}
            defaultValue={altinnUsername}
            autoComplete="off"
          />
          <ReactFormField
            noBorder
            size="small"
            name="altinnPassword"
            type="password"
            label={t`Altinn System Password`}
            placeholder={t`Please Enter Altinn System Password`}
            defaultValue={altinnPassword}
            autoComplete="off"
          />
          <hr />
          <Heading level={4}>
            <Trans> Visma Business NXT Integration </Trans>
          </Heading>
          <ReactFormField
            noBorder
            size="small"
            name="vismanxtCompanyId"
            type="text"
            label={t`Visma Customer ID`}
            placeholder={t`Please Enter Visma Customer ID`}
            defaultValue={vismanxtCompanyId}
            autoComplete="off"
            validate={[isValidVismaNxtCompanyId]}
          />{' '}
          <CheckHolder>
            <ReactFormField
              horizontal
              id="isMainAltinnData"
              type="checkbox"
              name="isMainAltinnData"
              label={checkLabel}
              value={isMainAltinnData}
              onChange={handleIsMainAltinnDataChange}
            />
          </CheckHolder>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            fill
            kind="success"
            magnify
            type="submit"
            onClick={handleSubmit}
            disabled={!canSubmit}
          >
            <ButtonIcon as={HiCheck} />{' '}
            {isSubmitting ? <Spinner type="white" size="md" /> : t`Submit`}
          </Button>
          <Button fill kind="danger" magnify onClick={closeModal}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

BalanceDataModal.propTypes = {
  payload: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default BalanceDataModal;

const CheckHolder = styled.div`
  margin-top: 10px;
`;

const ToggleHolder = styled.div`
  margin-right: 50px;
`;
