import React, { useState, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import { MdArrowDropDown } from '@react-icons/all-files/md/MdArrowDropDown';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import { ButtonGroup } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

import { Button, Spinner, ReportSettings, WarningBanner } from 'components';

import { useOnClickOutside } from 'services/hooks';
import ShowReportVersionsModal from 'containers/ShowReportVersionsModal';
import useLocalSettings from 'hooks/useLocalSettings';

function ButtonContainer(props) {
  const { variant, text, toggleOpen, disabled, pdfGenerating, isOpened } =
    props;

  switch (variant) {
    case 'icon': {
      return (
        <IconButtonHolder
          magnify
          size="sm"
          onClick={toggleOpen}
          disabled={disabled || pdfGenerating}
        >
          {pdfGenerating ? <Spinner button /> : <FaFilePdf />}
        </IconButtonHolder>
      );
    }
    default: {
      return (
        <ButtonHolder
          magnify
          size="sm"
          onClick={toggleOpen}
          disabled={disabled || pdfGenerating}
        >
          {pdfGenerating ? (
            <Spinner button />
          ) : (
            text || <Trans>Generate PDF Report</Trans>
          )}
          <ArrowIcon rotate={isOpened} />
        </ButtonHolder>
      );
    }
  }
}

function GenerateReportButton(props) {
  const {
    variant,
    text,
    filters,
    options,
    disabled,
    withVersions,
    versionsMeta,
    currentVersion,
    setFilters,
    generatePDF,
    disableGenerate,
    downloadLast,
    pdfGenerating,
    resetFilters,
    period,
    bimonthly,
    saveReport,
    skipDownload,
    noGenerateButton,
  } = props;

  const { showHelp } = useLocalSettings();

  const [isOpened, setOpened] = useState(false);
  const [showVersionsModal, setShowVersionsModal] = useState(false);

  const toggleOpen = () => {
    setOpened((state) => !state);
  };

  if (options) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const containerRef = useRef(null);

    const clearState = () => {
      if (isOpened) {
        setOpened(false);
        resetFilters?.();
      }
    };

    const handleClick = async () => {
      try {
        await generatePDF({ skipDownload, saveReport });
        toast.success(t`Report generated successfully`);
      } catch (e) {
        toast.error(t`Error generating report: ${e.message}`);
      }
      clearState();
    };

    const handleDownloadPackage = async () => {
      await downloadLast();
      clearState();
    };

    const latestReport =
      currentVersion?.[versionsMeta?.reportType]?.[0] || null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useOnClickOutside(containerRef, clearState);

    return (
      <Container ref={containerRef}>
        <ButtonContainer
          variant={variant}
          text={text}
          toggleOpen={toggleOpen}
          disabled={disabled}
          pdfGenerating={pdfGenerating}
          isOpened={isOpened}
        />
        {isOpened && (
          <DropdownContent>
            {latestReport && (
              <VersionHolder>
                <div>
                  <strong>
                    {t`Current version`}: {latestReport.version}
                  </strong>
                </div>
                <div>
                  <strong>
                    {t`By`}: {latestReport.createdBy}
                  </strong>
                </div>
              </VersionHolder>
            )}
            <ReportSettings
              type={options}
              state={filters}
              onChange={setFilters}
            />
            {showHelp && (
              <WarningBanner
                // eslint-disable-next-line max-len
                message={t`In some situations, the report may not be generated correctly.`}
                link="https://help.accountflow.com/nb/articles/10568668-hvorfor-feiler-rapportgenereringen"
                linkTitle={t`Read more here (in Norwegian)`}
                isExternalLink
              />
            )}
            {!noGenerateButton ? (
              <ButtonGroup bsSize="small">
                <Button
                  fill
                  magnify
                  kind="success"
                  onClick={handleClick}
                  disabled={pdfGenerating || disableGenerate}
                >
                  {pdfGenerating ? (
                    <Spinner size="md" />
                  ) : (
                    <Trans>Generate PDF Report</Trans>
                  )}
                </Button>
              </ButtonGroup>
            ) : null}
            {withVersions && (
              <>
                {/* We have 2 ways to know if this button should be availbale */}
                {/* 1 - Sebastian created endpoint to fetch last report so we can fetch it in the place you use this component */}
                {/* 2 - to not do extra calls just always keep it available and fetch last report only on click (if */}
                {/* no available reports just show tooltip with that) */}
                <ButtonGroup bsSize="small">
                  <Button
                    fill
                    magnify
                    kind="info"
                    onClick={handleDownloadPackage}
                    disabled={
                      pdfGenerating ||
                      (!latestReport &&
                        versionsMeta.reportType.includes('PAYROLL'))
                    }
                  >
                    {pdfGenerating ? (
                      <Spinner size="md" />
                    ) : (
                      <Trans>Download last report</Trans>
                    )}
                  </Button>
                </ButtonGroup>
                <JustifyCenter>
                  <Button
                    simple
                    disabled={pdfGenerating}
                    onClick={() => setShowVersionsModal(true)}
                  >
                    <Trans>Show report versions</Trans>
                  </Button>
                </JustifyCenter>
              </>
            )}
          </DropdownContent>
        )}
        {showVersionsModal && (
          <ShowReportVersionsModal
            {...versionsMeta}
            closeModal={() => setShowVersionsModal(false)}
            period={period}
            bimonthly={bimonthly}
          />
        )}
      </Container>
    );
  }

  return (
    <Button
      size="sm"
      disabled={disabled || pdfGenerating}
      onClick={async () => {
        try {
          await generatePDF({ skipDownload, saveReport });
          toast.success(t`Report generated successfully`);
        } catch (e) {
          toast.error(t`Error generating report: ${e.message}`);
        }
      }}
      onMouseEnter={(e) => e.stopPropagation()}
      data-testid="generate-pdf-btn"
    >
      {pdfGenerating ? <Spinner button /> : <Trans>Generate PDF Report</Trans>}
    </Button>
  );
}

export default GenerateReportButton;

const ButtonHolder = styled(Button)`
  display: flex;
  align-items: center;

  &:hover {
    svg {
      fill: white;
    }
  }
`;

const IconButtonHolder = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;

  &:hover {
    svg {
      fill: white;
    }
  }
`;

const JustifyCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`;

const DropdownContent = styled.div`
  padding: 20px;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  width: fit-content;
  z-index: 999;
  background: white;
  border-radius: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  transition: height 0.3s;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  animation: height 0.3s ease-in;

  @keyframes height {
    0% {
      max-height: 0;
      padding: 0 20px;
    }
    50% {
      padding: 20px;
    }
    100% {
      max-height: 300px;
    }
  }
`;

const Container = styled.div`
  position: relative;

  .btn-group {
    margin-left: 0 !important;
    margin-top: 5px;
    width: 100%;

    .btn {
      width: 100%;
    }
  }
`;

const VersionHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

const ArrowIcon = styled(MdArrowDropDown)`
  color: white;
  margin-left: 5px;
  font-size: 20px;
  fill: darkgray;

  ${({ rotate }) => rotate && `transform: rotate(180deg)`}
`;
