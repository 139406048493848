import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { t } from '@lingui/macro';
import { sort } from 'fast-sort';

import { VATManualReconcileTable } from 'components';

import {
  resourceCreateRequest,
  resourceListReadRequest,
  resourceDeleteRequest,
} from 'store/actions';
import { fromAuth, fromCompany, fromResource } from 'store/selectors';
import {
  getVATReconciliationsApi,
  getVATCodesNew,
  getVATDeviationsApi,
  autoReconcileSplitLinesReverseChargeApi,
  autoReconcileSplitLinesIngoingApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

import VATManualReconcileModal from './VATManualReconcileModal';

const getRowColor = (row) => {
  switch (row.reconciliationStatus) {
    case 'MATCHED': {
      return 'info';
    }
    case 'COMMITTED': {
      return 'warning';
    }
    default:
      return null;
  }
};

function VATManualReconcileTableContainer(props) {
  const { roleType } = props;
  const { id } = useParams();
  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);
  const dispatch = useDispatch();
  const data = useSelector((state) =>
    fromResource.getList(state, getVATReconciliationsApi),
  );
  const vatCodes = useSelector((state) =>
    fromResource.getList(state, getVATCodesNew),
  );
  const loading = useSelector(
    (state) =>
      pending(state, `${getVATReconciliationsApi}ListRead`) ||
      pending(state, `${getVATCodesNew}ListRead`),
  );
  const [showModal, toggleModal] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [reconciliations, setReconciliations] = React.useState([]);
  const [reconciliationDate, setReconciliationDate] = React.useState('');
  const [vatCodeRuleSid, setVatCodeRuleSid] = React.useState('');
  const [actionSubmitting, setActionSubmitting] = useState({});

  const generateRandomId = () =>
    Math.floor(Math.random() * Date.now()).toString(16);

  const transformedData = React.useMemo(() => {
    if (!Array.isArray(data)) return [];
    return data.map((row) => ({
      ...row,
      internalUID: generateRandomId(),
    }));
  }, [data]);

  const fetchReconcileData = async () => {
    try {
      await dispatch(
        resourceListReadRequest(getVATReconciliationsApi, {
          year: +company.currentAccountingYear,
          companyId: company.currentCompanySID,
        }),
      );
      await dispatch(
        resourceListReadRequest(getVATDeviationsApi, {
          companyId: company.currentCompanySID,
          year: +company.currentAccountingYear,
        }),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const fetchVatCodes = async () => {
    try {
      await dispatch(
        resourceListReadRequest(getVATCodesNew, {
          companySid: company.currentCompanySID,
        }),
      );
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const getVATCodeName = (_vatCodeRuleSid) =>
    vatCodes.find((row) => row.vatCodeRuleSid === _vatCodeRuleSid).code || '';

  const handleReconcile = async () => {
    const query = {
      creator: user.uuid,
      reconciliations: reconciliations.map((row) => ({
        reconciliationDate: row.reconciliationDate,
        vatCodeRuleSid: row.vatCodeRuleSid,
        lines: row.lines.map((line) => ({
          lineSid: line.lineId,
          splitAmount: line.splitAmount || null,
          type: line.type,
        })),
      })),
    };
    await dispatch(resourceCreateRequest(getVATReconciliationsApi, query));
    await fetchReconcileData();
    setReconciliations([]);
  };

  const handleModal = (transactionId) => {
    const _rows = transformedData
      .filter((_transaction) => _transaction.transactionId === transactionId)
      .map((row) => ({
        ...row,
        splitAmount: undefined,
      }));
    const fullTransaction = {
      transactionId,
      lines: sort(_rows).asc([(row) => row.accountId, (row) => row.vatCode]),
      date:
        transformedData.find(
          (_transaction) => _transaction.transactionId === transactionId,
        )?.date || '',
    };
    setReconciliationDate(fullTransaction.date);
    setTransaction(fullTransaction);
    toggleModal(true);
  };

  const handleUnReconcile = async (vatReconciliationSid) => {
    try {
      await dispatch(
        resourceDeleteRequest(
          `${getVATReconciliationsApi}?ids=${vatReconciliationSid}`,
        ),
      );
      await fetchReconcileData();
      toast.success(t`Reconciliation deleted successfully`);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'openModal': {
          await handleModal(item.transactionId);
          break;
        }

        case 'unReconcile': {
          await handleUnReconcile(item.vatReconciliationSid);
          break;
        }

        case 'manualReconcile': {
          _setActionSubmitting(action, true);
          await handleReconcile(item);
          _setActionSubmitting(action, false);
          break;
        }

        case 'storeReconciliation': {
          setReconciliations([
            ...reconciliations,
            {
              reconciliationId: generateRandomId(),
              vatCodeRuleSid: +vatCodeRuleSid,
              vatCodeRuleName: getVATCodeName(+vatCodeRuleSid),
              reconciliationDate,
              lines: item,
            },
          ]);
          const updatedLines = transaction.lines.map((row) => {
            // Ensure item is always an array
            const itemsArray = Array.isArray(item) ? item : [item];

            let matchedItem;

            // Find the item with the same internalUID
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < itemsArray.length; i++) {
              if (itemsArray[i].internalUID === row.internalUID) {
                matchedItem = itemsArray[i];
                break;
              }
            }

            // If a match is found, update the reconciliationStatus
            if (matchedItem) {
              return { ...row, reconciliationStatus: 'COMMITTED' };
            }

            return row;
          });

          setTransaction((prevState) => ({
            ...prevState,
            lines: updatedLines,
          }));
          setSelectedRows([]);
          break;
        }

        case 'deleteCommittedReconciliation': {
          const reconciliation = reconciliations.find(
            (row) => row.reconciliationId === item.reconciliationId,
          );

          const reconciliationLinesUID = reconciliation.lines.map(
            (row) => row.internalUID,
          );
          const updatedLines = transaction.lines.map((row) => {
            if (reconciliationLinesUID.includes(row.internalUID)) {
              return { ...row, reconciliationStatus: 'UNMATCHED' };
            }
            return row;
          });
          const updatedReconciliations = reconciliations.filter(
            (row) => row.reconciliationId !== item.reconciliationId,
          );
          setReconciliations(updatedReconciliations);
          setTransaction((prevState) => ({
            ...prevState,
            lines: updatedLines,
          }));
          setSelectedRows([]);
          break;
        }

        case 'saveReconciliation': {
          setReconciliations([...reconciliations, item]);
          // set lines selected to reconciled
          const updatedLines = transaction.lines.map((row) => {
            if (row.internalUID === item.internalUID) {
              return { ...row, reconciliationStatus: 'MATCHED' };
            }
            return row;
          });
          setTransaction((prevState) => ({
            ...prevState,
            lines: updatedLines,
          }));
          setSelectedRows([]);
          break;
        }

        case 'splitLine': {
          const targetIndex = transaction.lines.findIndex(
            (row) => row.internalUID === item.internalUID,
          );

          if (targetIndex === -1) return;

          const targetRow = transaction.lines[targetIndex];

          const currentAmount = targetRow.splitAmount || targetRow.amount;
          const newSplitAmount = currentAmount / 2;

          const newSplitItem = {
            ...item,
            splitAmount: newSplitAmount,
            internalUID: generateRandomId(),
          };

          const updatedLines = [
            ...transaction.lines.slice(0, targetIndex + 1),
            newSplitItem,
            ...transaction.lines.slice(targetIndex + 1),
          ];

          // Update the targetRow's splitAmount
          updatedLines[targetIndex].splitAmount = newSplitAmount;

          setTransaction((prevState) => ({
            ...prevState,
            lines: updatedLines,
          }));
          setSelectedRows([]);
          break;
        }

        case 'deleteSplitLine': {
          const updatedLines = transaction.lines.filter(
            (row) => row.internalUID !== item.internalUID,
          );

          const remainingSplits = updatedLines.filter(
            (row) => row.lineId === item.lineId,
          );

          // If only one line with the same lineId remains, revert it to its original state
          if (remainingSplits.length === 1) {
            const index = updatedLines.findIndex(
              (row) => row.lineId === item.lineId,
            );
            updatedLines[index] = {
              ...updatedLines[index],
              splitAmount: undefined,
            };
          }

          setTransaction((prevState) => ({
            ...prevState,
            lines: updatedLines,
          }));
          setSelectedRows([]);
          break;
        }

        case 'cellSave': {
          switch (item.id) {
            case 'type': {
              const _rows = transaction.lines.map((row) => {
                if (row.internalUID === item.row.internalUID) {
                  return { ...row, type: item.value[0]?.id || '' };
                }
                return row;
              });
              setTransaction({ ...transaction, lines: _rows });
              break;
            }
            case 'splitAmount': {
              const _rows = transaction.lines.map((row) => {
                if (row.internalUID === item.row.internalUID) {
                  return { ...row, splitAmount: +item.value };
                }
                return row;
              });
              setTransaction({ ...transaction, lines: _rows });
              break;
            }
            default: {
              break;
            }
          }
          break;
        }

        case 'tableStateChange': {
          if (item.selectedRowIds) {
            setSelectedRows(
              transaction?.lines?.filter(
                (_, index) => item.selectedRowIds[index],
              ) || [],
            );
          }
          break;
        }

        case 'autoReconcileSplitLinesReverseCharge': {
          await dispatch(
            resourceCreateRequest(autoReconcileSplitLinesReverseChargeApi, {
              companySid: company.currentCompanySID,
              year: +company.currentAccountingYear,
            }),
          );
          await new Promise((resolve) => {
            setTimeout(resolve, 2000);
          });
          fetchReconcileData();
          break;
        }

        case 'autoReconcileSplitLinesIngoing': {
          await dispatch(
            resourceCreateRequest(autoReconcileSplitLinesIngoingApi, {
              companySid: company.currentCompanySID,
              year: +company.currentAccountingYear,
            }),
          );
          await new Promise((resolve) => {
            setTimeout(resolve, 2000);
          });
          fetchReconcileData();
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    fetchReconcileData();
    fetchVatCodes();
  }, []);

  useEffect(() => {
    if (showModal) return;
    setTransaction({});
    setReconciliationDate('');
    setVatCodeRuleSid('');
    setSelectedRows([]);
    setReconciliations([]);
  }, [showModal]);

  useEffect(() => {
    if (!transaction?.transactionId) return;
    if (!Array.isArray(transformedData)) return;
    const fullTransaction = {
      transactionId: transaction.transactionId,
      lines: sort(
        transformedData.filter(
          (_transaction) =>
            _transaction.transactionId === transaction.transactionId,
        ),
      ).asc([(row) => row.accountId, (row) => row.vatCode]),
      date:
        transformedData.find(
          (_transaction) =>
            _transaction.transactionId === transaction.transactionId,
        )?.date || '',
    };
    setTransaction(fullTransaction);
    toggleModal(true);
  }, [transformedData]);

  const isSelectionValid = React.useMemo(() => {
    if (selectedRows.length === 0) return false;
    // Check if all selected lines have a type
    const selectedLinesWithNoType = selectedRows.filter(
      (row) => row.type === '' || row.type === null,
    );
    return selectedLinesWithNoType.length === 0;
  }, [transaction.lines, selectedRows]);

  React.useEffect(() => {
    // Update lines in selectedRows when transaction.lines changes. Only update type.
    if (selectedRows.length === 0) return;
    const updatedSelectedRows = selectedRows.map((row) => {
      // UpdatedRow may be undefined, if the row was deleted
      const updatedRow = transaction.lines.find(
        (_row) => _row.internalUID === row.internalUID,
      );
      if (!updatedRow) return row;
      return {
        ...row,
        type: updatedRow.type,
      };
    });
    setSelectedRows(updatedSelectedRows);
  }, [transaction.lines]);

  return (
    <>
      {showModal && (
        <VATManualReconcileModal
          {...{
            transaction,
            showModal,
            toggleModal,
            handleAction,
            vatCodes,
            getRowColor,
            vatCodeRuleSid,
            setVatCodeRuleSid,
            actionSubmitting,
            reconciliationDate,
            setReconciliationDate,
            isSelectionValid,
            hasReconciliations: reconciliations.length > 0,
            reconciliations,
          }}
        />
      )}
      <VATManualReconcileTable
        {...{
          id,
          data: transformedData,
          loading,
          handleAction,
          getRowColor,
          roleType,
          accountSystem: company.accountSystem,
        }}
      />
    </>
  );
}

export default VATManualReconcileTableContainer;
