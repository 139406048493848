import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { sort } from 'fast-sort';

import {
  Card,
  Heading,
  CustomTableWithAutoSettings,
  InfoBanner,
} from 'components';

import { VATManualReconcileColumns } from 'services/tableshapes';
import { vatManualReconcileFilters } from 'services/tableFilterShapes';
import { filterByReconciledMatchedTransaction } from 'services/shapes';
import useLocalSettings from '../../../hooks/useLocalSettings';

export default function VATManualReconcileTable(props) {
  const { data, loading, handleAction, getRowColor, roleType, accountSystem } =
    props;

  const { showHelp } = useLocalSettings();

  const globalFilter = useMemo(
    () => ({
      reconciliationStatus: filterByReconciledMatchedTransaction().filter(
        (item) => item.id === 'UNMATCHED',
      ),
    }),
    [],
  );

  const isTripletex = accountSystem === 'TRIPLETEX';

  const isAnyRowOpen = useMemo(() => {
    if (accountSystem !== 'TRIPLETEX') return false;
    if (Array.isArray(data) && data.length > 0) {
      return data.some((item) => item.reconciliationStatus === 'UNMATCHED');
    }
    return false;
  }, [JSON.stringify(data), accountSystem]);

  const filteredData = useMemo(() => {
    const rows = [];

    // Check if data is not empty and is an array
    if (Array.isArray(data) && data.length > 0) {
      // Sort by transactionId
      const sortedData = sort(data).asc([
        (row) => row.date,
        (row) => row.transactionId,
        (row) => row.accountId,
      ]);

      const transactionIdToGroupIdMap = new Map();
      let nextGroupId = 0;

      // Loop through the sorted data array
      sortedData.forEach((row) => {
        const { transactionId } = row;

        // If the transactionId is new, assign a new groupId
        if (!transactionIdToGroupIdMap.has(transactionId)) {
          transactionIdToGroupIdMap.set(transactionId, nextGroupId);
          nextGroupId += 1;
        }

        // Fetch the groupId for this transactionId
        const groupId = transactionIdToGroupIdMap.get(transactionId);

        // Push the row with the groupId
        rows.push({ ...row, groupId });
      });
    }

    return rows;
  }, [JSON.stringify(data)]);

  const hasSplitAmounts = Array.isArray(data)
    ? data.some((item) => item.splitAmount !== null)
    : false;

  const content = (
    <>
      {showHelp && (
        <InfoBanner
          message={t`Do you have issues manually reconciling VAT transactions?`}
          link="https://help.accountflow.com/nb/articles/9003616-avstemme-mva-linjer-manuelt"
          linkTitle={t`Learn more (in Norwegian)`}
          isExternalLink
        />
      )}
      <CustomTableWithAutoSettings
        {...{
          loading,
          data: filteredData,
          handleAction,
          loadingId: 'accountId',
          tableId: 'vatManualReconcileTable',
          enableGroups: true,
          groupId: 'groupId',
          getRowColor,
          filterConfigs: vatManualReconcileFilters(),
          columns: VATManualReconcileColumns(
            roleType.isReadOnly,
            hasSplitAmounts,
          ),
          headerConfigs: {
            exportFileName: t`VAT Deviations`,
            extraButtons: [
              ...(isTripletex
                ? [
                    {
                      id: 'autoReconcileSplitLinesReverseCharge',
                      title: t`Auto-match split reverse charge VAT`,
                      buttonProps: {
                        size: 'sm',
                        kind: 'default',
                        fill: false,
                        magnify: true,
                        disabled: loading || !isAnyRowOpen,
                      },
                    },
                    {
                      id: 'autoReconcileSplitLinesIngoing',
                      title: t`Auto-match split ingoing VAT`,
                      buttonProps: {
                        size: 'sm',
                        kind: 'default',
                        fill: false,
                        magnify: true,
                        disabled: loading || !isAnyRowOpen,
                      },
                    },
                  ]
                : []),
            ],
          },
          initialState: {
            globalFilter,
          },
        }}
      />
    </>
  );

  return (
    <Card
      header={
        <HeaderContainer>
          <Heading level={3} className="card-title">
            <Trans>VAT Transactions</Trans>
          </Heading>
        </HeaderContainer>
      }
    >
      {content}
    </Card>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
