import React from 'react';
import { t } from '@lingui/macro';
import { BiUnlink } from '@react-icons/all-files/bi/BiUnlink';
import { BiCommentDetail } from '@react-icons/all-files/bi/BiCommentDetail';
import { MdOutlineFileCopy } from '@react-icons/all-files/md/MdOutlineFileCopy';

import { recordFilterListFormatter } from 'services/helpers';

export const partialReconciliationSIDConverter = (_, item) =>
  item.reconciliationSID || item.partialReconciliationSID || '';
export const sourceDocumentIdConverter = (_, item) =>
  `${item.sourceDocumentId}${
    item.originTransactionId ? `, ${item.originTransactionId}` : ''
  }`;

/**
 * General Template Grid
 */
export const columns = (isReadOnly) => [
  ...(!isReadOnly
    ? [
        {
          width: 50,
          accessor: 'selection',
          className: 'flex-center',
          disableSortBy: true,
          cell: {
            type: 'selection',
            disableClick: true,
          },
        },
      ]
    : []),
  {
    width: 70,
    Header: t`ID`,
    accessor: 'partialReconciliationSID',
    className: 'flex-center',
    cell: {
      converter: partialReconciliationSIDConverter,
    },
  },
  {
    width: 110,
    Header: t`Trans. ID`,
    accessor: 'sourceDocumentId',
    className: '',
    withTooltip: !isReadOnly,
    cell: {
      type: 'string',
      converter: sourceDocumentIdConverter,
    },
  },
  {
    width: 240,
    Header: t`Description`,
    accessor: 'description',
    className: 'flex-left',
    withTooltip: true,
  },
  {
    width: 240,
    Header: t`Comment`,
    accessor: 'comment',
    className: 'flex-left',
    cell: {
      type: 'textarea',
      editable: true,
      placeholder: t`Add comment:`,
      disableClick: true,
    },
  },
  {
    width: 120,
    Header: t`Value Date`,
    accessor: 'valueDate',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
  {
    width: 130,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Debit Amount`,
    sortType: 'basic',
    accessor: 'debitAmount',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 130,
    Header: t`Credit Amount`,
    sortType: 'basic',
    accessor: 'creditAmount',
    className: 'flex-right',
    withTotal: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: 100,
    Header: t`Period`,
    accessor: 'period',
    className: 'flex-right',
  },
  {
    width: 80,
    Header: t`Year`,
    accessor: 'year',
    className: '',
  },
  {
    width: 200,
    Header: t`Status`,
    accessor: 'reconciliationStatus',
    className: 'flex-center',
    withTooltip: true,
    cell: {
      converter: recordFilterListFormatter,
    },
  },
  {
    width: 120,
    Header: t`Actions`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'dialog',
          icon: () => <BiCommentDetail />,
          accessor: 'hasDialogue',
          tooltip: t`Open dialogue`,
        },
        {
          id: 'files',
          icon: () => <MdOutlineFileCopy />,
          accessor: 'hasFiles',
          tooltip: t`Attach files`,
        },
        {
          id: 'unlink',
          icon: () => <BiUnlink />,
          tooltip: t`Unlink`,
          visible: (item) =>
            ['RECONCILED', 'PARTIAL_RECONCILED'].includes(
              item.reconciliationStatus,
            ),
        },
      ],
    },
  },
];

export default { columns };
