import React from 'react';
import { t } from '@lingui/macro';
import { BiToggleRight } from '@react-icons/all-files/bi/BiToggleRight';

export const columns = () => [
  {
    width: 40,
    Header: '',
    accessor: 'manuallyToggled',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'actions',
      disableClick: true,
      actions: [
        {
          id: 'manuallyToggled',
          icon: () => <BiToggleRight />,
          tooltip: t`Manually Toggled`,
          visible: (item) => item.manuallyToggled,
          disableClick: true,
        },
      ],
    },
  },
  {
    width: 100,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
    withTooltip: true,
  },
  {
    width: 250,
    Header: t`Description`,
    accessor: 'lineDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Date`,
    accessor: 'transactionDate',
    cell: {
      type: 'date',
    },
  },
  {
    width: 70,
    Header: t`Account`,
    accessor: 'accountId',
    className: '',
    withTooltip: true,
  },
  {
    width: 200,
    Header: t`Account Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 150,
    Header: t`Amount`,
    accessor: 'amount',
    predefinedTotal: 'amount',
    cell: {
      type: 'number',
    },
  },
  {
    width: 150,
    Header: t`Actions`,
    cell: {
      type: 'button',
      disableClick: true,
      buttons: [
        {
          id: 'includeWTTransaction',
          size: 'sm',
          kind: 'default',
          title: t`Include Transaction`,
          magnify: true,
          fill: (item) => item.manuallyToggled,
          visible: (item) => item.excluded,
        },
        {
          id: 'excludeWTTransaction',
          size: 'sm',
          kind: 'default',
          title: t`Exclude Transaction`,
          magnify: true,
          fill: (item) => item.manuallyToggled,
          visible: (item) => !item.excluded,
        },
      ],
    },
  },
];

export default { columns };
